import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./styles.scss";

export default function MyComponent({style}) {
  const [date, setDate] = useState(new Date());
  const handleChange = (date) => setDate(date);

  const CustomInput = ({style, value, onClick }) => (
    <input
      style={{...style, width:"100%", height:"100%"}}
      value={value}
      onClick={onClick}
      onChange={onClick}
    ></input>
  ); 

  return (
    <DatePicker
      selected={date}
      onChange={handleChange}
      customInput={<CustomInput style = {style} />}
    />
  );
}
