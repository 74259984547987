import React, { useEffect } from 'react'
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import AuditChanges from "../../components/audit/AuditTable";
import { Typography } from '@mui/material';
import { FormControl, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../axios/AxiosInstance";
import CaptureImage from "./CaptureImage";
import Input from "../../components/detailView/InputFiles";
import Edit from "../../pages/city/cityTable/Agentmodal";
import { toast } from "react-toastify";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const userData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
const userId = userData?._id;

const Details = ({ data }) => {

    const [state, setState] = React.useState({
        right: false,
    });

    let newArray = {}

    newArray.name = data.name
    newArray._id = data._id
    newArray.caseId = data.caseId
    newArray.city = data.city
    newArray.category = data.category
    newArray.createdAt = data.createdAt
    newArray.customerId = data.customerId
    newArray.deadline = data.deadline
    newArray.deliverDate = data.deliverDate
    newArray.email = data.email
    newArray.jobType = data.jobType
    newArray.orderId = data.orderId
    newArray.ownerId = data.ownerId
    newArray.status = data.status
    newArray.subCategory = data.subCategory
    newArray.subject = data.subject
    newArray.ticketNumber = data.ticketNumber
    newArray.scheduledDate = data.scheduledDate
    newArray.invoiceUrl = data.invoiceUrl
    let newarr = []
    for (const key in newArray) {
        if (Object.hasOwnProperty.call(newArray, key)) {
            const element = newArray[key];
            newarr.push(<>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        width: "100%",
                    }}
                >
                    <Stack direction="column" spacing={0.5}>
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                            }}
                        >
                            {key}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        sx={{
                            width: "40%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                            }}
                        >

                            {element}
                        </Typography>
                    </Stack>
                </Stack>
            </>)
        }
    }
    const handleFileUpload = async (ev) => {
        try {
            const files = ev.target.files;

            const formData = new FormData();
            for (let i = 0; i < files?.length; i++) {
                formData.append(`file`, files[i]);
            }
            formData.append("userId", userId);
            alert("Your file has been uploaded !!");
            // closeDetail();
            const res = await axiosInstance.post(
                `api/ticket/files/${data._id}`,
                formData
            );
            if (res.status === 200) {

                toast.success("Your file has been uploaded !!");
                // closeDetail();
                if (userData.type === "agent") {

                } else {

                }
            } else {

                toast.error("Something went wrong!!");
            }
        } catch (err) {
            //console.log(err);

            toast.error("Something went wrong!!");
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        getData(anchor, open);
    };
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 550 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Divider />
        </Box>
    );
    let getData = async (anchor, open) => {
        await axiosInstance
            .post("api/ticket/details", {
                customerId: data.customerId,
                ticketId: data._id,
            })
            .then((res) => {
                let customer = res?.data;
                if (customer) {
                    data.newData = customer;

                }
                setState({ ...state, [anchor]: open });
            })
            .catch((error) => {
                console.log(error);
            });

    }

    return (
        <>
            <div className="filter">
                <div>
                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Typography onClick={toggleDrawer(anchor, true)}>
                                <p style={{ fontSize: "13px", fontWeight: "600" }}>{data.name}</p>
                            </Typography>
                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                            >
                                {list(anchor)}
                                <div style={{ padding: "20px" }}>




                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <Stack
                                            direction="column"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "1.5rem",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    Details
                                                </Typography>

                                            </Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "1rem",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    Key
                                                </Typography>
                                                <div style={{ width: "40%" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "1rem",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        Value
                                                    </Typography>
                                                </div>
                                            </Stack>

                                            <Stack
                                                direction="column"
                                                spacing={3}
                                                sx={{
                                                    width: "100%",

                                                }}
                                            >
                                                {
                                                    newarr?.map((data) => {
                                                        return data
                                                    })
                                                }
                                                {data?.newData?.customer && (
                                                    <>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Stack direction="column" spacing={0.5}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "1rem",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    address
                                                                </Typography>
                                                            </Stack>

                                                            <Stack
                                                                direction="column"
                                                                spacing={0.5}
                                                                sx={{
                                                                    width: "40%",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "1rem",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    {data?.newData?.customer[0]?.street}{" "}
                                                                    {data?.newData?.customer[0]?.city}{" "}
                                                                    {data?.newData?.customer[0]?.state}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </>
                                                )}
                                                {data?.newData?.files && (
                                                    <>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Stack direction="column" spacing={0.5}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "1rem",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    files
                                                                </Typography>
                                                            </Stack>
                                                            {data?.newData?.files?.length > 0 ? (
                                                                <Stack
                                                                    direction="column"
                                                                    spacing={1}
                                                                    sx={{
                                                                        width: "40%",
                                                                    }}
                                                                >
                                                                    {data.newData.files.map((file) => {
                                                                        return (
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "1rem",
                                                                                    fontWeight: "500",
                                                                                    cursor: "pointer",
                                                                                    transition: "all 0.3s ease",
                                                                                    "&:hover": {
                                                                                        color: "#000",
                                                                                        fontWeight: "700",
                                                                                        textDecoration: "underline",
                                                                                    },
                                                                                }}
                                                                                onClick={() => {
                                                                                    window.open(file.s3Data.Location, "_blank");
                                                                                }}
                                                                            >
                                                                                {file.name}
                                                                            </Typography>
                                                                        );
                                                                    })}
                                                                </Stack>
                                                            ) : (
                                                                <Stack
                                                                    direction="column"
                                                                    spacing={1}
                                                                    sx={{
                                                                        width: "40%",
                                                                    }}
                                                                >
                                                                    {"-"}
                                                                </Stack>
                                                            )}
                                                        </Stack>
                                                    </>
                                                )}
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Stack direction="column" spacing={0.5}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "1rem",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            attachment
                                                        </Typography>
                                                    </Stack>
                                                    <Stack
                                                        // direction={ window.innerWidth > 600 ? "row" : "column" }
                                                        justifyContent="space-between"
                                                        spacing={0.5}
                                                        sx={{
                                                            width: "40%",
                                                            flexDirection: "column",
                                                            "@media (max-width: 785px)": {
                                                                fontSize: "0.6rem !important",
                                                                // width:"80px",
                                                                height: "30px",
                                                                flexDirection: "column",
                                                            },
                                                        }}>
                                                        <div style={{ position: "relative", height: "40px" }}>
                                                            <Input
                                                                type="file"
                                                                style={{
                                                                    position: "absolute",
                                                                    zIndex: "2",
                                                                    opacity: "0",
                                                                    cursor: "pointer",
                                                                    width: "100%",
                                                                }}
                                                                handleChange={handleFileUpload} />
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    position: "absolute",
                                                                    height: "40px",
                                                                    width: "100%",
                                                                    backgroundColor: "#000",
                                                                    color: "#fff",
                                                                    fontSize: "0.8rem!important",
                                                                    zIndex: "1",
                                                                    "&:hover": {
                                                                        backgroundColor: "#000",
                                                                        opacity: "0.8",
                                                                    },
                                                                    "@media (max-width: 785px)": {
                                                                        fontSize: "0.6rem !important",
                                                                        width: "100%",
                                                                        height: "30px",
                                                                    },
                                                                }}>
                                                                <AddCircleIcon sx={{ marginRight: "10px" }} />
                                                                Upload
                                                            </Button>
                                                        </div>
                                                        <CaptureImage
                                                            ticketId={data._id}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Drawer>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Details

