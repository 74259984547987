const pairs = {
  transport: "Transport",
  sms: "SMS",
  working_hours: "Working Hours",
  weekly_off: "Weekly Off",
  city: "City",
  "task_limit": "Task Limit",
  "slot_duration": "Slot Duration",
  start_time: "Start Time",
  end_time: "End Time",
  "Week Day": "Week Day",
  "slot_duration1":"Slot Duration1",
  "holiday":"Holiday",
  "Barcode":"Barcode",
  "Remark":"Remark",
  "whatsapp sms":"Whats app sms",
  "PickUp Barcode":"PickUp Barcode",
  "Zoho Books Delivery Date":"Zoho Books Delivery Date",
  "Email Notification":"Email Notification"
}

export default pairs