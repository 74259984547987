import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function BasicPagination({count,limit,setPage}) {

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };
  return (
    <Stack sx={{    marginRight: "-10px"}} spacing={1}>
      <Pagination count={Math.ceil(count/limit)}  onChange={handleChangePage} />
   
    </Stack>
  );
}