import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment/moment";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePicker from "react-datepicker";
import { TextField, Box, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import axiosInstance from "../../../axios/AxiosInstance";
import { toast } from "react-toastify";
import Modal from '@mui/material/Modal';

function createData(taskName, transport, agentName) {
  return { taskName, transport, agentName };
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const rows = [
  createData("Task 1", "Car", "Agent A"),
  createData("Task 2", "Train", "Agent B"),
  createData("Task 3", "Bus", "Agent C"),
  createData("Task 4", "Bike", "Agent D"),
  createData("Task 5", "Car", "Agent E"),
];

const Pickup_and_Refund_Status = [
  "Pickup Pending",
  "Pickup Pending",
  "Pickup Done",
];

const Pickup_and_Refund_Sub_Status = ["Pending", "Scheduled", "Pickup Done"];

const Order = ["Delivery Scheduled", "Order Verified", "Delivered"];
const OrderSubStatus = [
  "Feasibility in progress",
  "Delivery Scheduled",
  "KYC Completed",
  "Delivery to be Rescheduled",
  "Delivered",
];

const OtherStatus = ["Completed", "Pending", "Scheduled"];
const OtherSubStatus = ["Completed", "Scheduled", "Pending"];

function filterCityStatusTrue(data, cityName) {
  const filteredRows = [];
  for (const row of data) {
    if (row.city === cityName && row.status === true) {
      filteredRows.push(row);
    }
  }
  return filteredRows;
}

const TaskModalTable = ({ ticketData, setReloard, reloard }) => {
  let [EditStatus, setEditStatus] = useState(false);
  let [selectedDate, setSelectedDate] = useState([]);
  let [StatusList, setStatusList] = useState([]);
  let [SubStatusList, setSubStatusList] = useState([]);
  let [Tran, setTran] = useState([]);
  let [agent, setagent] = useState([]);
  let [tripData, setTripData] = useState([]);
  let [reload, setReload] = useState(0);
  let [sendWhatsSms, setSendWhatsSms] = useState(false);
  let [Status, setStatus] = useState(
    ticketData.status ? ticketData.status : ""
  );
  let [subStatus, setsubStatus] = useState(
    ticketData.subStatus ? ticketData.subStatus : ""
  );
  let [selectedScheduledDate, setScheduledSelectedDate] = useState(
    ticketData.scheduledDate ? new Date(ticketData.scheduledDate) : ""
  );
  let [selectedDonedDate, setselectedDoneDate] = useState(
    ticketData.deliverDate ? new Date(ticketData.deliverDate) : ""
  );
  let [createNewTrip, setNewCreateTrip] = useState([]);
  let [agenct, setAgenct] = useState(ticketData.agentId);
  let [newTrancpost, setnewTrancpost] = useState(
    ticketData.transport ? ticketData.transport : ticketData.adhoc_vehicle
  );
  let [isAdhock, setIsAdhock] = useState(ticketData.transport ? false : true);
  let [changed, setChanged] = useState({});
  const [ids, setIds] = React.useState("");
  const [indexId, setindexId] = React.useState("");
  const [deleteIndex, setdeleteIndexIndexId] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let [disable, setDisable] = useState(
    ticketData.deliverDate
      ? moment().diff(ticketData.deliverDate, "days") > 4
        ? true
        : false
      : false
  );
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const userData = JSON.parse(localStorage.getItem("wh_tk")).data;


  function giveStatuAndSubStatus(name) {
    if (name == "Pickup and Refund") {
      return { Pickup_and_Refund_Status, Pickup_and_Refund_Sub_Status };
    }
    if (name == "Order") {
      return { Order, OrderSubStatus };
    }
    return {
      OtherStatus,
      OtherSubStatus,
    };
  }

  async function call() {
    const response = await axiosInstance.get(
      `api/trip/details/${ticketData._id}`
    );
    if (response.status === 200) {
      setTripData(response.data);
    }
    const res = await axiosInstance.get(`api/master`);
    const agentRes = await axiosInstance.get(
      `/api/agent?city=${ticketData.city}&onlyOnlineAgents=true&type=all`
    );
    return { tran: res?.data, agentRes: agentRes?.data?.data };
  }
  const handleChangeForNew = (index, name, event) => {
    updateValueAtIndex(index, name, event.target.value);
    setReload(++reload);
  };
  const handleChangeForDate = (index, name, event) => {
    updateValueAtIndex(index, name, event.toString());
    setReload(++reload);
  };
  const adhocTransportForNew = async (event, index) => {
    if (event.key == "Enter") {
      updateValueAtIndex(index, "adhoc_vehicle", event.target.value);
      setReload(++reload);
    }
  };
  function checkStatusExists(array) {
    let res = [];
    for (let i = 0; i < array?.length; i++) {
      if (["Completed", "Delivered", "Pickup Done"].includes(array[i].status)) {
        array[i].doneDate = array[i].scheduledDate;
        res.push(array[i]);
      } else {
        if (
          checkFieldsExistence(array[i], [
            "scheduledDate",
            "transportId",
            "agentId",
          ])
        ) {
          res.push(array[i]);
        }
      }
    }
    return res;
  }
  const handleSubmit = async () => {
    let payload = {
      auditTrailId: ticketData?.auditTrailId,
      userId: userData._id,
      body: checkStatusExists(createNewTrip),
    };

    if (payload.body.length > 0) {
      await axiosInstance.post(`/api/trip/create/${ticketData._id}`, {
        body: payload,
        auditTrailId: ticketData?.auditTrailId,
        userId: userData._id,
      });
      setNewCreateTrip([]);
      setReload(++reload);
    } else {
      toast.error("Please Select ScheduledDate Transport Agent");
    }
  };

  function deleteAtIndex() {

    if (indexId < 0 || indexId >= createNewTrip.length) {
      return;
    }
    createNewTrip.splice(indexId, 1);
    setReload(++reload);
    setdeleteIndexIndexId(false)
    handleClose()
  }
  useEffect(() => {
    call().then((res) => {
      let new_res = filterCityStatusTrue(
        res?.tran?.data.find((data) => data.master == "transport").rows,
        ticketData.city
      );
      setTran(new_res);
      setagent(res.agentRes);
    });

    if (ticketData.subCategory == "Pickup and Refund") {
      let new_status = giveStatuAndSubStatus(ticketData.subCategory);
      setStatusList(new_status.Pickup_and_Refund_Status);
      setSubStatusList(new_status.Pickup_and_Refund_Sub_Status);
    } else if (ticketData.category == "Order") {
      let new_status = giveStatuAndSubStatus(ticketData.category);
      setStatusList(new_status.Order);
      setSubStatusList(new_status.OrderSubStatus);
    } else {
      let new_status = giveStatuAndSubStatus(ticketData.category);
      setStatusList(new_status.OtherStatus);
      setSubStatusList(new_status.OtherSubStatus);
    }
  }, []);

  useEffect(() => {
    call();
  }, [reload]);

  const handleChange = async (id, name, event) => {
    await axiosInstance.post(`/api/trip/update/${id}`, {
      [name]: event.target.value,
      auditTrailId: ticketData?.auditTrailId,
      userId: userData._id,
    });
    setReload(++reload);
  };
  const handleSendWhatsSms = async (event) => {
    setSendWhatsSms(!sendWhatsSms);
    event.target.blur();
  };
  async function deleteTrip() {
    let res = await axiosInstance.get(
      `/api/trip/delete/${ids}?auditTrailId=${ticketData.auditTrailId}&userId=${userData._id}`
    );
    if (res.status == 200) {
      handleClose()
      setReload(++reload);
    }
  }
  function updateValueAtIndex(index, key, value) {
    if (index < 0 || index >= createNewTrip.length) {
      return;
    }
    createNewTrip[index][key] = value;
    const temp = [...createNewTrip];
    setNewCreateTrip([...temp]);
  }

  const handleDateChange = async (name, date, id) => {
    try {
      await axiosInstance.post(`/api/trip/update/${id}`, {
        [name]: date,
        auditTrailId: ticketData?.auditTrailId,
        userId: userData._id,
      });
      setReload(++reload);
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateTask = async () => {
    await axiosInstance.post(`/api/ticket/single/${ticketData._id}`, {
      body: changed,
      sendWhatsSms: sendWhatsSms,
      userId: userData._id,
    });
    setReload(++reload);
    setReloard(++reloard);
  };

  function checkFieldsExistence(object, fields) {
    return fields.every((field) =>
      Object.prototype.hasOwnProperty.call(object, field)
    );
  }

  const handleKeyDown = async (event, id) => {
    if (event.key == "Enter") {
      await axiosInstance.post(`/api/trip/update/${id}`, {
        adhoc_vehicle: event.target.value,
        auditTrailId: ticketData?.auditTrailId,
        userId: userData._id,
      });
      setReload(++reload);
    }
  };

  const setCompletedDate = (old, date) => {
    if (moment(old).format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY")) {
      return true;
    } else {
      toast.error("Scheduled Date And Completed Showld Be Same");
      return false;
    }
  };

  const handleKeyDownForTrans = async (event) => {
    if (event.key == "Enter") {
      setIsAdhock(true);
      const newState = { ...changed };
      delete newState.transport;
      setChanged({ ...newState, adhoc_vehicle: event.target.value });
      setnewTrancpost(event.target.value);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>Delete Trip</h2>

          <Button
            variant="contained"
            sx={{
              whiteSpace: "nowrap",
              marginTop: "25px",
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "13px !important",
              "&:hover": {
                backgroundColor: "#000",
                opacity: "0.8",
              },
              "@media (max-width: 785px)": {
                fontSize: "0.6rem !important",
                // width:"60px",
                height: "30px",
              }
            }}
            onClick={() => { deleteIndex ? deleteAtIndex() : deleteTrip() }}
          >
            yes
          </Button>

          <Button
            variant="contained"
            sx={{
              whiteSpace: "nowrap",
              marginLeft: "50px",
              marginTop: "25px",
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "13px !important",
              "&:hover": {
                backgroundColor: "#000",
                opacity: "0.8",
              },
              "@media (max-width: 785px)": {
                fontSize: "0.6rem !important",
                // width:"60px",
                height: "30px",
              }
            }}
            onClick={() => { handleClose() }}
          >
            no
          </Button>

        </Box>
      </Modal>

      {userData.type == "admin" ? (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "500px",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              ...(disable
                ? {
                  pointerEvents: "none",
                  opacity: "0.9",
                }
                : null),
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Box>
                  <p>Scheduled Date</p>

                  <DatePicker
                    selected={selectedScheduledDate}
                    onChange={(date) => {
                      setChanged({ ...changed, scheduledDate: date });
                      setScheduledSelectedDate(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="abc"
                  />
                </Box>
              </Box>

              <Box>
                <p>Completed On</p>
                <DatePicker
                  selected={selectedDonedDate}
                  onChange={(date) => {
                    if (date == null) {
                      setselectedDoneDate(date);
                      setChanged({ ...changed, deliverDate: date });
                    } else if (setCompletedDate(selectedScheduledDate, date)) {
                      setChanged({ ...changed, deliverDate: date });
                      setselectedDoneDate(date);
                    }
                  }}
                  isClearable
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="abc"
                />
              </Box>
              <Box>
                <p>Transport</p>
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    sx={{
                      width: "200px",
                    }}
                    value={newTrancpost}
                    defaultValue={true}
                    onChange={(event) => {
                      setChanged({
                        ...changed,
                        transport: event.target.value,
                      });
                      setIsAdhock(false);
                      setnewTrancpost(event.target.value);
                    }}
                  >
                    <MenuItem
                      onKeyDown={(e) => e.stopPropagation()}
                      value={null}
                    >
                      empty
                    </MenuItem>
                    {Tran?.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item["vehicle number"]}>
                          {item["vehicle number"]}
                        </MenuItem>
                      );
                    })}
                    {isAdhock ? (
                      <MenuItem key={newTrancpost} value={newTrancpost}>
                        {newTrancpost}
                      </MenuItem>
                    ) : null}
                    {
                      <TextField
                        defaultValue={isAdhock ? newTrancpost : ""}
                        id="standard-basic"
                        label={"Enter Adhoc Vehicle"}
                        onKeyDown={(event) => {
                          handleKeyDownForTrans(event);
                        }}
                      />
                    }
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <p>Agent On</p>
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    sx={{
                      width: "200px",
                    }}
                    value={agenct}
                    defaultValue={true}
                    onChange={(event) => {
                      setChanged({ ...changed, agentId: event.target.value });
                      setAgenct(event.target.value);
                    }}
                  >
                    <MenuItem
                      onKeyDown={(e) => e.stopPropagation()}
                      value={null}
                    >
                      empty
                    </MenuItem>

                    {agent?.map((item, key) => {
                      if (item.type == "agent") {
                        return (
                          <MenuItem key={key} value={item["_id"]}>
                            {item["name"]}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{ marginLeft: "15px", width: "70px", marginTop: "17px" }}
              >
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 100, width: "120px" }}
                >
                  <InputLabel
                    sx={{ fontSize: "15px" }}
                    id="demo-simple-select-standard-label"
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={Status}
                    label="category"
                    onChange={(event) => {
                      setChanged({ ...changed, status: event.target.value });
                      setStatus(event.target.value);
                    }}
                  >
                    {StatusList?.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{ marginLeft: "50px", width: "70px", marginTop: "17px" }}
              >
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 100, width: "120px" }}
                >
                  <InputLabel
                    sx={{ fontSize: "15px" }}
                    id="demo-simple-select-standard-label"
                  >
                    Sub status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={subStatus}
                    label="category"
                    onChange={(event) => {
                      setChanged({ ...changed, subStatus: event.target.value });
                      setsubStatus(event.target.value);
                    }}
                  >
                    {SubStatusList?.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  sx={{
                    whiteSpace: "nowrap",
                    marginLeft: "50px",
                    marginTop: "25px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "13px !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                    "@media (max-width: 785px)": {
                      fontSize: "0.6rem !important",
                      // width:"60px",
                      height: "30px",
                    },
                  }}
                  onClick={() => {
                    UpdateTask();
                  }}
                >
                  Update Task
                </Button>
              </Box>
            </Box>

            <Box sx={{ border: "dotted 1px grey", marginTop: "24px" }}></Box>
            <Box
              sx={{
                display: "flex",
                margin: "2px",
                gap: "15px",
                justifyContent: "start",
                width: "100%",
                marginTop: "10px",
                alignItems: "center",
              }}
              className="deepak"
            >
              <Tooltip title={`send sms ${sendWhatsSms}`}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setSendWhatsSms(!sendWhatsSms);
                  }}
                  sx={{
                    backgroundColor: sendWhatsSms ? "#000" : "#808080",
                    whiteSpace: "nowrap",
                    // ${sendWhatsSms}? (backgroundColor: "#000"): (c,
                    color: "#fff",
                    fontSize: "13px !important",
                    "&:hover": {
                      opacity: "0.8",
                    },
                    "@media (max-width: 785px)": {
                      fontSize: "0.6rem !important",
                      // width:"60px",
                      height: "30px",
                    },
                  }}
                >
                  send sms
                </Button>
              </Tooltip>

              <Tooltip title="Add New Trip">
                <Button
                  variant="contained"
                  onClick={() => {
                    setNewCreateTrip([
                      ...createNewTrip,
                      {
                        tripData: ticketData?.subject,
                        ticketNumber: ticketData?.ticketNumber,
                        deliveryId: ticketData?._id,
                        caseId: ticketData?.caseId,
                        userId: userData._id,
                        doneBy: "Admin",
                      },
                    ]);
                  }}
                  sx={{
                    whiteSpace: "nowrap",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "13px !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                    "@media (max-width: 785px)": {
                      fontSize: "0.6rem !important",
                      // width:"60px",
                      height: "30px",
                    },
                  }}
                >
                  <AddIcon />
                </Button>
              </Tooltip>

              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
                sx={{
                  whiteSpace: "nowrap",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "13px !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem !important",
                    // width:"60px",
                    height: "30px",
                  },
                }}
              >
                Update Trip
              </Button>
            </Box>

            <Table sx={{ marginTop: "20px" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>trip Number</TableCell>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Transport</TableCell>
                  <TableCell>Ticket ID</TableCell>
                  <TableCell>Agent</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>CreatedAt</TableCell>
                  <TableCell>Scheduled Date</TableCell>
                  <TableCell>Done Date</TableCell>
                  <TableCell>Done by</TableCell>
                  <TableCell>Delete</TableCell>
                  {/* <TableCell align="right">Transport</TableCell>
            <TableCell align="right">Agent</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {tripData?.data?.length > 0 ? (
                  tripData?.data?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        ...(row.softDelete
                          ? {
                            pointerEvents: "none",
                            opacity: "0.8",
                          }
                          : null),
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {tripData?.data[index].tripNumber}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {tripData?.data[index].subject}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <FormControl sx={{ minWidth: 120 }} size="small">
                          <InputLabel>Transport</InputLabel>

                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            onChange={(event) =>
                              handleChange(
                                tripData?.data[index]._id,
                                "transportId",
                                event
                              )
                            }
                            label="Age"
                            sx={{
                              width: "200px",
                            }}
                            value={
                              tripData?.data[index].transportId
                                ? tripData?.data[index].transportId
                                : tripData?.data[index].adhoc_vehicle
                            }
                            defaultValue={true}
                          >
                            <MenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                              value={null}
                            >
                              empty
                            </MenuItem>

                            {Tran?.map((item, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  value={item["vehicle number"]}
                                >
                                  {item["vehicle number"]}
                                </MenuItem>
                              );
                            })}
                            {tripData?.data[index]?.adhoc_vehicle ? (
                              <MenuItem
                                key={index}
                                value={tripData?.data[index]?.adhoc_vehicle}
                              >
                                {tripData?.data[index]?.adhoc_vehicle}
                              </MenuItem>
                            ) : null}

                            {
                              <TextField
                                defaultValue={
                                  tripData?.data[index]?.adhoc_vehicle
                                }
                                id="standard-basic"
                                label={"Enter Adhoc Vehicle"}
                                onKeyDown={(event) => {
                                  event.stopPropagation();
                                  handleKeyDown(
                                    event,
                                    tripData?.data[index]._id
                                  );
                                }}
                              />
                            }
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {tripData?.data[index].ticketNumber}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <FormControl sx={{ minWidth: 80 }} size="small">
                          <InputLabel id="demo-select-small-label">
                            Agent
                          </InputLabel>

                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            onChange={(event) =>
                              handleChange(
                                tripData?.data[index]._id,
                                "agentId",
                                event
                              )
                            }
                            label="Age"
                            sx={{
                              width: "150px",
                            }}
                            defaultValue={true}
                            value={tripData?.data[index].agentId}
                          >
                            <MenuItem
                              onKeyDown={(e) => e.stopPropagation()}
                              value={"empty"}
                            >
                              empty
                            </MenuItem>

                            {agent?.map((item, key) => {
                              if (item.type == "agent") {
                                return (
                                  <MenuItem key={key} value={item["_id"]}>
                                    {item["name"]}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <FormControl sx={{ minWidth: 50 }} size="small">
                          <InputLabel id="demo-select-small-label">
                            Status
                          </InputLabel>

                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={tripData?.data[index].status}
                            onChange={(event) =>
                              handleChange(
                                tripData?.data[index]._id,
                                "status",
                                event
                              )
                            }
                            label="Age"
                            sx={{
                              width: "150px",
                            }}
                            defaultValue={true}
                          >
                            {StatusList?.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {tripData?.data[index].createdAt
                          ? moment(tripData?.data[index].createdAt).format(
                            "DD/MM/YYYY"
                          )
                          : ""}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box>
                          <DatePicker
                            selected={
                              tripData?.data[index].scheduledDate
                                ? new Date(tripData?.data[index].scheduledDate)
                                : ""
                            }
                            onChange={(date) =>
                              handleDateChange(
                                "scheduledDate",
                                date,
                                tripData?.data[index]._id
                              )
                            }
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="abc"
                          />
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Box>
                          <DatePicker
                            disabled
                            selected={
                              tripData?.data[index].doneDate
                                ? new Date(tripData?.data[index].doneDate)
                                : ""
                            }
                            onChange={(date) =>
                              handleDateChange(
                                "doneDate",
                                date,
                                tripData?.data[index]._id
                              )
                            }
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="abc"
                          />
                        </Box>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {tripData?.data[index].doneBy}
                      </TableCell>
                      <TableCell>
                        {row.softDelete ? (
                          ""
                        ) : (
                          <DeleteIcon
                            onClick={() => {
                              handleOpen();
                              setIds(tripData?.data[index]._id)
                            }}
                          />
                        )}
                      </TableCell>
                      {/* <TableCell align="right">{row.transport}</TableCell>
              <TableCell align="right">{row.agentName}</TableCell> */}
                    </TableRow>
                  ))
                ) : createNewTrip.length == 0 ? (
                  <>No data</>
                ) : null}
                {createNewTrip.length > 0
                  ? createNewTrip.map((item, key) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {console.log(
                          "item.adhoc_vehicle",
                          item.adhoc_vehicle
                            ? item.adhoc_vehicle
                            : item.transportId
                        )}
                        <TableCell component="th" scope="row">
                          {""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.tripData}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <FormControl sx={{ minWidth: 120 }} size="small">
                            <InputLabel id="demo-simple-select-label">
                              Transport
                            </InputLabel>

                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(event) => {
                                handleChangeForNew(key, "transportId", event);
                              }}
                              label="Age"
                              sx={{
                                width: "200px",
                              }}
                              value={
                                item.adhoc_vehicle
                                  ? item.adhoc_vehicle
                                  : item.transportId
                              }
                            // defaultValue={true}
                            >
                              <MenuItem value={null}>empty</MenuItem>

                              {Tran?.map((item, key) => {
                                return (
                                  <MenuItem
                                    key={key}
                                    value={item["vehicle number"]}
                                  >
                                    {item["vehicle number"]}
                                  </MenuItem>
                                );
                              })}

                              {item.adhoc_vehicle ? (
                                <MenuItem
                                  key={item.adhoc_vehicle}
                                  value={item.adhoc_vehicle}
                                >
                                  {item.adhoc_vehicle}
                                </MenuItem>
                              ) : null}

                              {
                                <TextField
                                  defaultValue={item.adhoc_vehicle}
                                  id="standard-basic"
                                  label={"Enter Adhoc Vehicle"}
                                  onKeyDown={(event) => {
                                    adhocTransportForNew(event, key);
                                  }}
                                />
                              }
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.ticketNumber}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <FormControl sx={{ minWidth: 80 }} size="small">
                            <InputLabel id="demo-select-small-label">
                              Agent
                            </InputLabel>

                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              label="Age"
                              sx={{
                                width: "150px",
                              }}
                              defaultValue={true}
                              onChange={(event) => {
                                handleChangeForNew(key, "agentId", event);
                              }}
                              value={createNewTrip.agentId}
                            >
                              <MenuItem
                                onKeyDown={(e) => e.stopPropagation()}
                                value={"empty"}
                              >
                                empty
                              </MenuItem>

                              {agent?.map((item, key) => {
                                if (item.type == "agent") {
                                  return (
                                    <MenuItem key={key} value={item["_id"]}>
                                      {item["name"]}
                                    </MenuItem>
                                  );
                                }
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <FormControl sx={{ minWidth: 50 }} size="small">
                            <InputLabel id="demo-select-small-label">
                              Status
                            </InputLabel>

                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={createNewTrip.status}
                              label="Age"
                              sx={{
                                width: "150px",
                              }}
                              defaultValue={true}
                              onChange={(event) => {
                                handleChangeForNew(key, "status", event);
                              }}
                            >
                              {StatusList?.map((item, key) => {
                                return (
                                  <MenuItem key={key} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.createdAt
                            ? moment(item.createdAt).format("DD/MM/YYYY")
                            : ""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box>
                            <DatePicker
                              selected={
                                item.scheduledDate
                                  ? new Date(item?.scheduledDate)
                                  : ""
                              }
                              onChange={(event) => {
                                handleChangeForDate(
                                  key,
                                  "scheduledDate",
                                  event
                                );
                              }}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                              className="abc"
                            />
                          </Box>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Box>
                            <DatePicker
                              disabled
                              selected={
                                [
                                  "Completed",
                                  "Delivered",
                                  "Pickup Done",
                                ].includes(item.status)
                                  ? item.scheduledDate
                                    ? new Date(item?.scheduledDate)
                                    : ""
                                  : ""
                              }
                              onChange={(event) => {
                                if (
                                  setCompletedDate(item?.scheduledDate, event)
                                ) {
                                  handleChangeForDate(key, "doneDate", event);
                                }
                              }}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                              className="abc"
                            />
                          </Box>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <TableCell component="th" scope="row">
                            {"Admin"}
                          </TableCell>
                          {/* <FormControl sx={{ minWidth: 80 }} size="small">
                            <InputLabel id="demo-select-small-label">
                              Done by
                            </InputLabel>

                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              label="Age"
                              sx={{
                                width: "150px",
                              }}
                              defaultValue={true}
                              value={createNewTrip.doneBy}
                              onChange={(event) => {
                                handleChangeForNew(key, "doneBy", event);
                              }}
                            >
                              <MenuItem
                                onKeyDown={(e) => e.stopPropagation()}
                                value={"empty"}
                              >
                                empty
                              </MenuItem>

                              {agent?.map((item, key) => {
                                return (
                                  <MenuItem key={key} value={item["name"]}>
                                    {item["name"]}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl> */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <DeleteIcon
                            onClick={() => {
                              setdeleteIndexIndexId(true)
                              setindexId(key)
                              handleOpen()
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                  : ""}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Task Name</TableCell>
                <TableCell>Transport</TableCell>
                <TableCell>Ticket ID</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>CreatedAt</TableCell>
                <TableCell>Scheduled Date</TableCell>
                <TableCell>Done by</TableCell>
                {/* <TableCell align="right">Transport</TableCell>
            <TableCell align="right">Agent</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tripData?.data?.length > 0 ? (
                tripData?.data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {tripData?.data[index].subject}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tripData?.data[index].transportId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tripData?.data[index].ticketNumber}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tripData?.data[index].agentName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tripData?.data[index].status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(tripData?.data[index]?.createdAt).format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(tripData?.data[index]?.scheduledDate).format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {tripData?.data[index]?.doneBy}
                    </TableCell>

                    {/* <TableCell align="right">{row.transport}</TableCell>
              <TableCell align="right">{row.agentName}</TableCell> */}
                  </TableRow>
                ))
              ) : (
                <>No data</>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TaskModalTable;
