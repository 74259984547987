import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Input from "../../components/input/Input";
import { Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import axiosInstance from "../../axios/AxiosInstance";
import ClearIcon from "@mui/icons-material/Clear";
import Loading from "../../components/loading/Loading";
import { TableRows } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const AuditTrailFilterMessage = ({
  messages,
  setMessages,
  setLoading,
  createData,
  loading,
  setFilterData,
  setCity,
  city,
  setTotalPage,
  page,
  limit,
  setPage,
  setFilters,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [city, setCity] = useState("");

  // const getdata = async () => {
  //   let url = "";
  //   if (city !== "") {
  //     url = url + `city$contains=${city}&`;
  //   }

  //   const base_url = `/api/ticket/auditTrall?`;

  //   url = base_url + url;

  //   try {
  //     setFilters(url)
  //     setLoading(true);
  //     const response = await axiosInstance.get(url);
  //     if (response.status === 200) {
  //       setLoading(false);
  //       let allMessages = response.data.data;
  //       allMessages = allMessages.map((message) => {
  //         return createData(message);
  //       });
  //       console.log("response", response);
  //       setMessages(allMessages);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     console.log(err);
  //   }
  //   handleClose();
  // };

  const reset = async () => {
    setMessages([]);
    setName("");
    setEmail("");
    setCity(searchParams.get("city"));
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `api/ticket/auditTrall?page=1&limit=10&city=${searchParams.get("city")}`
      );
      // ${scheduledDate.start !== "" || scheduledDate.end !== "" ? `&type=scheduled&start=` : ""}
      if (response.status === 200) {
        setLoading(false);
        setFilters(
          `api/ticket/auditTrall?page=1&limit=10&city=${searchParams.get(
            "city"
          )}`
        );
        let allMessages = response.data.data.docs;
        allMessages = allMessages.map((message) => {
          setName(message?.userId?.firstName);

          return createData(message);
        });
        setTotalPage(response.data.data.totalPages);
        setMessages(allMessages);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    handleClose();
  };
  const getdata = async () => {
    setMessages([]);
    setName("");
    setEmail("");
    setCity("");
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `api/ticket/auditTrall?page=1&limit=10&city=${city}`
      );
      // ${scheduledDate.start !== "" || scheduledDate.end !== "" ? `&type=scheduled&start=` : ""}
      if (response.status === 200) {
        setLoading(false);
        setCity(city);
        setFilters(`api/ticket/auditTrall?page=1&limit=10&city=${city}`);
        let allMessages = response.data.data.docs;
               allMessages = allMessages.map((message) => {
          setName(message?.userId?.firstName);

          return createData(message);
        });
        setTotalPage(response.data.data.totalPages);
        setMessages(allMessages);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          height: "40px",
          backgroundColor: "#000",
          color: "#fff",
          fontSize: "0.875rem !important",
          "&:hover": {
            backgroundColor: "#000",
            opacity: "0.8",
          },
        }}
        onClick={handleOpen}
      >
        <FilterAltIcon sx={{ marginRight: "10px" }} />
        Filter
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon
            style={{
              position: "absolute",
              right: "-30px",
              top: "-22px",
              cursor: "pointer",
            }}
            onClick={() => {
              setName("");
              setEmail("");
              setCity("");
              handleClose();
            }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filter
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack direction="column">
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  marginBottom: "50px",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    City
                  </Typography>
                  <Input
                    value={city}
                    setvalue={setCity}
                    style={{ width: "70%" }}
                    type="text"
                    placeholder="Enter City"
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={() => {
                    getdata();
                  }}
                >
                  {loading ? <Loading /> : "Ok"}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem!important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={reset}
                >
                  {loading ? <Loading /> : "Reset Filter"}
                </Button>
              </Stack>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default AuditTrailFilterMessage;
