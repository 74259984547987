import React from 'react'
import styled from 'styled-components'
import Input from "../../components/input/Input"
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tag from "./ActionLabel";

const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Main = styled.div`
    width:90%;
    ${'' /* height:85%; */}
    background-color:white;
    padding:5%;
    display:flex;
    flex-direction:column;
    align-items:center;
`
const Heading = styled.div`
    color:black;
    font-size:36px;
    font-weight:700;
`
const Head = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
`
const Label = styled.div`
    font-weight:500;
`
const FieldItems = styled.div`
    display:flex;
    justify-content:space-between;
`
const Fields = styled.div`
    margin-top:50px;
    width:100%;
    height:70%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
`
const Button = styled.div`
    width:150px;
    height:40px;
    background-color:black;
    color:white;
    border-radius:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:5px;
    margin-top:30px;
`


const AddCustomer = () => {

  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  return (
    <Container>
        <Main>
            <Head>
                <Heading>Add New Customers Data</Heading>
            </Head>
            <Fields>
                <FieldItems>
                    <div style={{width:"47%"}}>
                        <Label style={{marginTop:"-1px"}}>Customer ID</Label>
                        <Input placeholder = "Enter Customer ID" />
                    </div>
                    <div style={{width:"47%"}}>
                        <Label style={{marginTop:"-1px"}} >Name</Label>
                        <Input placeholder = "Enter name of the Customer" />
                    </div>
                </FieldItems>
                <FieldItems>
                    <div style={{width:"47%"}}>
                        <Label style={{marginTop:"-1px"}} >Phone</Label>
                        <Input placeholder = "Enter phone number" />
                    </div>
                    <div style={{width:"47%"}}>
                        <Label style={{marginTop:"-1px"}} >E-mail</Label>
                        <Input placeholder = "Enter Email" />
                    </div>
                </FieldItems>
                <FieldItems>
                    <div style={{width:"47%"}}>
                        <Label style={{marginTop:"-1px"}} >Address</Label>
                        <Input style={{marginBottom:"6px"}} placeholder = "Address 1" />
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <Input style={{width:"68%"}} placeholder = "Address 2" />
                            <Input style={{width:"30%"}} placeholder = "Pincode" />
                        </div>
                        <div style={{display:"flex", width:"100%", marginTop:"10px"}}>
                            <Box style={{width:"47%"}} sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={state}
                                label="State"
                                onChange={handleStateChange}
                                >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            </Box>
                            <Box style={{width:"47%"}} sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">City</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={city}
                                label="City"
                                onChange={handleCityChange}
                                >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            </Box>
                        </div>
                    </div>
                    <div style={{width:"47%"}}>
                        <Label>Action</Label>
                        <div style={{width:"100%", display:"flex",flexWrap: "wrap"}}>
                            <Tag />
                            <Tag />
                            <Tag />
                            <Tag />
                            <Tag />
                            <Tag />
                            <Tag />
                            <Tag />
                            <Tag />
                        </div>
                    </div>
                </FieldItems>
                <FieldItems>
                    
                </FieldItems>
            </Fields>
            <Button>
                <AddIcon />
                &nbsp;
                Add Data
            </Button>
        </Main>
    </Container>
  )
}

export default AddCustomer