import { Button, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import React, { useEffect, useState } from "react";

import { Dialog, DialogContent } from "@mui/material";

import axiosInstance from "../../../axios/AxiosInstance";
import Loading from "../../../components/loading/Loading";
import Modal from "./Agentmodal";
import MobileTable from "./MobileTable";
import TaskModalTable from "./TaskModalTable";
import moment from "moment-timezone";
const columns = [
  // { id: 'caseId', label: 'Case ID', minWidth: 170 },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "left",
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 170,
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "left",
  },
  // {
  //   id: "mobile",
  //   label: "Mobile",
  //   minWidth: 170,
  //   align: "right",
  // },
  {
    id: "category",
    label: "Category",
    minWidth: 170,
    align: "left",
  },
  {
    id: "city",
    label: "City",
    minWidth: window.innerWidth > 600 ? 170 : 70,
    align: "left",
  },
  {
    id: "orderId",
    label: "Order ID",
    minWidth: 170,
    align: "left",
  },
  {
    id: "ticketNumber",
    label: "Ticket ID",
    minWidth: 170,
    align: "left",
  },
  {
    id: "createdAt",
    label: "Assigned Date",
    minWidth: 170,
    align: "left",
  },
  {
    id: "scheduledDate",
    label: "Scheduled Date",
    minWidth: 170,
    align: "left",
  },
  {
    id: "deliverDate",
    label: "Completed On",
    minWidth: 170,
    align: "left",
  },
  {
    id: "subCategory",
    label: "Sub Category",
    minWidth: 170,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
  },
  {
    id: "subStatus",
    label: "Sub status",
    minWidth: 170,
    align: "left",
  },
  {
    id: "comment",
    label: "Remarks",
    minWidth: 170,
    align: "left",
  },
  {
    id: "agent",
    label: "Agent",
    minWidth: 170,
    align: "left",
  },
  {
    id: "trip",
    label: "Trips",
    minWidth: 170,
    align: "left",
  },
  {
    id: "edit",
    label: "Edit",
    minWidth: 170,
    align: "left",
  },
  {
    id: "audit",
    label: "Audit Trail",
    minWidth: 170,
    align: "left",
  },
];

function createData(curr) {
  return { ...curr };
}
function convertToIST(utcDateTime) {
  let istDate = moment(utcDateTime).tz("Asia/Kolkata");
  return istDate.format("YYYY-MM-DD HH:mm:ss");
}
export default function StickyHeadTable({
  flag,
  setFlag,
  data,
  auditStyle,
  setAuditStyle,
  setAuditId,
  detailStyle,
  setDetailStyle,
  setDetailData,
  setOverlay,
  getdata,
  page,
  setPage,
  handleChangePage,
  rowsPerPage,
  setRowsPerPage,
  handleChangeRowsPerPage,
  totalPage,
  loading,
  openAudit,
  handleChangePageMobile,
  lastPage,
  finalPage,
  start,
  end,
  isSmall,
  getAgentdata,
  checkedColumns,
}) {
  const [update, setUpdate] = useState(false);
  const [isAgent, setIsagent] = useState(false);
  const [rows, setRows] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskModalData, setTaskModalData] = useState();
  const [tripData, setTripData] = useState(null);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("wh_tk")).data;
    const userRole = userData.role;
    const outArr = userData.out;
    const userPermissions = userRole[0].permissions;

    userPermissions.map((curr, index) => {
      if (outArr[index].key === "city" && curr.update === true) {
        setUpdate(true);
      }
    });

    if (userData.type === "agent") {
      setIsagent(true);
    }

    setFlag(!flag);

    const rowdata = data?.map((curr) => {
      return createData(curr);
    });
    setRows(rowdata);
  }, [data]);

  const months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };

  const openDetails = (data) => {
    let customer = null;
    axiosInstance
      .post("api/ticket/details", {
        customerId: data?.customerId,
        ticketId: data?._id,
      })
      .then((res) => {
        customer = res?.data;
        if (customer) {
          data.newData = customer;
          setDetailData(data && data);
        }
      })
      .catch((error) => setDetailData(data && data));

    setDetailStyle({ ...detailStyle, display: "unset" });

    setTimeout(() => {
      setDetailStyle({
        ...detailStyle,
        transform: "translateX(0)",
        display: "unset",
      });
    }, 100);
    setOverlay(true);
  };
  const openTaskModal = (row) => {
    setShowTaskModal(true);
    setTaskModalData(row);
  };
  const closeTaskModal = () => {
    setShowTaskModal(false);
    setTaskModalData();
  };

  const getTripData = async (ticketId) => {
    try {
      const response = await axiosInstance.get(`api/trip/details/${ticketId}`);
      // console.log(response);
      if (response.status === 200) {
        setTripData(response.data);
        setShowTaskModal(true);
      }
    } catch (error) {
      console.error(error)
    }

  };

  return (
    <>
      {/* {console.log(rows)} */}
      {/* {console.log("data", data)} */}
      {isSmall === false ? (
        <Paper
          sx={{ width: "100%", height: "90%", overflowY: "scroll" }}
          className="no-scroll"
        >
          <TableContainer sx={{ height: "90%", overflowY: "scroll" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                sx={{
                  backgroundColor: "#F2F4FF",
                }}
              >
                <TableRow>
                  {columns?.map((column) => {
                    checkedColumns.Phone = true;
                    // console.log(
                    //   "1",
                    //   column.label,
                    //   checkedColumns.hasOwnProperty(column.label)
                    // );
                    if (
                      checkedColumns.hasOwnProperty(column.label) &&
                      checkedColumns[column.label]
                    ) {
                      if (column.id === "agentId") {
                        if (update === true) {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                backgroundColor: "#F2F4FF",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          );
                        }
                      } else if (column.id === "edit") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#F2F4FF",
                              position: "sticky",
                              right: column.minWidth,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        );
                      } else if (column.id === "audit") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#F2F4FF",
                              position: "sticky",
                              right: "0px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#F2F4FF",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        );
                      }
                    }
                  })}
                </TableRow>
              </TableHead>

              <TableBody sx={{ height: "80%", overflowY: "scroll" }}>
                {loading === true ? (
                  <Loading color="black" />
                ) : rows && rows.length > 0 ? (
                  rows.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns?.map((column) => {
                          if (
                            checkedColumns.hasOwnProperty(column.label) &&
                            checkedColumns[column.label]
                          ) {
                            let value = row[column.id];

                            if (column.id === "name") {
                              if (row.firstName === null) {
                                value = row.lastName;
                              } else if (row.lastName === null) {
                                value = row.firstName;
                              } else value = row.firstName + " " + row.lastName;
                            }

                            if (
                              column.id === "orderId" &&
                              row[column.id] === null
                            ) {
                              value = "-";
                            }

                            if (
                              column.id === "ticketNumber" &&
                              row[column.id] === null
                            ) {
                              value = "-";
                            }

                            if (column.id === "agent") {
                              if (isAgent === true) {
                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {row.agentId}
                                  </TableCell>
                                );
                              }
                              if (row.agent.length === 0) {
                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {/* <AgentEdit flag={flag} ticketId = {row._id}/> */}
                                    {"-"}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {row.agent[0].name}
                                  </TableCell>
                                );
                              }
                            } else if (column.id === "name") {
                              return (
                                <TableCell
                                  onClick={() => {
                                    openDetails(row);
                                  }}
                                  sx={{
                                    border: "none",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    transition: "all 0.3s ease",
                                    "&:hover": {
                                      color: "#000",
                                      fontWeight: "700",
                                    },
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {value}
                                </TableCell>
                              );
                            } else if (column.id === "status") {
                              return (
                                <TableCell
                                  onClick={() => {
                                    getTripData(row._id);
                                  }}
                                  sx={{
                                    border: "none",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    transition: "all 0.3s ease",
                                    "&:hover": {
                                      color: "#000",
                                      fontWeight: "700",
                                    },
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {value}
                                </TableCell>
                              );
                            } else if (column.id === "mobile") {
                              if (
                                // customer?.customer[0].phone === null ||
                                // customer?.customer[0].phone === undefined
                                true
                              ) {
                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {"-"}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {row.user[0].phone}
                                  </TableCell>
                                );
                              }
                            } else if (column.id === "trip") {
                              return (
                                <TableCell
                                  sx={{
                                    border: "none",
                                    position: "sticky",
                                    right: column.minWidth,
                                    backgroundColor: "#ffffff",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      height: "40px",
                                      backgroundColor: "#000",
                                      color: "#fff",
                                      // marginTop:"10px",
                                      fontSize: "0.875rem !important",
                                      "&:hover": {
                                        backgroundColor: "#000",
                                        opacity: "0.8",
                                      },
                                    }}
                                    onClick={() => {
                                      getTripData(row._id);
                                    }}
                                  >
                                    Show
                                  </Button>
                                </TableCell>
                              );
                            } else if (column.id === "edit") {
                              return (
                                <TableCell
                                  sx={{
                                    border: "none",
                                    position: "sticky",
                                    right: column.minWidth,
                                    backgroundColor: "#ffffff",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  <Modal
                                    getAgentdata={getAgentdata}
                                    flag={flag}
                                    setFlag={setFlag}
                                    schedule={row.scheduledDate}
                                    deliver={row.deliverDate}
                                    rowStatus={row.status}
                                    ticketId={row._id}
                                    subcategory={row.subCategory}
                                    getdata={getdata}
                                    row={row}
                                  />
                                </TableCell>
                              );
                            } else if (column.id === "audit") {
                              if (
                                row.auditTrailId === undefined ||
                                row.auditTrailId === null
                              ) {
                                return (
                                  <TableCell
                                    sx={{
                                      border: "none",
                                      position: "sticky",
                                      right: 0,
                                      backgroundColor: "#ffffff",
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    No Audit record
                                  </TableCell>
                                );
                              }

                              return (
                                <TableCell
                                  sx={{
                                    border: "none",
                                    position: "sticky",
                                    right: 0,
                                    backgroundColor: "#ffffff",
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      height: "40px",
                                      backgroundColor: "#000",
                                      color: "#fff",
                                      fontSize: "0.8rem!important",
                                      "&:hover": {
                                        backgroundColor: "#000",
                                        opacity: "0.8",
                                      },
                                    }}
                                    onClick={() => {
                                      openAudit(row.auditTrailId);
                                    }}
                                  >
                                    Audit Trail
                                  </Button>
                                </TableCell>
                              );
                            } else if (
                              column.id === "createdAt" ||
                              column.id === "scheduledDate" ||
                              column.id === "deliverDate"
                            ) {
                              if (
                                row[column.id] === undefined ||
                                row[column.id] === null
                              ) {
                                // console.log("column.idcolumn.idcolumn.idcolumn.id",column.id)
                                return (

                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {"-"}
                                  </TableCell>
                                );
                              } else {
                                const datetimeObj = convertToIST(
                                  row[column.id]
                                );


                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <Stack
                                      direction="column"
                                      justifyContent="center"
                                      spacing={0}
                                      sx={{
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        {datetimeObj}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                );
                              }
                            } else if (column.id === "comment") {
                              if (
                                row[column.id] === undefined ||
                                row[column.id] === null
                              ) {
                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {/* <RemarkEdit flag={flag} ticketId = {row._id} /> */}
                                    {"-"}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    sx={{ border: "none" }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {value}
                                  </TableCell>
                                );
                              }
                            } else {
                              return (
                                <TableCell
                                  sx={{ border: "none" }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {value}
                                </TableCell>
                              );
                            }
                          }
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <Typography sx={{ padding: "1rem" }}>
                      No Data Found
                    </Typography>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <MobileTable
          rows={rows}
          openDetails={openDetails}
          page={page}
          handleChangePageMobile={handleChangePageMobile}
          lastPage={lastPage}
          finalPage={finalPage}
          start={start}
          end={end}
          totalPage={totalPage}
          loading={loading}
        />
      )}
      <Dialog open={showTaskModal} onClose={closeTaskModal} contentStyle={{
        width: '100%',
        maxWidth: 'none',
      }}>
        <DialogContent>
          {console.log('jijijjijijjjijijijijijijijijijij')}
          <TaskModalTable tripData={tripData} />
        </DialogContent>
      </Dialog>
    </>
  );
}
