// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_MainContainer__8XgPI {
    padding: 10px;
}

.style_Heading__vNwNr {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}

.style_DateContainer__iSMvm {
    padding: 10px;
}


.style_TableContainer__m4W6x {
    padding: 10px;
}

/* ======================= */

.style_table__5nDmA {
    width: 100%;
    border-collapse: collapse;
}

.style_th__rD6kh,
.style_td__Lioum {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}

.style_th__rD6kh {
    background-color: #f2f2f2;
}

.style_grand-total-row__CmyW9 .style_th__rD6kh {
    background-color: #d9d9d9;
}

.style_bg_changer__9vPZM {
    background-color: blue;
}`, "",{"version":3,"sources":["webpack://./src/pages/VehicleDuration/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,aAAa;AACjB;;AAEA,4BAA4B;;AAE5B;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;;IAEI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".MainContainer {\n    padding: 10px;\n}\n\n.Heading {\n    font-size: 16px;\n    font-weight: 600;\n    padding: 10px;\n}\n\n.DateContainer {\n    padding: 10px;\n}\n\n\n.TableContainer {\n    padding: 10px;\n}\n\n/* ======================= */\n\n.table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.th,\n.td {\n    border: 1px solid black;\n    padding: 8px;\n    text-align: center;\n}\n\n.th {\n    background-color: #f2f2f2;\n}\n\n.grand-total-row .th {\n    background-color: #d9d9d9;\n}\n\n.bg_changer {\n    background-color: blue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainContainer": `style_MainContainer__8XgPI`,
	"Heading": `style_Heading__vNwNr`,
	"DateContainer": `style_DateContainer__iSMvm`,
	"TableContainer": `style_TableContainer__m4W6x`,
	"table": `style_table__5nDmA`,
	"th": `style_th__rD6kh`,
	"td": `style_td__Lioum`,
	"grand-total-row": `style_grand-total-row__CmyW9`,
	"bg_changer": `style_bg_changer__9vPZM`
};
export default ___CSS_LOADER_EXPORT___;
