import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Table from "./Table";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import BasicSelect from "./dropDown";
import BasicTransport from "./transportDropDown";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import BasicPagination from "./pagination";
import BasicTextFields from "./input";
import BasicModal from "./ScheduleModal";
import UpdateRemark from "./upDateRemark";
import Contect from "./contect";
import Product from "./product"
import { Unstable_Popup as Popup } from "@mui/base/Unstable_Popup";
import axiosInstance from "../../axios/AxiosInstance";
import AuditTrail from "./auditTrail";
import Details from "./details";
import moment from "moment";
import styles from "./input.module.css";
import { ThreeDots } from "react-loader-spinner";
import ClearIcon from "@mui/icons-material/Clear";
import Input from "../../components/input/Input";
import LocationField from "./LocationField";
import PointField from "./PointField";
import NativeSelect from "@mui/material/NativeSelect";
import Category from "./category";
import { toast } from "react-toastify";
import {
  Modal,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BounceLoader from "react-spinners/BounceLoader";
import DataNotFound from '../../assets/dataNotfound.svg';

const style = {
  borderRadius: "10px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: 330,
  },
};


const Styles = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  font-family: Open Sans, sans-serif;
  font-size: 15px;
  margin-left: -37px;

  .table {
    border-spacing: 0;
    width: 100%;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th {
      background-color: #ddd !important;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #panding {
      background-color: #dcf9fc;
      color: #0abacc;
    }

    #completed {
      background-color: #d2fcd6;
      color: #04b312;
    }

    #Failed {
      background-color: #fabbbb;
      color: #cc2f2f;
    }

    .th,
    .td {
      margin: 0;
      //  padding: 0.2rem;
      border-bottom: 1px solid #e5e4e2;
      border-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255);
      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background: black;
        width: 3px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
        &.isResizing {
          background: red;
        }
      }
    }
    .rowww {
      display: flex;
      justify-content: center;
    }
  }
`;

const Some = () => {
  const navigate = useNavigate();
  const authData = localStorage.getItem("wh_tk");
  useEffect(() => {
    if (!authData) {
      navigate("/login", { replace: true });
    }
  }, [])
  const currentDate = moment().valueOf();
  const startDate = moment().subtract(30, "days").valueOf();


  const [buttonState, setbuttonState] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);
  const [flag, setflag] = useState(false);
  const [filter, setfilter] = useState();
  const [pagination, setpagination] = useState({});
  const [count, setCount] = useState(0);
  const [agenct, setAllAgent] = useState([]);

  const [selectFilterField, setSelectFilterField] = useState();
  const [ascDesc, setAscDesc] = useState();

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [status, setStatus] = useState("");
  const [dateFilterStatus, setDateFilterStatus] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [vehicleNumber, setVehicleNumber] = useState();
  const [adhocVehicle, setAdhocvVehicle] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose = () => setOpen(false);
  const [vehicle, setvehicle] = useState([]);
  const [anchor, setAnchor] = React.useState(null);
  const [reloard, setReloard] = useState(1);
  const [dataForTranAge, setDataForTranAge] = useState({});
  const [ticketNumber, seTicketNumber] = useState([]);
  const [AdhocVehicle, seAdhocVehicle] = useState("");
  const [vehicleData, setVehicleData] = useState();
  const [openAnother, setOpenAnother] = useState(false);
  const handleOpenAnother = () => setOpenAnother(!openAnother);
  const handleCloseAnother = () => setOpenAnother(false);
  const [sortFillterStatus, setSortFillterStatus] = useState("");
  const [sortStatus, setSortStatus] = useState("");
  const [dateRange1, setDateRange1] = useState([null, null]);
  const [startDate1, endDate1] = dateRange1;

  const [location, setLocation] = useState();
  const [point, setPoint] = useState();

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };


  const handleFilterSelection = (e) => {
    setSelectFilterField(e.target.value);
    // setfilter({ ...filter, ['filterfeild']: e.target.value });
  }

  const handleAscDescSelection = (e) => {
    setSelectFilterField(e.toLowerCase());
    setAscDesc(!ascDesc ? "ASC" : ascDesc == "ASC" ? "DESC" : "ASC");
    setfilter({ ...filter, ['filterfeild']: e.toLowerCase(), ['ascDesc']: ascDesc == "ASC" ? "DESC" : "ASC" });
  }

  let handleSortFillterStatus = (event) => {
    setSortFillterStatus(event.target.value);
  };


  let handleDate = (event) => {
    setDateRange1(event);
    if (!event[0] == null && !event[1] == null) {
      // console.log("event", event);
    }
  };

  let submit = () => {
    let error = false;
    if (dateFilterStatus) {
      if (dateRange1[0] == null || dateRange1[1] == null) {
        error = true;
        toast.warning("please select date");
      }
    }
    if ((dateRange1[0] !== null, dateRange1[1] !== null)) {
      if (!dateFilterStatus) {
        error = true;
        toast.warning("please select date status");
      }
    }
    if (sortFillterStatus || sortStatus) {
      if (!sortFillterStatus) {
        error = true;
        toast.warning("please sort value");
      }
      if (!sortStatus) {
        error = true;
        toast.warning("please sort status");
      }
    }

    if (!error) {
      if (dateFilterStatus) {
        let start = new Date(dateRange1[0]).toUTCString();
        let end = new Date(dateRange1[1]);
        end = end.setHours(23, 59, 59, 999);
        end = new Date(end).toUTCString();

        setfilter({
          ...filter,
          type: dateFilterStatus,
          start: new Date(start).getTime(),
          end: new Date(end).getTime(),
        });
      }
      if (sortFillterStatus) {
        setfilter({ ...filter, sortType: sortStatus, sort: sortFillterStatus });
      }
      setOpen1(false);
    }

  };

  let handleDateFilterStatus = (event) => {
    setDateFilterStatus(event.target.value);
  };

  const newopen = Boolean(anchor);
  const id = newopen ? "simple-popup" : undefined;
  const columnPermission = JSON.parse(localStorage.getItem("wh_tk")).data
    .column_selector;

  let CityNames = JSON.parse(localStorage.getItem("wh_tk")).data?.warehouses;
  let checkAdmin = JSON.parse(localStorage.getItem("wh_tk")).data?.type;

  let house = [
    {
      _id: "6353dd2fc9383308bab3b855",
      name: "Noida",
      createdAt: "2022-10-22T12:08:15.928Z",
      updatedAt: "2022-10-22T12:08:15.928Z",
      __v: 0,
      city: "Noida",
    },
    {
      _id: "6385d82369d7b11ec8dbce2f",
      name: "Pune",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Pune",
    },
    {
      _id: "639ee663a0a2c3856669742c",
      name: "Mumbai",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Mumbai",
    },
    {
      _id: "639ee6aaa0a2c3856669742e",
      name: "Jodhpur",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Jodhpur",
    },
    {
      _id: "639ee6d6a0a2c38566697430",
      name: "Gurgaon",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Gurgaon",
    },
    {
      _id: "639ee6ffa0a2c38566697431",
      name: "Bangalore",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Bangalore",
    },
    {
      _id: "639ee735a0a2c38566697433",
      name: "Hyderabad",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Hyderabad",
    },
  ];
  if (checkAdmin == "admin") {
    CityNames = house;
  }


  const [city, setCity] = useState(
    CityNames[1]?.name ? CityNames[1]?.name : CityNames[0]?.name
  );
  let cols = [
    {
      Header: "Name",
      width: 130,
      sticky: "left",
      Cell: (data) => {
        return <Details data={data?.cell.row.original} />;
      },
    },
    {
      Header: "Ticket ID",
      accessor: "ticketNumber",
      width: 40,
      sticky: "left",
      Cell: (data) => {
        return (
          <p>
            {" "}
            <a target="_blank" href={data?.cell.row.original.caseUrl}>
              {" "}
              {data?.cell.row.original.ticketNumber}
            </a>{" "}
          </p>
        );
      },
    },
    {
      Header: "Contact",
      width: 50,
      Cell: (data) => {
        return <Contect data={data?.cell.row.original} />;
      },
    },
    {
      Header: "Product",
      width: 10,
      Cell: (data) => {
        return <Product data={data?.cell.row.original} city={city} />;
      },
    },

    {
      Header: "Category",
      accessor: "category",
      width: 50,
      Cell: (data) => {
        return (
          <Category
            data={data?.cell.row.original}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Sub Category",
      accessor: "subCategory",
      width: 150,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 138,
    },
    {
      Header: "Sub status",
      accessor: "subStatus",
      width: 138,
    },
    {
      Header: "Scheduled Date",
      accessor: "scheduledDate",
      width: 120,
    },
    {
      Header: "Location",
      accessor: "location",
      width: 121,
      Cell: (data) => {
        return (
          <div style={{ padding: "5px" }}>
            <LocationField data={data} />
          </div >
        );
      },
    },
    {
      Header: "Point",
      accessor: "point",
      width: 121,
      Cell: (data) => {
        return (
          <div style={{ padding: "5px" }}>
            <PointField data={data} />
            {/* <Input some={() => handleUpdateLocationAndPoint(data.cell.row.original?._id)} defaultValue={data.cell.row.original.point} setvalue={setPoint} style={{ height: "30px", fontSize: '12px' }} placeholder="Update Point" /> */}
          </div>
        );
      },
    },
    {
      Header: "Transport",
      accessor: "transport",
      as: vehicle,
      width: 200,
      Cell: (data) => {
        return (
          <BasicTransport
            data={data}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Agent",
      accessor: "agentId",
      width: 150,
      as: agenct,
      Cell: (data) => {
        return (
          <BasicSelect
            agenct={data}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Assigned Date",
      accessor: "createdAt",
      width: 111,
    },

    {
      Header: "Completed On",
      accessor: "deliverDate",
      width: 111,
    },

    {
      Header: "City",
      accessor: "city",
      width: 138,
    },
    {
      Header: "Trips",
      width: 90,
      Cell: (data) => {
        return (
          <BasicModal
            data={data?.cell.row.original}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "comment",
      width: 150,
      Cell: (data) => {
        return <UpdateRemark data={data?.cell.row.original} />;
      },
    },
    {
      Header: "Audit Trail",
      width: 40,
      Cell: (data) => {
        return <AuditTrail data={data?.cell.row.original} />;
      },
    },
  ];
  const [columns, setColumns] = useState([]);
  const [toogleCols, setToogleCols] = useState(columnPermission);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const hideColumn = (columnHeader) => {
    setHiddenColumns((prevHiddenColumns) => [
      ...prevHiddenColumns,
      columnHeader,
    ]);
  };

  const showColumn = (columnHeader) => {
    // console.log("first");
    setHiddenColumns((prevHiddenColumns) =>
      prevHiddenColumns.filter((column) => column !== columnHeader)
    );
  };
  const visibleColumns = columns.filter(
    (column) =>
      //  {return column}
      // !hiddenColumns.includes(column.Header) &&  toogleCols[column.Header]
      !hiddenColumns.includes(column.Header) && column.Header
  );

  useEffect(() => {

  }, [hiddenColumns]);

  const getInitialData = async () => {
    setLoading(true);
    setGetData([]);
    try {
      const tableRes = await axiosInstance.get(
        `api/ticket?page=${page}&limit=${limit}&city=${"Pune"}`
      );

      if (tableRes.status === 200) {
        setGetData([...tableRes.data?.data]);
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  // calling for today and tomorrow Time with filter
  const getTodayAndTomorrowData = async (isToday) => {

    try {
      setLoading(true);
      isToday ? setbuttonState("Today") : setbuttonState("Tomorrow");
      setPage(1);
      let filters = makeFilterString(filter);
      const now = new Date();
      const yesterday = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      yesterday.setHours(0, 0, 0, 0);
      now.setHours(0, 0, 0, 0);
      const start = isToday ? now.getTime() : yesterday.getTime();
      now.setHours(23, 59, 59, 999);
      yesterday.setHours(23, 59, 59, 999);
      const end = isToday ? now.getTime() : yesterday.getTime();
      let url = `type=scheduled&start=${start}&end=${end}`;
      const UserData = JSON.parse(localStorage.getItem("wh_tk")).data;
      const userId = UserData?._id;
      let baseUrl = "";

      if (UserData?.type === "agent") {
        baseUrl = `api/ticket?page=${page}&limit=${limit}&agentId=${userId}&city=${city}&${filters}`;
      } else {
        baseUrl = `api/ticket?page=${page}&limit=${limit}&city=${city}${filters}`;
      }

      setfilter({ ...filter, type: "scheduled", start: start, end: end });
      // const tableRes = await axiosInstance.get(baseUrl + url);
      // if (tableRes.status === 200) {

      // }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  // create all filter in single string
  function makeFilterString(filter) {
    let base = "&";
    for (const property in filter) {
      base = base + `${property}=${filter[property]}&`;
    }
    return base;
  }

  let getDataOnFlterChange = async () => {
    let base = "";
    for (const property in filter) {
      base = base + `&${property}=${filter[property]}`;
    }
    setGetData([]);
    setLoading(true);
    try {
      const tableRes = await axiosInstance.get(
        `api/ticket?page=${page}&limit=${limit}&city=${city || CityNames[0]?.name
        }${base}`
      );
      if (tableRes.status === 200) {
        setLocationData(tableRes?.data?.data);

        setGetData([...tableRes.data?.data]);
        setCount(tableRes.data?.count);
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    if (agenct && vehicle) {
      setColumns(cols);
    }
  }, [agenct, vehicle]);

  const getAgents = async () => {
    try {

      const response = await axiosInstance.get(
        `/api/agent?city=${city}&onlyOnlineAgents=true`
      );
      if (response.status === 200) {
        let allAgents = response?.data?.data;
        allAgents = allAgents.map((agent) => {
          return agent;
        });
        setAllAgent(allAgents);
      }
    } catch (err) {
      console.log(err);
    } finally {

    }
  };
  const getMasterData = async () => {
    try {

      const response = await axiosInstance.get(`/api/master`);
      if (response.status === 200) {
        setTransportNumber(response?.data?.data)
        let allAgents = response?.data?.data;
        allAgents = allAgents.map((agent) => {
          return agent;
        });
        let getTransportData = allAgents.filter(
          (items) => items.master == "transport"
        );
        let newArray = getTransportData[0].rows.filter(
          (items) => items.city == city && items.status == true
        );
        setvehicle(newArray);
      }
    } catch (err) {

      console.log(err);
    } finally {

    }
  };

  const setTransportNumber = (data) => {
    let newdata = [];
    data?.filter((item, index) => {
      if (item.master === "transport") {
        item.rows?.filter((e) => {
          if (e?.status) {
            if (e?.city == city) {
              newdata.push(e);
            }
          }
        })
      }
    })
    setVehicleData(newdata)
  }

  const setLocationData = (data) => {

  }


  useEffect(() => {
    getInitialData();
    AOS.init({ duration: 1500 });
  }, []);

  useEffect(() => {

    getAgents();
    getMasterData();
    getDataOnFlterChange();
  }, [filter, limit, page, city, reloard]);

  const exportData = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: city,
      filters: makeFilterString(filter),
    };
    // console.log(
    //   `${BASE_URL}api/ticket?&city=${Cityname}&download=csv${filters}`
    // );
    var newWindow = window.open(
      `${process.env.REACT_APP_API_URL
      }api/ticket?&city=${city}&download=csv${makeFilterString(filter)}`
    );
    newWindow.passedData = myObj;
  };

  const exportTicket = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: city,
      filters: makeFilterString(filter),
    };
    // console.log(`${BASE_URL}api/trip/export?&city=${Cityname}&download=csv${filters}`);
    var newWindow = window.open(
      `${process.env.REACT_APP_API_URL
      }api/trip/export?&city=${city}&download=csv${makeFilterString(filter)}`
    );
    newWindow.passedData = myObj;
  };
  const exportOder = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: city,
      filters: makeFilterString(filter),
    };
    // console.log(`${BASE_URL}api/trip/export?&city=${Cityname}&download=csv${filters}`);
    var newWindow = window.open(
      filter ? `${process.env.REACT_APP_API_URL}api/ticket/order?&city=${city}&download=csv${makeFilterString(filter)}` : `${process.env.REACT_APP_API_URL}api/ticket/order?&city=${city}&download=csv&type=scheduled&start=${startDate}&end=${currentDate}}`,
    );
    newWindow.passedData = myObj;
  };

  const handleChange = async (e) => {
    setDataForTranAge({ ...dataForTranAge, [e.target.name]: e.target.value })
  }

  const handleKeyDown = async (event) => {
    event.stopPropagation();
    if (event.key == "Enter") {
      seAdhocVehicle(event.target.value)
    }
  }

  const submitmulti = async () => {
    let body = { ...dataForTranAge, ticketNumber }
    if (AdhocVehicle) {
      body.adhoc_vehicle = AdhocVehicle
    }
    try {
      await axiosInstance.post(`/api/ticket/update/updatemulti`, body);
      setReloard(prev => Math.random());
      seAdhocVehicle("")
      setDataForTranAge({})
      toast.success("Update successfull");

    } catch (error) {
      console.log(error.response?.data?.message);
    }
  }

  const handleClearFilter = () => {
    window.location.reload()
  }

  return (
    <Styles>
      <>
        <Box
          sx={{
            padding: '10px',
            display: "flex",
            justifyContent: "space-between",
          }}>

          <Box
            sx={{
              width: "12%",
              "@media (max-width: 785px)": {
                fontSize: "0.6rem !important",
                width: "100%",
                height: "30px",
                marginTop: "-20px",
              },
              display: "flex",
            }}
          >

            <Select
              value={city || CityNames[1]?.name}
              style={{
                marginLeft: "3px",
                width: "100%",
                borderColor: "black",
                height: "30px",
              }}
              type="text"
              placeholder="Enter City"
              onChange={(event) => {
                setCity(event.target.value);
                setPage(1);
              }}
            >
              {CityNames?.map((data, index) => {
                return (
                  <MenuItem key={index} value={data?.name}>
                    {data?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              sx={{
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  height: "30px",
                },
              }}
              onClick={() => {
                handleOpenAnother();
              }}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              sx={{
                marginLeft: "10px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  height: "30px",
                },
              }}
              onClick={() => {
                handleClearFilter();
              }}
            >
              Clear Filter
            </Button>
            {/* 
            <Box sx={{ marginLeft: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  whiteSpace: "nowrap",
                  height: "30px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "13px !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem !important",
                    // width:"60px",
                    height: "30px",
                  },
                }}
                onClick={() => {
                  // setflag(!flag);
                  // handleOpenAnother()
                }}
              >
                Select Filter
              </Button>
            </Box> */}

            {/* <Box sx={{ marginLeft: "10px" }}>
              <Select
                sx={{
                  // backgroundColor: "#000",
                  // color: "#fff",
                  minWidth: 120,
                  width: "100px",
                }}
                labelId="demo-simple-select-label"
                id="Select-Filter-option"
                value={selectFilterField}
                onChange={handleFilterSelection}
                placeholder="Select Filter"
              >
                <MenuItem value={"point"}>Point</MenuItem>
                <MenuItem value={"agent"}>Agent</MenuItem>
              </Select>
            </Box> */}

            {/* {selectFilterField && <Box sx={{ marginLeft: "10px" }}>
              <Select
                sx={{
                  // backgroundColor: "#000",
                  // color: "#fff",
                  minWidth: 120,
                  width: "100px",
                }}

                labelId="demo-simple-select-standard-label"
                id="Select-Filter-option"
                value={ascDesc}
                onChange={handleAscDescSelection}
                placeholder="Select Filter"
              >
                <MenuItem value={"ASC"}>ASC</MenuItem>
                <MenuItem value={"DESC"}>DESC</MenuItem>
              </Select>
            </Box>} */}


            <Box sx={{ marginLeft: "10px" }}>
              <Button
                onClick={handleOpen1}
                variant="contained"
                sx={{
                  whiteSpace: "nowrap",
                  height: "30px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "13px !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem !important",
                    // width:"60px",
                    height: "30px",
                  },
                }}
              >
                Date Filter
              </Button>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box>
                  <Box sx={style}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Box sx={{ width: "50%" }}>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                          >
                            status
                          </InputLabel>
                          <NativeSelect
                            defaultValue={dateFilterStatus}
                            onChange={handleDateFilterStatus}
                          >
                            <option value={""}></option>
                            <option value={"scheduled"}>Scheduled</option>
                            <option value={"assigned"}>Assigned</option>
                            <option value={"deliver"}>Completed</option>
                          </NativeSelect>
                        </FormControl>
                      </Box>
                      <Box sx={{ width: "50%", marginTop: "19px", padding: "10px" }}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          id={`DataPickerNewPage`}
                          selectsRange={true}
                          startDate={startDate1}
                          endDate={endDate1}
                          onChange={handleDate}
                          isClearable={true}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", marginTop: "10px", gap: "10px" }}>
                      <Box sx={{ width: "50%" }}>
                        <FormControl variant="standard" sx={{ width: '100%' }}>
                          <InputLabel
                            sx={{ fontSize: "15px" }}
                            id="demo-simple-select-standard-label">
                            Sort By
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={sortStatus}
                            onChange={(event) => setSortStatus(event.target.value)}
                            label="category"
                          >
                            <MenuItem value={"assigned"}>Assigned</MenuItem>
                            <MenuItem value={"scheduled"}>Schedule</MenuItem>
                            <MenuItem value={"deliver"}>Completed</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>{" "}
                      <Box sx={{ width: "50%" }}>
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                          <InputLabel
                            sx={{ fontSize: "15px" }}
                            id="demo-simple-select-standard-label">
                            Sort
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={sortFillterStatus}
                            onChange={handleSortFillterStatus}>
                            <MenuItem value={"AEC"}>AEC</MenuItem>
                            <MenuItem value={"DESC"}>DESC</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: "10px",
                          whiteSpace: "nowrap",
                          height: "30px",
                          backgroundColor: "#000",
                          color: "#fff",
                          fontSize: "13px !important",
                          "&:hover": {
                            backgroundColor: "#000",
                            opacity: "0.8",
                          },
                          "@media (max-width: 785px)": {
                            fontSize: "0.6rem !important",
                            // width:"60px",
                            height: "30px",
                          },
                        }}
                        onClick={submit}>
                        Filter
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Box>
            <Button
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: buttonState == "Today" ? "grey" : "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
              onClick={() => {
                getTodayAndTomorrowData(true);
              }}>
              Today
            </Button>
            <Button
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: buttonState == "Tomorrow" ? "grey" : "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
              onClick={() => {
                getTodayAndTomorrowData(false);
              }}>

              Tomorrow
            </Button>
            <Button
              aria-describedby={id}
              type="button"
              onClick={handleClick}
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }} >
              Export
            </Button>
            <Popup id={id} open={newopen} anchor={anchor}>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "3px",
                  border: "1px solid black",
                }}
              >
                {" "}
                <MenuItem
                  onClick={() => {
                    exportData();
                    handleClick();
                  }}
                >
                  City
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exportTicket();
                    handleClick();
                  }}>
                  Trip
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exportOder();
                    handleClick();
                  }}>
                  Barcode
                </MenuItem>
              </div>
            </Popup>
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}>

              <SettingsSuggestIcon />
            </Button>

            {/* <Select
              id="demo-select-small"
              onChange={handleChange}
              name="transport"
              label="Transport"
              style={{
                marginLeft: "3px",
                width: "50%",
                borderColor: "black",
                height: "30px",
                minWidth: "80px"
              }}
            >
              <MenuItem
                onKeyDown={(e) => e.stopPropagation()}
                value={null}
              >
                empty
              </MenuItem>

              {vehicle?.map((item) => (
                <MenuItem
                  onKeyDown={(e) => e.stopPropagation()}
                  key={item._id}
                  value={item?.["vehicle number"]}
                >
                  {item["vehicle number"]}
                </MenuItem>
              ))}

              {
                <TextField
                  id="standard-basic"
                  label="Enter Adhoc Vehicle"
                  value={AdhocVehicle}
                  onKeyDown={handleKeyDown}
                />
              }
            </Select> */}
            {/* <Select
              sx={{
                marginLeft: "3px",
                width: "50%",
                borderColor: "black",
                height: "30px",
                minWidth: "50px"
              }}
              name="agentId"
              id="demo-select-small"
              label="Agent"
              onChange={handleChange}
            >
              <MenuItem onKeyDown={(e) => e.stopPropagation()} value={"empty"}>
                empty
              </MenuItem>
              {agenct?.map((item) => (
                <MenuItem key={item.name} value={item._id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select> */}
            <Button
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
              onClick={() => submitmulti()}
            >
              Submit
            </Button>
          </Box>

          {/* 
          <Modal
            open={openAnother}
            onClose={handleCloseAnother}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              zIndex: "100000",
            }}>

            <Box sx={style}>
              <ClearIcon
                style={{
                  borderRadius: "10px",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  cursor: "pointer",
                  color: "black",
                  '@media (max-width: 400px)': {
                    right: "10px",
                    top: "10px",
                    color: "black",
                  },
                }}
                onClick={() => {
                  handleCloseAnother();
                }}
              />
              <Box >
                <BasicTextFields
                  name={name}
                  setName={setName}
                  category={category}
                  setCategory={setCategory}
                  status={status} setStatus={setStatus}
                  dateFilterStatus={dateFilterStatus} setDateFilterStatus={setDateFilterStatus}
                  dateRange={dateRange} setDateRange={setDateRange}
                  vehicleNumber={vehicleNumber} setVehicleNumber={setVehicleNumber}
                  adhocVehicle={adhocVehicle} setAdhocvVehicle={setAdhocvVehicle}
                  vehicleData={vehicleData}
                  filter={filter}
                  setfilter={setfilter}
                />
              </Box>
            </Box>
          </Modal> */}
        </Box>
        {openAnother ? (
          <div className={styles.FilterContainer} data-aos="fade-right">
            <BasicTextFields
              handleClearFilter={handleClearFilter}
              vehicleData={vehicleData}
              filter={filter}
              setfilter={setfilter}
            />
          </div>
        ) : null}
        {
          (getData?.length > 0 ? <>
            <Table
              handleAscDescSelection={handleAscDescSelection}
              columns={visibleColumns}
              seTicketNumber={seTicketNumber}
              data={getData}
              open={open}
              setOpen={setOpen}
              handleOpen={handleOpen}
              handleClose={handleClose}
              setColumns={setColumns}
              hideColumn={hideColumn}
              showColumn={showColumn}
              toogleCols={toogleCols}
              setToogleCols={setToogleCols} />

            <Box style={{ padding: "10px", width: "100%", display: 'flex', alignItems: "center", justifyContent: 'end' }}>
              <BasicPagination count={count} limit={limit} setPage={setPage} />
              <Box sx={{ marginTop: "-6px" }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 20 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={limit}
                    onChange={(event) => {
                      setLimit(event.target.value);
                      setPage(1);
                    }}>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </> : loading ? <div className="LoadingContainer">
            <BounceLoader /> </div> :
            <div className='NotFoundContainer' style={{ marginTop: "10px" }}>
              <img className='dataNotFountContainerImage' src={DataNotFound} />
              <div style={{ fontSize: "26px" }}>{"Data Not Found"}</div>
            </div>
          )}
      </>
    </Styles >
  );
};
export default Some;

// const LoaderComponent = () => {
//   return (
//     <div className={styles.spinner_overlay}>
//       <div className={styles.spinner_container}>
//         <ThreeDots
//           height={50}
//           width={50}
//           color={"#EFF5FF"}
//           ariaLabel="loading"
//         />
//       </div>
//     </div>
//   );
// };


// const DataNotFound = () => {
//   return (
//     <Box sx={{
//       width: "100%",
//       display: "flex",
//       justifyContent: 'center',
//       alignItems: "center",
//       height: "60vh"
//     }}>
//       <div style={{
//         fontSize: "22px",
//         fontWeight: '500'

//       }}>Data Not Available</div>
//     </Box>
//   )
// }

{/* <div className='NotFoundContainer' style={{ marginTop: "10px" }}>
                        <img className='dataNotFountContainerImage' src={DataNotFound} />
                        <div style={{ fontSize: "26px" }}>{startDate1 && endDate1 ? "Data Not Found" : "Please Select Date"}</div>
</div> */}