import React, { useState, useEffect } from 'react';
import './BarcodeMatchTable.css'; // Make sure to import the CSS file
import { TextField, MenuItem, Button, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../../axios/AxiosInstance';
import moment from "moment/moment";
import BounceLoader from "react-spinners/BounceLoader";

const cities = ["All", 'Mumbai', 'Hyderabad', 'Bangalore', 'Pune', 'Noida', 'Gurgaon'];
const citiesAll = ['Mumbai', 'Hyderabad', 'Bangalore', 'Pune', 'Noida', 'Gurgaon'];

const jobTypes = ["All", "Pickup and Refund", "New - Rental", "Repair", "Replace"];
const jobTypeAll = ["Pickup and Refund", "New - Rental", "Repair", "Replace"];


const BarcodeMatchTable = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [loading, setLoading] = useState(false);

    const [city, setCity] = useState(cities[1]);
    const [jobType, setJobType] = useState(jobTypes[1]);
    const [data, setData] = useState({});

    const fetchData = async () => {
        try {
            setLoading(true)
            let start = new Date(startDate);
            let end = new Date(endDate);
            end = end.setHours(23, 59, 59, 999);
            end = new Date(end).toUTCString();
            const url = `/api/ticket/ordernew?jobType=${jobType}&type=scheduled&city=${city}&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
            console.log(url);
            let data = await axiosInstance.get(url)
            setData({ ...data.data.data })
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    let exportData = () => {
        let start = new Date(startDate);
        let end = new Date(endDate);
        end = end.setHours(23, 59, 59, 999);
        end = new Date(end).toUTCString();
        const url = `${process.env.REACT_APP_API_URL}api/ticket/ordernew?jobType=${jobType}&type=scheduled&city=${city}&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}&download=csv`;
        var newWindow = window.open(url);
    }
    // const handleDate = (event) => {
    //     if (event[0] != null && event[1] != null) {

    //         setStartDate(new Date(start).getTime());
    //         setEndDate(new Date(end).getTime());
    //         setStart();
    //         setEnd();
    //     }
    // };
    const handleCityChange = (e) => {
        let allstring = ``
        if (e.target.value === "All") {
            citiesAll?.map((item) => {
                allstring += item + ","
            })
            setCity(allstring);
        } else {
            setCity(e.target.value);
        }
    };

    const handleJobTypeChange = (e) => {
        let allstring = ``
        if (e.target.value === "All") {
            jobTypeAll?.map((item) => {
                allstring += item + ","
            })
            setJobType(allstring);
        } else {
            setJobType(e.target.value);
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchData();
        // You can send 'values' to an API or handle it however you need.
    };
    const locations = Object.keys(data);

    const calculateTotals = (data) => {
        let totals = {
            outwardDoneMatch: 0,
            outwardDoneNotMatch: 0,
            outwardNotDoneMatch: 0,
            outwardNotDoneNotMatch: 0,
            inwardDoneMatch: 0,
            inwardDoneNotMatch: 0,
            inwardNotDoneMatch: 0,
            inwardNotDoneNotMatch: 0,
        };

        locations.forEach(location => {
            const locationData = data[location];
            totals.outwardDoneMatch += locationData.outward.done.match;
            totals.outwardDoneNotMatch += locationData.outward.done.notMatch;
            totals.outwardNotDoneMatch += locationData.outward.notDone.match;
            totals.outwardNotDoneNotMatch += locationData.outward.notDone.notMatch;
            totals.inwardDoneMatch += locationData.inward.done.match;
            totals.inwardDoneNotMatch += locationData.inward.done.notMatch;
            totals.inwardNotDoneMatch += locationData.inward.notDone.match;
            totals.inwardNotDoneNotMatch += locationData.inward.notDone.notMatch;
        });

        return totals;
    };

    const totals = calculateTotals(data);

    // const exportOder = () => {
    //     let status = "Assigned" ? "assigned" : "scheduled"
    //     let url = `${process.env.REACT_APP_API_URL}api/ticket/order?type=${status}&download=csv&`;

    //     url =
    //         url +
    //         `start=${new Date(startDate).getTime()}&end=${new Date(endDate).getTime()}`;
    //     var newWindow = window.open(url);
    // };

    return (
        <div style={{ maxWidth: "98%" }}>
            <div style={{}}>
                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                            <div style={{ padding: "5px", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ marginRight: "5px", whiteSpace: "nowrap" }}>Date Range:</label>
                                <DatePicker
                                    id='someDatePicker'
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                />
                            </div>
                            <div style={{ padding: "5px", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ marginRight: "5px", whiteSpace: "nowrap" }}>City:</label>
                                <select style={{ padding: "5px", width: "191px" }} value={city} onChange={handleCityChange}>
                                    {cities.map((item, key) => {
                                        return <option key={key} value={item}>{item}</option>
                                    })}
                                </select>
                            </div>
                            <div style={{ padding: "5px", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ marginRight: "5px", whiteSpace: "nowrap" }}>Job Type:</label>
                                <select style={{ padding: "5px", width: "191px" }} value={jobType} onChange={handleJobTypeChange}>
                                    {jobTypes.map((item, key) => {
                                        return <option key={key} value={item}>{item}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <button style={{ padding: "5px 20px", backgroundColor: "black", color: "white", }} type="submit">Submit</button>
                            <button onClick={() => exportData()} style={{ padding: "5px 20px", backgroundColor: "black", color: "white", marginLeft: "5px", }} type="submit">Export</button>
                        </div>
                    </div>

                </form>
            </div>

            {!loading ? <table className="barcode-match-table">
                <thead>
                    <tr style={{ color: "black" }} className="header">
                        <th colSpan="2">Movement type</th>
                        <th colSpan="4">Outward</th>
                        <th colSpan="4">Inward</th>
                        <th rowSpan="2">Grand Total</th>
                    </tr>
                    <tr style={{ color: "black" }} className="subheader">
                        <th colSpan="2">Row Labels</th>
                        <th colSpan="2">Done</th>
                        <th colSpan="2">Not Done</th>
                        <th colSpan="2">Done</th>
                        <th colSpan="2">Not Done</th>
                    </tr>
                    <tr>
                        <th colSpan="2" className="empty-cell"></th>
                        <th>Matched</th>
                        <th>Non-Matched</th>
                        <th>Matched</th>
                        <th>Non-Matched</th>
                        <th>Matched</th>
                        <th>Non-Matched</th>
                        <th>Matched</th>
                        <th>Non-Matched</th>
                        <th className="empty-cell"></th>
                    </tr>
                </thead>
                <tbody>
                    {locations?.map(location => (
                        <tr key={location}>
                            <td colSpan="2" className="row-label">{location}</td>
                            <td>{data[location].outward.done.match}</td>
                            <td>{data[location].outward.done.notMatch}</td>
                            <td>{data[location].outward.notDone.match}</td>
                            <td>{data[location].outward.notDone.notMatch}</td>
                            <td>{data[location].inward.done.match}</td>
                            <td>{data[location].inward.done.notMatch}</td>
                            <td>{data[location].inward.notDone.match}</td>
                            <td>{data[location].inward.notDone.notMatch}</td>
                            <td>
                                {Object.values(data[location].outward.done).reduce((a, b) => a + b, 0) +
                                    Object.values(data[location].outward.notDone).reduce((a, b) => a + b, 0) +
                                    Object.values(data[location].inward.done).reduce((a, b) => a + b, 0) +
                                    Object.values(data[location].inward.notDone).reduce((a, b) => a + b, 0)}
                            </td>
                        </tr>
                    ))}
                    <tr className="grand-total">
                        <td colSpan="2">Grand Total</td>
                        <td>{totals.outwardDoneMatch}</td>
                        <td>{totals.outwardDoneNotMatch}</td>
                        <td>{totals.outwardNotDoneMatch}</td>
                        <td>{totals.outwardNotDoneNotMatch}</td>
                        <td>{totals.inwardDoneMatch}</td>
                        <td>{totals.inwardDoneNotMatch}</td>
                        <td>{totals.inwardNotDoneMatch}</td>
                        <td>{totals.inwardNotDoneNotMatch}</td>
                        <td>
                            {Object.values(totals).reduce((a, b) => a + b, 0)}
                        </td>
                    </tr>
                </tbody>
            </table> :
                <div style={{ height: "40vh", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                    <BounceLoader />
                </div>
            }
        </div>
    );
};

export default BarcodeMatchTable;



