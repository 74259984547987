import React, { useState } from 'react';
import axiosInstance from "../../axios/AxiosInstance";
import Input from '../../components/input/Input';
import { toast } from 'react-toastify';

const PointField = (data) => {
    const [point, setPoint] = useState();
    const handleUpdateLocationAndPoint = async (id,) => {
        let body = {
            id: id,
            point: point,
        }
        try {
            const res = await axiosInstance.post(`/api/ticket/start/location`, body);
            toast.success('Point Update successFully')
        } catch (error) {
            console.error(error);
            toast.error('SomeThing went Wrong')
        }
    }


    return (
        <div>
            <Input some={() => handleUpdateLocationAndPoint(data?.data?.cell?.row.original?._id)} defaultValue={data?.data?.cell?.row?.original?.point} setvalue={setPoint} style={{ height: "30px", fontSize: '12px' }} placeholder="Update Point" />
        </div>
    )
}

export default PointField 