
import React, { useEffect, useState } from 'react';
import "../MobileView.css"
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axios/AxiosInstance';
import { toast } from "react-toastify";
const ForgotPassword = () => {
    
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        forgotEmail: '',
        newPawword: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/user/new/forgot', formData);
            if (response.status === 200) {
                console.log(response.data);
                toast.success(response.data.msg)
                navigate('/login');
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }

    };

    useEffect(() => {
      
    }, [])

    return (
        <div className='newBody'>
            <div class="login-container">
                <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "50px" }}>cityfurnish</h1>
                    <p style={{ color: "#898989" }}>Reset Password</p>
                </div>

                <form onSubmit={handleSubmit}>

                    <div style={{ marginTop: "40px" }}>
                        <label htmlFor="email" style={{ fontSize: "13px", color: "#898989" }} for="">Email</label>
                        <input
                            className='input'
                            id="email"
                            name="forgotEmail"
                            autocomplete="off"
                            value={formData.forgotEmail}
                            onChange={handleChange}
                            required
                            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", border: "none", borderRadius: "10px" }} type="text"
                            placeholder="Enter Your Email Address" />
                    </div>

                    <div style={{ marginTop: "15px" }}>
                        <label htmlFor="password" style={{ fontSize: "13px", color: "#898989" }} for="">New Password</label>
                        <input
                            className='input'
                            id="password"
                            name="newPawword"
                            value={formData.newPawword}
                            onChange={handleChange}
                            required
                            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", border: "none", borderRadius: "10px" }} type="password"
                            placeholder="Enter Your Password" />

                    </div>
                    <button className='newbutton' type="submit" style=
                        {{ marginTop: "10px", backgroundColor: "#007BFF", color: "white", borderRadius: "10px" }}>Update</button>
                    
                </form>

            </div>
        </div>
    )


}

export default ForgotPassword