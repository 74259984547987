import React, { useState, useEffect } from 'react';
import { Button, Container, Stack, Typography } from '@mui/material'
import wh_sign_lbg from '../../assets/wh_sign_lbg.png';
import Input from '../../components/input/LoginInput';
import axiosInstance from '../../axios/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading/Loading';

function Login() {

    const [value, setValue] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const navigate = useNavigate();
    const PHONE_REGEX = new RegExp(/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/);
    const EMAIL_REGEX = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

    const handleIdChange = (ev) => {

        if (PHONE_REGEX.test(ev.target.value) === true) {
            setIsPhone(true);
        }
        else {
            setIsPhone(false)
        }

        if (EMAIL_REGEX.test(ev.target.value) === true) {
            setIsEmail(true);
        }
        else {
            setIsEmail(false);
        }
        // console.log(EMAIL_REGEX.test(ev.target.value));
        setValue(ev.target.value);
    }

    useEffect(() => {
        if (window.innerWidth < 600) {
            navigate('/login-page');
        }
    }, [])
    const handlePassChange = (ev) => {
        setPassword(ev.target.value)
    }

    const sendOtp = async () => {

        //9667676054 431739
        const data = {
            phone: value
        }
        setLoading1(true);
        try {
            const res = await axiosInstance.post('api/user/auth/send_otp', data);
            if (res.status === 200) {
                setLoading1(false);
                alert(res.data.message);
            }
            else {
                setLoading1(false);
                alert("Something went wrong")
            }
        }
        catch (err) {
            console.log(err);
            setLoading1(false);
            alert("Something went wrong !");
        }
    }

    const onLogin = async () => {

        if (isEmail === false && isPhone === false) {
            return;
        }

        try {
            setLoading(true);
            let data = {}

            if (isEmail) {
                data = {
                    email: value,
                    password: password
                }
            }
            else {
                data = {
                    phone: parseInt(value),
                    otp: parseInt(password)
                }
            }
            const response = await axiosInstance.post('/api/user/auth/login', data);
            // console.log(response);
            if (response.status === 200) {
                setLoading(false);
                localStorage.setItem("wh_tk", JSON.stringify(response.data));
                alert("✅Logged in successfully");
                if (window.innerWidth < 600) {
                    navigate('/taskscreen', { replace: true });
                } else {
                    console.log(response.data.data.type=="agent");
                    if(response.data.data.type=="agent"){
                        navigate('/taskscreen', { replace: true });
                    }else{
                        navigate('/', { replace: true });
                    }
                    
                }

            }

        } catch (err) {
            console.log(err);
            setLoading(false);
            alert(err.response.data.message);
        }
    }

    if (localStorage.getItem("wh_tk")) {
        navigate('/', { replace: true });
    }

    const forgotPassword = () => {
        navigate('/forgot-page');
    }

    return (
        <Container>
            <Stack
                direction={window.innerWidth > 600 ? "row" : "column-reverse"}
                sx={{
                    padding: '5rem 0rem 0rem'
                }}
                spacing={2}  >
                <Stack
                    sx={{
                        width: window.innerWidth > 600 ? '50%' : '100%',
                        paddingLeft: '2rem'
                    }} >
                    <Typography
                        variant="h3"
                        sx={{
                            color: '#484848',
                            fontWeight: '500'
                        }} >Sign in</Typography>
                    <Typography
                        variant="p"
                        sx={{
                            color: '#838383',
                            fontWeight: '700',
                            marginTop: '0.5rem'
                        }} >Welcome back , signin to your warehouse account</Typography>
                    <Stack
                        direction="column"
                        spacing={3}
                        sx={{
                            mt: 5,
                            width: '80%'
                        }} >
                        <Input
                            type="email"
                            value={value}
                            setvalue={setValue}
                            style={{
                                border: '1px solid #CCCAD6',
                                borderRadius: '8px',
                                outline: 'none'
                            }}
                            handleChange={handleIdChange}
                            placeholder="Enter email or phone" />

                        {
                            (isPhone === false && isEmail === false && value !== "") &&
                            <Typography
                                variant="p"
                                sx={{
                                    color: 'red',
                                    marginTop: '0.2rem !important',
                                    marginBottom: '0.5rem'
                                }} >Phone or Email entered is Invalid</Typography>
                        }
                        {
                            (isPhone === true) &&
                            <Button
                                sx={{
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    // padding: '0.5rem 0rem',
                                    width: '100px',
                                    // borderRadius: '40px',
                                    fontSize: "0.8rem!important",
                                    '&:hover': {
                                        backgroundColor: '#000',
                                        opacity: '0.8'
                                    }
                                }}
                                onClick={sendOtp}
                            >
                                {
                                    loading1 ?
                                        <Loading color="#fff" /> :
                                        "Send OTP"
                                }
                            </Button>
                        }

                        <Input
                            value={password}
                            setvalue={setPassword}
                            type="password"
                            style={{
                                border: '1px solid #CCCAD6',
                                borderRadius: '8px',
                                outline: 'none'
                            }}
                            handleChange={handlePassChange}
                            placeholder={isPhone ? "Enter OTP" : "Enter password"} />
                        <Button
                            onClick={onLogin}
                            sx={{
                                backgroundColor: '#000',
                                color: '#fff',
                                padding: '0.5rem 0rem',
                                width: '200px',
                                borderRadius: '40px',
                                '&:hover': {
                                    backgroundColor: '#000',
                                    opacity: '0.8'
                                }
                            }} >
                            {
                                loading ?
                                    <Loading color="#fff" /> :
                                    "Login"
                            }
                        </Button>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: '500'
                                }} >
                                Facing problem to sign in ?
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#484848',
                                    fontSize: "1rem",
                                    fontWeight: '500',
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    transition: "all 0.5s ease",
                                    '&:hover': {
                                        transform: "scale(1.1)",
                                    }
                                }} onClick={forgotPassword} >Forgot password ?</Typography>

                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        width: window.innerWidth > 600 ? '50%' : '100%'
                    }} >
                    <Stack sx={{
                        width: '100%',
                        height: '90%'
                    }} >
                        <img style={{ width: '100%', height: '100%' }} src={wh_sign_lbg} alt="warehouse img" />
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    )
}

export default Login