import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from "./RowsInput";
import AddIcon from '@mui/icons-material/Add';
// import Data from "./Data";
import { Stack } from '@mui/material';
import axiosInstance from '../../axios/AxiosInstance';
import Select from "../../components/select/Select";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DatePickerMui from "../../components/datepickerMUI/DatePickerMui";
import ChipSelect from '../userrole/ChipEditRole'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const BasicModal = ({ flag, setFlag, data }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setFlag(!flag);
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setStartTime(null);
    setEndTime(null);
  }

  const [datalist, setList] = useState([]);
  const [row, setRows] = useState({});
  const [Data, setData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  // const [startMinDate , setStartMinDate] = useState(null);
  const [date , setDate] = useState(null);
  const [rolename , setRolename] = useState({});
  const [arrKey , setArrKey] = useState("");

  // console.log(master)

  const addRowsData = async () => {

    let obj = {};
    if(arrKey.length !== 0 ){

      // console.log(rolename[arrKey].length);
      // console.log(rolename[arrKey]);
      let key = arrKey.toLocaleLowerCase();
      obj={...row,[key]:rolename[arrKey]};
      // console.log(obj);
      setRows(obj);
    }
    // console.log(obj)

    let arr = [];
    let cnt = Object.keys(obj).length;
    if( cnt !== 0) arr.push(obj);
    else arr.push(row);
    // console.log(arr);
    const body = {
      rows: arr
    }

    const res = await axiosInstance.post(`api/master/${data._id}`, body);
    if (res.status === 200) {
      alert('Fields added sucessfully');
    }
    else {
      alert('Something went wrong');
    }

    setRows({});
    handleClose();
    setFlag(!flag);
  }

  const handleChange = (ev) => {
    const key = (ev.target.name).toLowerCase();
    setRows({ ...row, [key]: ev.target.value, status: true });
  }

  const handleDate = (date,key)=>{
    // console.log(key);
    key = key.toLowerCase();
    let finaldate = '';
    finaldate = finaldate + date.$D + '/' + date.$M + '/' + date.$y;
    setRows({...row , [key]:finaldate, status:true});
    // console.log(date);
  }

  // const handleArray = ({data},key)=>{
  //   console.log(data ,key,rolename);
  //   key = key.toLowerCase();
  //   setRows({...row , [key]:data, status:true});
  // }


  useEffect(() => {
    const getData = async () => {
      const res = await axiosInstance.get('api/master');
      // console.log(res);
      setData(res.data.data);

      const newarr = res.data.data;

      for (let i = 0; i < newarr.length; i++) {
        if (newarr[i]._id === data._id) {

          setList(newarr[i].fields);
          break;
        }
      }
    }
    getData();

  }, [flag])

  // console.log(datalist);

  return (
    <div>
      <Button variant="contained"
        sx={{
          height: "40px",
          backgroundColor: '#000',
          color: '#fff',
          // marginTop:"10px",
          fontSize: "0.875rem !important",
          '&:hover': {
            backgroundColor: '#000',
            opacity: '0.8'
          }
        }} onClick={handleOpen}>
        <AddIcon sx={{
          marginRight: '0.5rem'
        }} />
        Add Rows
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          maxHeight: "100vh",
          overflow: "scroll"
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Row Data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {
              datalist.map((curr) => {
                if (curr.type === 'dropdown') {
                  return (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                      <Typography sx={{
                        fontSize: '1rem',
                        fontWeight: '500',
                      }} >{curr.val}</Typography>
                      <Select boxstyle={{ height: "40px" }} style={{ height: "40px" }} name={curr.val} items={curr.items} value={row[curr.val]} handleChange={handleChange} lablestyle={{ top: "-8px" }} label="Select" />
                    </div>
                  )
                }
                else if (curr.type === 'array') {
                  let newarr = [];
                  let name = curr.items.map((item)=>item.value);

                  // console.log(curr , name);
                  return (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                      <Typography sx={{
                        fontSize: '1rem',
                        fontWeight: '500',
                      }} >{curr.val}</Typography>
                      {/* <Select boxstyle={{ height: "40px" }} style={{ height: "40px" }} name={curr.val} items={curr.items} value={row[curr.val]} handleChange={handleChange} lablestyle={{ top: "-8px" }} label="Select" /> */}
                      <ChipSelect names = {name} newarr={newarr} rolename={rolename} setArrKey = {setArrKey} unikey={curr.val} setRolename={setRolename} />
                    </div>
                  )
                }
                else if (curr.type === 'date') {

                  return (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                      <Typography sx={{
                        fontSize: '1rem',
                        fontWeight: '500',
                        marginTop:'15px',
                        marginRight:'40px'
                      }} >{curr.val}</Typography>
                      <DatePickerMui value={date} setValue={setDate} handleDate = {handleDate} name={curr.val} label={curr.val} />
                    </div>
                  )
                }
                else if (curr.type === 'time' && curr.val === 'start_time') {
                  return (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                      <Typography sx={{
                        fontSize: '1rem',
                        fontWeight: '500',
                      }} >{curr.val}</Typography>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label={curr.val}
                          value={startTime}
                          onChange={(newValue) => {
                            setStartTime(newValue);
                            const key = (curr.val).toLowerCase();
                            setRows({ ...row, [key]: (newValue), status: true });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  )
                }
                else if (curr.type === 'time' && curr.val === 'end_time') {
                  return (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                      <Typography sx={{
                        fontSize: '1rem',
                        fontWeight: '500',
                      }} >{curr.val}</Typography>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label={curr.val}
                          // Display time in IST timezone
                          value={endTime}
                          onChange={(newValue) => {
                            setEndTime(newValue);
                            const key = (curr.val).toLowerCase();
                            setRows({ ...row, [key]: (newValue), status: true });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  )
                }
                return (
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                    <Typography sx={{
                      fontSize: '1rem',
                      fontWeight: '500',
                    }} >{curr.val}</Typography>
                    <Input style={{ width: "60%" }} type={curr.type} placeholder="Enter value" name={curr.val} value={row[curr.val]} handleChange={handleChange} />
                  </div>
                )
              })
            }

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: "100%",
                marginTop: "20px"
              }}
            >
              <Button variant="contained"
                sx={{
                  height: "40px",
                  backgroundColor: '#000',
                  color: '#fff',
                  // marginTop:"40px",
                  fontSize: "0.8rem !important",
                  '&:hover': {
                    backgroundColor: '#000',
                    opacity: '0.8'
                  }
                }}
                onClick={() => {
                  setRows({})
                  handleClose()
                }}
              >
                Cancel
              </Button>

              <Button variant="contained"
                sx={{
                  height: "40px",
                  backgroundColor: '#000',
                  color: '#fff',
                  // marginTop:"40px",
                  fontSize: "0.8rem !important",
                  '&:hover': {
                    backgroundColor: '#000',
                    opacity: '0.8'
                  }
                }}
                onClick={addRowsData}
              >
                Done
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;
