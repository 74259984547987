import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Stack } from "@mui/system";
import moment from "moment/moment";

const SlotCard = (props) => {
  function handleDuration() {
    // console.log(props);
    const start = moment(props.startTime, "HH:mm");
    const end = moment(props.endTime, "HH:mm");
    const duration = moment.duration(end.diff(start));
    const hours =
      duration.asHours() < 0 ? 12 + duration.asHours() : duration.asHours();
    return hours;
  }

  const duration = handleDuration();

  const [isHovered, setIsHovered] = useState(false);
  const [taskLimitTotal, settaskLimitTotal] = useState(0)
  function isNotMoreThanHalf(value1, value2) {

    const num1 = Number(value1);
    const num2 = Number(value2);
    if(num2 == num1 ){
      return false
    }else{
      return num2 >= num1 ;
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "max-content",
        border: "1px solid #3D3C42",
        borderRadius: "5px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        marginTop: "1rem",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* {
          !isHovered && */}
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.7rem",
                fontWeight: "500",
                paddingLeft: ".5rem",
                paddingRight: ".3rem",
              }}
            >
              {props.startTime}
            </Typography>

            <Typography
              sx={{
                fontSize: "0.7rem",
                fontWeight: "500",
                paddingLeft: ".3rem",
                paddingRight: ".5rem",
              }}
            >
              {props.endTime}
            </Typography>
          </div>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              marginTop: "0.1rem",
            }}
          >
            {props.master ? props?.holiday || props?.weekoff ? <h1 style={{ marginBottom: "5px", fontSize: "10px", backgroundColor: "orange", padding: "2px", borderRadius: "10px", }}>WH status :- Off</h1> : <h1 style={{ marginBottom: "5px", fontSize: "10px", backgroundColor: "#00FF7F", padding: "2px", borderRadius: "10px", }}>WH status :- Working</h1> : <>
              <AccessTimeIcon
                sx={{
                  color: "#7e7e7e",
                  fontSize: "0.8rem",
                }}
              />
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontWeight: "500",
                  color: "#7e7e7e",
                }}
              >
                {duration} hrs
              </Typography>
            </>}

          </Stack>
          <Typography
            sx={{
              fontSize: "0.7rem",
              fontWeight: "500",
              paddingLeft: "2em",
              paddingRight: "2em",
              backgroundColor: props.master ? isNotMoreThanHalf(props.deliveryCount, props?.taskLimit?.delivery) ? "" : "#872323" : props.deliveryAvailable ? "" : "#872323",
              color: props.master ? isNotMoreThanHalf(props.deliveryCount, props?.taskLimit?.delivery) ? "" : "#fff" : props.deliveryAvailable ? "" :"#fff",
              borderRadius: "5px",
              marginBottom: "1px",
            }}
          >

            Delivery : {props.master ? props.deliveryCount + " / " + props.taskLimit.delivery : props.deliveryCount}
          </Typography>

          <Typography
            sx={{
              fontSize: "0.7rem",
              fontWeight: "500",
              paddingLeft: "2.1em",
              paddingRight: "2.1em",
              backgroundColor: props.master ? isNotMoreThanHalf(props.pickupCount, props?.taskLimit?.pickup) ? "" : "#872323" : props.pickupAvailable ? "" : "#872323",
              color:  props.master ? isNotMoreThanHalf(props.pickupCount, props?.taskLimit?.pickup) ? "" : "#fff" : props.pickupAvailable ? "" : "#fff",
              borderRadius: "5px",
              marginBottom: "1px",
            }}
          >
            Pickup : {props.master ? props.pickupCount + " / " + props.taskLimit.pickup : props.pickupCount}
          </Typography>

          <Typography
            sx={{
              fontSize: "0.7rem",
              fontWeight: "500",
              paddingLeft: "2.3em",
              paddingRight: "2.3em",
              backgroundColor: props.master ? isNotMoreThanHalf(props.srCount, props.taskLimit["service request"]) ? "" : "#872323" : props.srAvailable ? "" : "#872323",
              color:props.master ? isNotMoreThanHalf(props.srCount, props.taskLimit["service request"]) ? "" : "#fff" : props.srAvailable ? "" : "#fff",
              borderRadius: "5px",
            }}
          >
            SR : {props.master ? props.srCount + " / " + props.taskLimit["service request"] : props.srCount}
          </Typography>

          {
            props.master && <Typography
              sx={{
                marginTop: "10px",
                fontSize: "0.7rem",
                fontWeight: "500",
                paddingLeft: "2.3em",
                paddingRight: "2.3em",
                backgroundColor: props.master ? isNotMoreThanHalf(props.srCount + props.pickupCount + props.deliveryCount, Number(props?.taskLimit["service request"]) + Number(props?.taskLimit.pickup) + Number(props?.taskLimit.delivery)) ? "" : "#872323" : props.srAvailable ? "" : "#872323",
                color:  props.master ? isNotMoreThanHalf(props.srCount + props.pickupCount + props.deliveryCount, Number(props?.taskLimit["service request"]) + Number(props?.taskLimit.pickup) + Number(props?.taskLimit.delivery)) ? "" : "#fff" : props.srAvailable ? "" : "#fff",
                borderRadius: "5px",
              }}
            >
              Total : {props.master ? (props.srCount + props.pickupCount + props.deliveryCount) + " / " + (Number(props?.taskLimit["service request"]) + Number(props?.taskLimit.pickup) + Number(props?.taskLimit.delivery)) : props.srCount}
            </Typography>
          }
          {/* <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            marginTop: '0.5rem',
          }}
        >
        </Stack> */}
        </>
        {/* } */}

        {/* {isHovered &&

          <>
            <Typography
              sx={{
                fontSize: '0.85rem',
                fontWeight: '500',
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
            >
              Delivery: {props.deliveryCount}
            </Typography>

            <Typography
              sx={{
                fontSize: '0.85rem',
                fontWeight: '500',
              }}
            >
              Pickup: {props.pickupCount}
            </Typography>

            <Typography
              sx={{
                fontSize: '0.85rem',
                fontWeight: '500',
              }}
            >
              SR: {props.srCount}
            </Typography>
          </>
        } */}
      </div>

      {/* {!isHovered &&
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            marginTop: '0.5rem',
          }}
        >
          <AccessTimeIcon
            sx={{
              color: '#7e7e7e',
              fontSize: '0.8rem',
            }}
          />
          <Typography
            sx={{
              fontSize: '0.7rem',
              fontWeight: '500',
              color: '#7e7e7e'
            }}
          >
            {duration} hrs
          </Typography>
        </Stack>
      } */}
    </Box>
  );
};

export default SlotCard;
