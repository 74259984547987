import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { toast } from "react-toastify";
import axiosInstance from "../../axios/AxiosInstance";

const InputBox = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 18px;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "black",
};

export default function UpdateRemark({ data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [string, setString] = React.useState("");

  const handleKeyDown = async (event) => {
    if (event.key == "Enter") {
      let body = {
        comment: event.target.value,
        userId: JSON.parse(localStorage.getItem("wh_tk")).data._id
      }
      const res = await axiosInstance.post(`api/ticket/${data.cell.row.original._id}`, body);
      if (res.status === 200) {
        toast.success("Update successfull");
      } else {
        return alert("Something went wrong !!");
      }
    }
  };
  return (
    <div>
      {data.comment ? <p onClick={handleOpen}>
        {data.comment}
      </p> : <Button
        sx={{
          whiteSpace: "nowrap",
          height: "30px",
          backgroundColor: "#000",
          color: "#fff",
          fontSize: "13px !important",
          "&:hover": {
            backgroundColor: "#000",
            opacity: "0.8",
          },
          "@media (max-width: 785px)": {
            fontSize: "0.6rem !important",
            // width:"60px",
            height: "30px",
          },
        }}
        onClick={handleOpen}
      >
        Update
      </Button>
      }



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Update Remark</h3>
          <InputBox
            value={data?.cell?.row?.original?.comment}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Modal>
    </div>
  );
}
