import React, { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import { Typography, Box } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import DatePicker from "react-datepicker";
import axiosInstance, { BASE_URL, BASE_LOCAL } from "../../axios/AxiosInstance";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableV1, { StyledTableCell } from "./TableV1/TableV1";
ChartJS.register(ArcElement, Tooltip, Legend);

const City = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [Mumbai, setMumbai] = useState([]);
  const [Hyderabad, setHyderabad] = useState([]);
  const [Gurgaon, setGurgaon] = useState([]);
  const [Bangalore, setBangalore] = useState([]);
  const [Pune, setPune] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [deliverysCount, setDeliverysCount] = useState([]);
  const [pickupsCount, setPickups] = useState([]);
  const [srsCount, setSrs] = useState([]);
  const [TotalCount, setTotalCount] = useState(0);
  let city = ["Bangalore", "Gurgaon", "Hyderabad", "Mumbai", "Pune"];
  let getData = async () => {
    const tableRes = await axiosInstance
      .get(`/api/dashboard/time-analytics-count?start=${start}&end=${end}`)
      .then((res) => {
        let Mumbai = [];
        let Hyderabad = [];
        let Gurgaon = [];
        let Bangalore = [];
        let Pune = [];
        for (let index = 0; index < res.data.data.length; index++) {
          const element = res.data.data[index];
          Mumbai.push({
            _id: element._id,
            vehicle_count: element.Mumbai.length,
            count: element.Mumbai_count,
            Mumbai_adhoc_vehicle: element.Mumbai_adhoc_vehicle.length,
            Mumbai_transportId: element.Mumbai_transportId.length,
          });
          Hyderabad.push({
            _id: element._id,
            vehicle_count: element.Hyderabad.length,
            count: element.Hyderabad_count,
            Hyderabad_adhoc_vehicle: element.Hyderabad_adhoc_vehicle.length,
            Hyderabad_transportId: element.Hyderabad_transportId.length,
          });
          Gurgaon.push({
            _id: element._id,
            vehicle_count: element.Gurgaon.length,
            count: element.Gurgaon_count,
            Gurgaon_adhoc_vehicle: element.Gurgaon_adhoc_vehicle.length,
            Gurgaon_transportId: element.Gurgaon_transportId.length,
          });
          Bangalore.push({
            _id: element._id,
            vehicle_count: element.Bangalore.length,
            count: element.Bangalore_count,
            Bangalore_adhoc_vehicle: element.Bangalore_adhoc_vehicle.length,
            Bangalore_transportId: element.Bangalore_transportId.length,
          });
          Pune.push({
            _id: element._id,
            vehicle_count: element.Pune.length,
            count: element.Pune_count,
            Pune_adhoc_vehicle: element.Pune_adhoc_vehicle.length,
            Pune_transportId: element.Pune_transportId.length,
          });
        }
        setMumbai(Mumbai);
        setHyderabad(Hyderabad);
        setGurgaon(Gurgaon);
        setBangalore(Bangalore);
        setPune(Pune);
      });
  };
  let handleDate = (event) => {
    setDateRange(event);
    if (event[0] != null && event[1] != null) {
      let start = new Date(event[0]);
      let end = new Date(event[1]);
      end = end.setHours(23, 59, 59, 999);
      end = new Date(end).toUTCString();
      setStart(new Date(start).getTime());
      setEnd(new Date(end).getTime());
      getData();
    }
  };

  let getCount = async () => {
    const deliverys = await axiosInstance
      .get(`/api/dashboard/deliverys-analytics-count?start=${start}&end=${end}`)
      .then((res) => {
        setDeliverysCount(res.data.data);
      });
    const pickups = await axiosInstance
      .get(`/api/dashboard/pickups-analytics-count?start=${start}&end=${end}`)
      .then((res) => {
        setPickups(res.data.data);
      });
    const srs = await axiosInstance
      .get(`/api/dashboard/srs-analytics-count?start=${start}&end=${end}`)
      .then((res) => {
        setSrs(res.data.data);
        setLoaded(true);
      });
  };
  let getStartingDate = () => {
    var targetDate = new Date();
    var thirtyDaysAgo = new Date(targetDate);
    thirtyDaysAgo.setDate(targetDate.getDate() - 7);
    thirtyDaysAgo.setHours(23, 59, 59, 999);
    setDateRange([targetDate, thirtyDaysAgo]);
    let start = new Date(targetDate).toUTCString();
    let end = new Date(thirtyDaysAgo).toUTCString();
    setStart(new Date(end).getTime());
    setEnd(new Date(start).getTime());
  };
  let getAllTotal = () => {
    let data = city.map((item) => {
      return (
        srsCount.find((city) => city._id === item)?.total +
        pickupsCount.find((city) => city._id === item)?.total +
        deliverysCount.find((city) => city._id === item)?.total
      );
    });
    return data.reduce((sum, city) => sum + city, 0);
  };

  let getAllDoneTotal = () => {
    let data = city.map((item) => {
      return (
        srsCount.find((city) => city._id === item)?.doneTask +
        pickupsCount.find((city) => city._id === item)?.doneTask +
        deliverysCount.find((city) => city._id === item)?.doneTask
      );
    });
    return data.reduce((sum, city) => sum + city, 0);
  };

  let getTotal = () => {
    let data = [];
    data.push(
      (
        (srsCount.find((city) => city._id == "Pune")?.total +
          pickupsCount.find((city) => city._id === "Pune")?.total +
          deliverysCount.find((city) => city._id === "Pune")?.total) /
        Pune.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Mumbai")?.total +
          pickupsCount.find((city) => city._id === "Mumbai")?.total +
          deliverysCount.find((city) => city._id === "Mumbai")?.total) /
        Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Pune")?.total +
          pickupsCount.find((city) => city._id === "Hyderabad")?.total +
          deliverysCount.find((city) => city._id === "Hyderabad")?.total) /
        Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Gurgaon")?.total +
          pickupsCount.find((city) => city._id === "Gurgaon")?.total +
          deliverysCount.find((city) => city._id === "Gurgaon")?.total) /
        Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Bangalore")?.total +
          pickupsCount.find((city) => city._id === "Bangalore")?.total +
          deliverysCount.find((city) => city._id === "Bangalore")?.total) /
        Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    return data;
  };

  let getTotalDone = () => {
    let data = [];
    data.push(
      (
        (srsCount.find((city) => city._id == "Pune")?.doneTask +
          pickupsCount.find((city) => city._id === "Pune")?.doneTask +
          deliverysCount.find((city) => city._id === "Pune")?.doneTask) /
        Pune.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Mumbai")?.doneTask +
          pickupsCount.find((city) => city._id === "Mumbai")?.doneTask +
          deliverysCount.find((city) => city._id === "Mumbai")?.doneTask) /
        Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Pune")?.doneTask +
          pickupsCount.find((city) => city._id === "Hyderabad")?.doneTask +
          deliverysCount.find((city) => city._id === "Hyderabad")?.doneTask) /
        Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Gurgaon")?.doneTask +
          pickupsCount.find((city) => city._id === "Gurgaon")?.doneTask +
          deliverysCount.find((city) => city._id === "Gurgaon")?.doneTask) /
        Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    data.push(
      (
        (srsCount.find((city) => city._id == "Bangalore")?.doneTask +
          pickupsCount.find((city) => city._id === "Bangalore")?.doneTask +
          deliverysCount.find((city) => city._id === "Bangalore")?.doneTask) /
        Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0)
      ).toFixed(2)
    );

    return data;
  };
  useEffect(() => {
    getStartingDate();
  }, []);

  useEffect(() => {
    if (start && end) {
      getData();
      getCount();
    }
  }, [start, end]);

  const renderEmptyRow = (
    <TableRow>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell></StyledTableCell>
    </TableRow>
  );

  const MainInnerTable = ({ name, data }) => (
    <>
      <TableRow>
        <StyledTableCell sx={{ textAlign: "center" }}>
          <h3>{name}</h3>
        </StyledTableCell>
        <StyledTableCell sx={{ padding: "0px !important" }}>
          <div className="dataDiv" style={{ backgroundColor: "#b2faaa" }}>
            <h4>Done</h4>
          </div>
          <div className="dataDiv" style={{ backgroundColor: "#ffdfde" }}>
            <h4>Not Done</h4>
          </div>
          <div className="dataDiv">
            <h4>Done %</h4>
          </div>
        </StyledTableCell>

        {data.map((item) => {
          return (
            <StyledTableCell key={item._id} sx={{ padding: "0px !important" }}>
              <div className="dataDiv">{item.doneTask}</div>
              <div className="dataDiv">{item?.total - item.doneTask}</div>
              <div className="dataDiv">
                {((item.doneTask / item?.total) * 100).toFixed(2)}%
              </div>
            </StyledTableCell>
          );
        })}
        <StyledTableCell sx={{ padding: "0px !important" }}>
          <div className="dataDiv">
            {data.reduce((sum, city) => sum + city.doneTask, 0)}
          </div>
          <div className="dataDiv">
            {data.reduce((sum, city) => sum + (city?.total - city.doneTask), 0)}
          </div>
          <div className="dataDiv">
            {(
              (data.reduce((sum, city) => sum + city.doneTask, 0) /
                data.reduce((sum, city) => sum + city?.total, 0)) *
              100
            ).toFixed(2)}
            %
          </div>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center">
          <h4>Total Assigned</h4>
        </StyledTableCell>
        {data.map((item) => {
          return (
            <StyledTableCell key={item._id} align="center">
              {item?.total}
            </StyledTableCell>
          );
        })}
        <StyledTableCell align="center">
          {data.reduce((sum, city) => sum + city?.total, 0)}
        </StyledTableCell>
      </TableRow>
    </>
  );
  const FinalInnerTable = ({ SR, PickUp, Delivery }) => {
    return (
      <>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell
            style={{ backgroundColor: "#b2faaa" }}
            align="center"
          >
            <h4>Total Assigned</h4>
          </StyledTableCell>
          {city.map((item) => {
            return (
              <StyledTableCell
                key={item}
                style={{ backgroundColor: "#b2faaa" }}
                align="center"
              >
                {Delivery.find((city) => city._id === item)?.total +
                  PickUp.find((city) => city._id === item)?.total +
                  SR.find((city) => city._id === item)?.total}
              </StyledTableCell>
            );
          })}
          <StyledTableCell
            style={{ backgroundColor: "#b2faaa" }}
            align="center"
          >
            {getAllTotal()}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Reg</h4>
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_transportId,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Gurgaon.reduce((sum, city) => sum + city.Gurgaon_transportId, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Hyderabad.reduce(
              (sum, city) => sum + city.Hyderabad_transportId,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Mumbai.reduce((sum, city) => sum + city.Mumbai_transportId, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Pune.reduce((sum, city) => sum + city.Pune_transportId, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_transportId,
              0
            ) +
              Gurgaon.reduce((sum, city) => sum + city.Gurgaon_transportId, 0) +
              Hyderabad.reduce(
                (sum, city) => sum + city.Hyderabad_transportId,
                0
              ) +
              Mumbai.reduce((sum, city) => sum + city.Mumbai_transportId, 0) +
              Pune.reduce((sum, city) => sum + city.Pune_transportId, 0)}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Ad-hoc</h4>
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_adhoc_vehicle,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Gurgaon.reduce((sum, city) => sum + city.Gurgaon_adhoc_vehicle, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Hyderabad.reduce(
              (sum, city) => sum + city.Hyderabad_adhoc_vehicle,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Mumbai.reduce((sum, city) => sum + city.Mumbai_adhoc_vehicle, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Pune.reduce((sum, city) => sum + city.Pune_adhoc_vehicle, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_adhoc_vehicle,
              0
            ) +
              Gurgaon.reduce(
                (sum, city) => sum + city.Gurgaon_adhoc_vehicle,
                0
              ) +
              Hyderabad.reduce(
                (sum, city) => sum + city.Hyderabad_adhoc_vehicle,
                0
              ) +
              Mumbai.reduce((sum, city) => sum + city.Mumbai_adhoc_vehicle, 0) +
              Pune.reduce((sum, city) => sum + city.Pune_adhoc_vehicle, 0)}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Vehicle Count</h4>
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Pune.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Pune.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Efficiency</h4>
          </StyledTableCell>
          {getTotal().map((item) => {
            return <StyledTableCell align="center">{item}</StyledTableCell>;
          })}
          <StyledTableCell align="center">
            {(
              getAllTotal() /
              Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0)
            ).toFixed(2) +
              Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Pune.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
        </TableRow>
      </>
    );
  };

  const FinalDoneInnerTable = ({ SR, PickUp, Delivery }) => {
    return (
      <>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell
            style={{ backgroundColor: "#b2faaa" }}
            align="center"
          >
            <h4>Total Done</h4>
          </StyledTableCell>
          {city.map((item) => {
            return (
              <StyledTableCell
                key={item}
                style={{ backgroundColor: "#b2faaa" }}
                align="center"
              >
                {Delivery.find((city) => city._id === item)?.doneTask +
                  PickUp.find((city) => city._id === item)?.doneTask +
                  SR.find((city) => city._id === item)?.doneTask}
              </StyledTableCell>
            );
          })}
          <StyledTableCell
            style={{ backgroundColor: "#b2faaa" }}
            align="center"
          >
            {getAllDoneTotal()}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Reg</h4>
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_transportId,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Gurgaon.reduce((sum, city) => sum + city.Gurgaon_transportId, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Hyderabad.reduce(
              (sum, city) => sum + city.Hyderabad_transportId,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Mumbai.reduce((sum, city) => sum + city.Mumbai_transportId, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Pune.reduce((sum, city) => sum + city.Pune_transportId, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_transportId,
              0
            ) +
              Gurgaon.reduce((sum, city) => sum + city.Gurgaon_transportId, 0) +
              Hyderabad.reduce(
                (sum, city) => sum + city.Hyderabad_transportId,
                0
              ) +
              Mumbai.reduce((sum, city) => sum + city.Mumbai_transportId, 0) +
              Pune.reduce((sum, city) => sum + city.Pune_transportId, 0)}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Ad-hoc</h4>
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_adhoc_vehicle,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Gurgaon.reduce((sum, city) => sum + city.Gurgaon_adhoc_vehicle, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Hyderabad.reduce(
              (sum, city) => sum + city.Hyderabad_adhoc_vehicle,
              0
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Mumbai.reduce((sum, city) => sum + city.Mumbai_adhoc_vehicle, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Pune.reduce((sum, city) => sum + city.Pune_adhoc_vehicle, 0)}
          </StyledTableCell>

          <StyledTableCell align="center">
            {Bangalore.reduce(
              (sum, city) => sum + city.Bangalore_adhoc_vehicle,
              0
            ) +
              Pune.reduce((sum, city) => sum + city.Pune_adhoc_vehicle, 0) +
              Mumbai.reduce((sum, city) => sum + city.Mumbai_adhoc_vehicle, 0) +
              Hyderabad.reduce(
                (sum, city) => sum + city.Hyderabad_adhoc_vehicle,
                0
              ) +
              Gurgaon.reduce(
                (sum, city) => sum + city.Gurgaon_adhoc_vehicle,
                0
              )}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Vehicle Count</h4>
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Pune.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
          <StyledTableCell align="center">
            {Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0) +
              Pune.reduce((sum, city) => sum + city.vehicle_count, 0)}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell align="center"></StyledTableCell>
          <StyledTableCell align="center">
            <h4>Efficiency</h4>
          </StyledTableCell>
          {getTotalDone().map((item) => {
            return <StyledTableCell align="center">{item}</StyledTableCell>;
          })}
          <StyledTableCell align="center">
            {(
              getAllDoneTotal() /
              (Pune.reduce((sum, city) => sum + city.vehicle_count, 0) +
                Mumbai.reduce((sum, city) => sum + city.vehicle_count, 0) +
                Hyderabad.reduce((sum, city) => sum + city.vehicle_count, 0) +
                Gurgaon.reduce((sum, city) => sum + city.vehicle_count, 0) +
                Bangalore.reduce((sum, city) => sum + city.vehicle_count, 0))
            ).toFixed(2)}
          </StyledTableCell>
        </TableRow>
      </>
    );
  };

  const table = (
    <>
      {loaded && <MainInnerTable name={"Delivery"} data={deliverysCount} />}
      {renderEmptyRow}
      <MainInnerTable name={"PickUp"} data={pickupsCount} />
      {renderEmptyRow}
      <MainInnerTable name={"SR"} data={srsCount} />
      {renderEmptyRow}
      <FinalInnerTable
        SR={srsCount}
        PickUp={pickupsCount}
        Delivery={deliverysCount}
      />
      {renderEmptyRow}
      <FinalDoneInnerTable
        SR={srsCount}
        PickUp={pickupsCount}
        Delivery={deliverysCount}
      />
    </>
  );

  let MyTable = ({ data, cityName }) => {
    return (
      <Box>
        <h4 style={{ paddingTop: "5px", textAlign: "center" }}>{cityName}</h4>
        <TableContainer>
          <Table sx={{}}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Task count</TableCell>
                <TableCell align="left">Vehicle Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                  <TableCell align="right">{row.vehicle_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <div>
      {loaded && (
        <>
          {" "}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
              mt: "60px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              Analytics
            </Typography>
            <Box sx={{ marginRight: "1pc" }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                id={`DatePicker`}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDate}
                isClearable={true}
              />
            </Box>
          </Stack>
          <Box>
            <div style={{ margin: "30px" }}>
              <TableV1
                tableHead={
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="center">
                      <h3>Bangalore</h3>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <h3>Gurgaon</h3>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <h3>Hyderabad</h3>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <h3>Mumbai</h3>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <h3>Pune</h3>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <h3>Grand Total</h3>
                    </StyledTableCell>
                  </TableRow>
                }
                tableBody={table}
              />
            </div>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {
              <Box sx={{ margin: "10px", border: "solid black 1px" }}>
                {loaded && (
                  <MyTable data={Bangalore} cityName="Bangalore">
                    {" "}
                  </MyTable>
                )}
              </Box>
            }
            {
              <Box sx={{ margin: "10px", border: "solid black 1px" }}>
                {loaded && (
                  <MyTable data={Gurgaon} cityName="Gurgaon">
                    {" "}
                  </MyTable>
                )}
              </Box>
            }
            {
              <Box sx={{ margin: "10px", border: "solid black 1px" }}>
                {loaded && (
                  <MyTable data={Hyderabad} cityName="Hyderabad">
                    {" "}
                  </MyTable>
                )}
              </Box>
            }
            {
              <Box sx={{ margin: "10px", border: "solid black 1px" }}>
                {loaded && (
                  <MyTable data={Mumbai} cityName="Mumbai">
                    {" "}
                  </MyTable>
                )}
              </Box>
            }
            {
              <Box sx={{ margin: "10px", border: "solid black 1px" }}>
                {loaded && (
                  <MyTable data={Pune} cityName="Pune">
                    {" "}
                  </MyTable>
                )}
              </Box>
            }
          </Box>
        </>
      )}
    </div>
  );
};

export default City;
