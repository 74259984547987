import React from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Stack, Typography, Button } from "@mui/material";
import moment from "moment-timezone";
const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const MobileTableRow = ({ row, openDetails }) => {
  let name;
  if (row.firstName === null) {
    name = row.lastName;
  } else if (row.lastName === null) {
    name = row.firstName;
  } else name = row.firstName + " " + row.lastName;

  // console.log("check this date ", row);
  let format = null;
  let time = null;
  if (row.scheduledDate) {
    const date = new Date(row.scheduledDate);
    format = date.toISOString().split("T")[0];
    time = date.toTimeString().split(":").slice(0, 2).join(":");
  }
  function convertToIST(utcDateTime) {
    let istDate = moment(utcDateTime).tz("Asia/Kolkata");
    return istDate.format("YYYY-MM-DD HH:mm:ss");
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "100%",
        borderBottom: "1px solid grey",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        sx={{
          width: "80%",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
            }}
          >
            Name :
          </Typography>

          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "300",
            }}
          >
            {name}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
            }}
          >
            Phone :
          </Typography>

          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "300",
            }}
          >
            {row.phone}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
            }}
          >
            Sub-Category :
          </Typography>

          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "300",
            }}
          >
            {row.subCategory}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
            }}
          >
            Scheduled D. :
          </Typography>

          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "300",
            }}
          >
            
            {row.scheduledDate ? convertToIST(row.scheduledDate) : null}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
            }}
          >
            Status :
          </Typography>

          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "300",
            }}
          >
            {" "}
            {row.status}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "20%",
        }}
        onClick={() => {
          openDetails(row);
        }}
      >
        <ArrowForwardIosIcon />
      </Stack>
    </Stack>
  );
};

export default MobileTableRow;
