import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Select from "./Agentselect";
import axiosInstance from "../../../axios/AxiosInstance";
import DatePickerMui from "../../../components/datepickerMUI/DatePickerMui";
import FilterDatePicker from "../../../components/datepickerMUI/FilterDatePicker";
import dayjs from "dayjs";
import TimePicker from "../../../components/datepickerMUI/TimePicker";
import ClearIcon from "@mui/icons-material/Clear";
import Loading from "../../../components/loading/Loading";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: 330,
  },
};

const pickrdeliveryItems = [
  {
    value: "Pickup Done",
    text: "Pickup Done",
  },
  {
    value: "Pickup Pending",
    text: "Pickup Rescheduled",
  },
];

const new_rentaldelivery = [
  {
    value: "Delivered",
    text: "Delivered",
  },
  {
    value: "Order Verified",
    text: "Delivery Rescheduled",
  },
];

const pickrefItems = [
  {
    value: "Pickup Scheduled",
    text: "Pickup Scheduled",
  },
];

const new_rentalItems = [
  {
    value: "Delivery Scheduled",
    text: "Delivery Scheduled",
  },
];

const else_delivery_items = [
  {
    value: "Completed",
    text: "Completed",
  },
  {
    value: "Pending",
    text: "Rescheduled",
  },
];

const else_items = [
  {
    value: "Scheduled",
    text: "Scheduled",
  },
];

const Week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const Month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const BasicModal = ({
  getAgentdata,
  flag,
  setFlag,
  schedule,
  rowStatus,
  deliver,
  ticketId,
  subcategory,
  getdata,
  row,
  closeDetail,
}) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [status, setStatus] = useState("");
  const [statusItems, setStatusItems] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [startMinDate, setStartMin] = useState(new Date());

  const [edit, setEdit] = useState(false);
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);

  const userid = JSON.parse(localStorage.getItem("wh_tk")).data._id;
  const userData = JSON.parse(localStorage.getItem("wh_tk")).data;

  var isRescheduled = false;

  const handleAgentUpdateDelivery = async () => {
    if (
      row?.files?.length === 0 &&
      (status === "Completed" ||
        status === "Delivered" ||
        status === "Pickup Done")
    ) {
      alert("Please attach the neccessary files before updating delivery");
      return;
    }

    if (time === "") {
      alert("Select Time");
      return;
    }

    if ((deliver === undefined || deliver === null) && deliver === "") {
      alert("Select Status");
      return;
    }

    setLoading(true);

    let finaldate = "";
    finaldate =
      finaldate +
      Week[date.$W] +
      " " +
      Month[date.$M] +
      " " +
      date.$D +
      " " +
      date.$y;
    finaldate =
      finaldate +
      " " +
      (time.$H < 10 ? "0" + time.$H : time.$H) +
      ":" +
      (time.$m < 10 ? "0" + time.$m : time.$m) +
      ":" +
      (time.$s < 10 ? "0" + time.$s : time.$s);
    finaldate = finaldate + " " + "GMT+0530 (India Standard Time)";
    finaldate = new Date(finaldate).toISOString();

    // console.log(finaldate);

    if (schedule && (row.transport == "-" || !row.agentId)) {
      alert("Agent and Transport are required!");
      return;
    }

    try {
      const body = {
        status: status,
        deliverDate: finaldate,
        userId: userid,
        rescheduled: isRescheduled,
        caseId: row.caseId,
      };

      if (schedule) {
        body.transport = row.transport;
        body.agentId = row.agentId;
      }

      if(row.adhoc_vehicle){
        body.adhoc_vehicle=row.adhoc_vehicle
      }

      const res = await axiosInstance.post(`api/ticket/${ticketId}`, body);
      if (res.status === 200) {
        setLoading(false);
        alert("Update successfull");
      } else {
        setLoading(false);
        alert("Something went wrong !!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err.response.data.message);
      
    }

    if (userData.type === "agent") {
      getAgentdata();
    } else {
      getdata();
    }
    handleClose();
    // window.location.reload();
    setFlag(!flag);
    closeDetail();
  };

  const handleStart = (date) => {
    setDate(date);
  };

  const handleAgentUpdate = async () => {
    if ((schedule === undefined || schedule === null) && status === "") {
      alert("Select Status");
      return;
    }

    if (time === "") {
      alert("Select Time");
      return;
    }

    setLoading(true);

    let finaldate = "";
    finaldate =
      finaldate +
      Week[date.$W] +
      " " +
      Month[date.$M] +
      " " +
      date.$D +
      " " +
      date.$y;
    finaldate =
      finaldate +
      " " +
      (time.$H < 10 ? "0" + time.$H : time.$H) +
      ":" +
      (time.$m < 10 ? "0" + time.$m : time.$m) +
      ":" +
      (time.$s < 10 ? "0" + time.$s : time.$s);
    finaldate = finaldate + " " + "GMT+0530 (India Standard Time)";
    // console.log(finaldate, date, time);
    finaldate = new Date(finaldate).toISOString();

    // console.log(finaldate);

    if (schedule && (row.transport == "-" || !row.agentId)) {
      alert("Agent and Transport are required!");
      return;
    }

    try {
      const body = {
        status: status,
        scheduledDate: finaldate,
        userId: userid,
        caseId: row.caseId,
        rescheduled: isRescheduled,
      };
      if (schedule) {
        body.transport = row.transport;
        body.agentId = row.agentId;
      }
      console.log(body);
      const res = await axiosInstance.post(`api/ticket/${ticketId}`, body);
      if (res.status === 200) {
        setLoading(false);
        alert("Update successfull");
      } else {
        setLoading(false);
        if(res.status === 400){
          alert(res.message);
        }else{
          alert("Something went wrong !!");
        }

      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err.response.data.message);
    }
    // console.log(res);
    if (userData.type === "agent") {
      getAgentdata();
    } else {
      getdata();
    }
    handleClose();
    // window.location.reload();
    setFlag(!flag);
    closeDetail();
  };

  const handleReschedule = async () => {
    if (row.transport == "-" || !row.agentId) {
      alert("Agent and Transport are required!");
      return;
    }

    setLoading(true);
    try {
      const body = {
        status: status,
        rescheduled: isRescheduled,
        userId: userid,
        // caseId: row.caseId,
      };
      // if (schedule) {
      //   body.transport = row.transport;
      //   body.agentId = row.agentId;
      // }

      //  console.log("BODY", body);

      const res = await axiosInstance.post(`api/ticket/${ticketId}`, body);
      if (res.status === 200) {
        setLoading(false);
        alert("Update successfull");
      } else {
        setLoading(false);
        alert("Something went wrong !!");
      }
      //console.log("RESPONSE", res);
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong !!");
    }

    if (userData.type === "agent") {
      getAgentdata();
    } else {
      getdata();
    }
    handleClose();
    setFlag(!flag);
    closeDetail();
  };
  // console.log(row)
  useEffect(() => {
    // console.log(schedule, subcategory);
    if (
      row.status == "Completed" ||
      row.status == "Pickup Done" ||
      row.status == "Pickup Failed" ||
      row.status == "Delivery Failed" ||
      row.status == "Delivered" ||
      row.status == "Task Failed"
    ) {
      setEdit(true);
    }

    if (
      row.status === "Pending" ||
      row.status === "Pickup Pending" ||
      row.status === "Order Verified"
    ) {
      if (subcategory === "New - Rental" || subcategory === "New - Buy"|| subcategory === "B2B") {
        setStatusItems(new_rentalItems);
      } else if (subcategory === "Pickup and Refund") {
        setStatusItems(pickrefItems);
      } else {
        setStatusItems(else_items);
      }
    } else {
      if (subcategory === "New - Rental" || subcategory === "New - Buy"|| subcategory === "B2B") {
        setStatusItems(new_rentaldelivery);
      } else if (subcategory === "Pickup and Refund") {
        setStatusItems(pickrdeliveryItems);
      } else {
        setStatusItems(else_delivery_items);
      }
    }
  }, [flag]);
  // console.log("roww ", row.status);
  return (
    <div>
      <Button
        variant="contained"
        disabled={edit}
        sx={{
          height: "40px",
          backgroundColor: "#000",
          color: "#fff",
          // marginTop:"10px",
          fontSize: "0.875rem !important",
          "&:hover": {
            backgroundColor: "#000",
            opacity: "0.8",
          },
        }}
        onClick={handleOpen}
      >
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon
            sx={{
              position: "absolute",
              right: "-30px",
              top: "-22px",
              cursor: "pointer",
              color: "white",
              "@media (max-width: 400px)": {
                right: "10px",
                top: "10px",
                color: "black",
              },
            }}
            onClick={() => {
              setStatus("");
              setTime("");
              handleClose();
            }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Select
              items={statusItems}
              label="Status"
              value={status}
              setValue={setStatus}
            />
            {/* <Select boxstyle={{marginTop:"20px"}} items={substatusItems} label="Sub status" value={subStatus} setValue={setSubStatus}  /> */}
            {/* <DatePickerMui minDate={startMinDate} schedule={schedule} status = {rowStatus} value={date} setValue={setDate} label={null} style={{ marginTop: "20px" }} /> */}
            <FilterDatePicker
              value={date}
              handleChange={handleStart}
              minDate={startMinDate}
              schedule={schedule}
              status={rowStatus}
              label={null}
              style={{ marginTop: "20px" }}
            />
            {/* <DatePickerMui style={{maxWidth:"150px" , marginRight:"2px"}} value={startDate} handleChange={handleStart} label={"Start"} /> */}

            <TimePicker
              style={{ width: "100%", marginTop: "20px" }}
              value={time}
              setValue={setTime}
            />
            <Button
              variant="contained"
              sx={{
                height: "40px",
                backgroundColor: "#000",
                color: "#fff",
                marginTop: "10px",
                fontSize: "0.8rem !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
              }}
              onClick={() => {
                if (schedule === undefined || schedule === null) {
                  isRescheduled = false;
                  handleAgentUpdate();
                } else {
                  if (
                    status === "Order Verified" ||
                    status === "Pending" ||
                    status === "Pickup Pending"
                  ) {
                    isRescheduled = true;
                    handleReschedule();
                  } else {
                    isRescheduled = false;
                    if (
                      status === "" &&
                      (rowStatus === "Scheduled" ||
                        rowStatus === "Delivery Scheduled" ||
                        rowStatus === "Pickup Scheduled")
                    )
                      handleAgentUpdate();
                    else handleAgentUpdateDelivery();
                  }
                }
              }}
            >
              {loading ? <Loading /> : "Ok"}
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
