import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { Container, Row, Col } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import MasterCard from './MasterCard';
import axiosInstance from '../../axios/AxiosInstance';
import { Stack } from '@mui/system';
// import Data from "./Data";



const View = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Main = styled.div`
   
`
const Head = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
`
const Heading = styled.div`
    color:black;
    font-size:1.5rem;
    font-weight:700;        
`

const MasterData = () => {

  const navigate = useNavigate();

  const [showAdd, setShowAdd] = useState(false);
  const [Data, setData] = useState([]);

  useEffect(() => {

    const getData = async () => {
      const res = await axiosInstance.get('api/master');
      console.log(res.data.data);
      setData(res.data.data);
    }
    getData();

    const userData = JSON.parse(localStorage.getItem('wh_tk')).data;
    // console.log(userData);
    const userRole = userData.role;
    const outArr = userData.out;
    const userPermissions = userRole[0].permissions;

    userPermissions.map((curr, index) => {
      if (outArr[index].key === 'customers' && curr.create === true) {
        setShowAdd(true);
      }
    })
  }, [])

  return (
    <View>
      <Main>
        <Head>
          <Heading>Manage master Data</Heading>
          {showAdd &&
            <Button
              variant="contained"
              sx={{
                width: '250px',
                height: "40px",
                backgroundColor: '#000',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#000',
                  opacity: '0.8'
                }
              }}
              onClick={() => { navigate("/masterdata/add_master") }}
            >
              <AddIcon sx={{
                marginRight: '0.5rem'
              }} />
              Add master data
            </Button>
          }
        </Head>

        <Container style={{ marginTop: "30px" }}>
          <Stack
            direction="row"
            // alignItems="center"
            flexWrap="wrap"
          >
            {
              Data.map((data) => {
                return (
                  <MasterCard data={data} />
                )
              })
            }
          </Stack>
        </Container>
      </Main>
    </View>
  )
}

export default MasterData