import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axiosInstance from '../../../axios/AxiosInstance';
import Input from "../../../components/input/Input";
import { Stack } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import Select from "../../../components/select/Select";
import Loading from '../../../components/loading/Loading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const items = [
    {
        value: "offline",
        text: "Offline"
    },
    {
        value: "online",
        text: "Online"
    }
]


const BasicModal = ({ flag, setFlag, id,data }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange = (ev) => {
        setStatus(ev.target.value);
    }

    const getdata = async () => {

        setLoading(true);
        try {
            let body = {}
            if (name !== '') {
                body = { ...body, "name": name }
            }
            if (status !== '') {
                if (status === 'online') body = { ...body, "status": true }
                else body = { ...body, "status": false }
            }
            if (phone !== '') {
                body = { ...body, "phone": phone }
            }
            // console.log(body);
            const res = await axiosInstance.post(`api/agent/${id}`, body)
            if (res.status === 200) {
                setLoading(false);
                alert("update successfull");
                setFlag(!flag);
            }
            else {
                setLoading(false);
                alert("Something went wrong!!");
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false);
            alert("Something went wrong!!");
        }
    }
useEffect(()=>{
    setName(data?.name)
    setPhone(data?.phone)
},[data])

    return (
        <div>
            <Button variant="contained"
                sx={{
                    height: "40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize: "0.875rem !important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }} onClick={handleOpen}>
                {/* <FilterAltIcon sx={{marginRight:"10px"}} /> */}
                Edit
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ClearIcon style={{ position: "absolute", right: "-30px", top: "-22px", cursor: "pointer" }} onClick={() => {
                       
                        setCategory('');
                        setStatus('');
                        setSubCategory('');
                        handleClose();
                    }} />
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Details
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Stack
                            direction="column"
                        >
                            <Stack
                                direction="column"
                                spacing={3}
                                sx={{
                                    marginBottom: "50px",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                    }} >Name</Typography>
                                    <Input value={name} setvalue={setName} style={{ width: "70%" }} type="text"
                                     placeholder={"Enter name"} />
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                    }} >Phone</Typography>
                                    <Input value={phone} setvalue={setPhone} style={{ width: "70%" }} type="number" placeholder="Enter phone" />
                                </Stack>
                                {/* <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >Category</Typography>
                        <Input value={category} setvalue={setCategory} style={{width:"70%"}} type="text" placeholder="Enter category" />    
                    </Stack> */}
                                {/* <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >Sub Category</Typography>
                        <Input value={subCategory} setvalue={setSubCategory} style={{width:"70%"}} type="text" placeholder="Enter sub category" />    
                    </Stack> */}
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                    }} >Status</Typography>

                                    <Select value={status} boxstyle={{ width: "70%", height: "40px" }} items={items} handleChange={handleChange} label="Status" />
                                    {/* <Input value={status} setvalue={setStatus} style={{width:"70%"}} type="text" placeholder="Enter status" />     */}
                                </Stack>
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                // justifyContent="space-between"
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Button variant="contained"
                                    sx={{
                                        height: "40px",
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        fontSize: "0.8rem !important",
                                        '&:hover': {
                                            backgroundColor: '#000',
                                            opacity: '0.8'
                                        }
                                    }} onClick={getdata} >Ok</Button>
                                {loading && <Loading color="black" />}
                            </Stack>
                        </Stack>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default BasicModal;

