import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { TableHead } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const TableV1 = (props) => {
  const { tableHead, tableBody, type } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{
        overflowX: "scroll",
      }}
    >
      <Table aria-label="customized table" size={type}>
        <TableHead>{tableHead}</TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableV1;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
    border: "1px solid black !important",
    padding: "4px 10px", // Adjust cell padding for a more compact layout
    [theme.breakpoints.down("md")]: {
      padding: "4px 8px", // Padding for screens smaller than 768px
      fontSize: 10,
      border: "1px solid black !important",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "4px 10px",
    border: "1px solid black !important",
    height: "20px", // Adjust cell padding for a more compact layout
    [theme.breakpoints.down("md")]: {
      // Padding for screens smaller than 768px
      fontSize: 10,
    },
  },
}));
