
import React, { useEffect, useState } from 'react';
import "./MobileView.css"
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../../axios/AxiosInstance';
import { toast } from "react-toastify";
const MobileView = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        otp: null
    });
    const [check, setCheck] = useState(0);

    async function isStringOrNumber(value) {
        let PHONE_REGEX = new RegExp(/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/);
        const EMAIL_REGEX = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        if (PHONE_REGEX.test(value) === true) {
            const res = await axiosInstance.post('api/user/auth/send_otp', { phone: value });
            if (res.status === 200) {
                toast.success(res.data.message)
            }
            else {
                alert("Something went wrong")
            }
            return 1
        } else if (EMAIL_REGEX.test(value) === true) {
            return 2
        } else {
            return 0
        }
    }

    const handleChange = async (e) => {
        const { name, value } = e.target;
        if (name == "email") {
            setCheck(await isStringOrNumber(value))
        }
        setFormData({ ...formData, [name]: value });
    };
    function isStringConvertibleToNumber(value) {
        const convertedNumber = Number(value);
        return !isNaN(convertedNumber) && typeof convertedNumber === 'number';
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let body = {}
            if (isStringConvertibleToNumber(formData.email)) {
                body.phone = Number(formData.email);
                body.otp = Number(formData.otp);
            } else {
                body.email = formData.email
                body.password = formData.password
            }

            const response = await axiosInstance.post('/api/user/auth/login', body);
            if (response.status === 200) {
                localStorage.setItem("wh_tk", JSON.stringify(response.data));
                toast.success("✅Logged in successfully");
                navigate('/taskscreen');
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }

    };

    const ApiCall = () => {
        let response = axiosInstance.get(`/api/user/new/update?token=${queryParams.get('token')}`)
            .then((res) => {
                console.log("djfhjksdg", res)
                toast.success("you can do login now");
                navigate('/login')
            }
            ).catch(err => console.log(err))
            ;
        // console.log(response.status);
        // if (response.status == 200) {
        //     toast.success("you can do login now");
        //     navigate('/login');
        // } else {
        //     toast.success("✅Logged in successfully");
        // }
    }

    useEffect(() => {
        if (queryParams.get('token')) {
            ApiCall()
        } else {
            const UserData = JSON.parse(localStorage.getItem("wh_tk"));
            if (UserData) {
                navigate('/taskscreen');
            }
        }
    }, [])

    return (
        <div className='newBody'>
            <div class="login-container">
                <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "50px" }}>cityfurnish</h1>
                    <p style={{ color: "#898989" }}>Welcome Back</p>
                </div>

                <form onSubmit={handleSubmit}>

                    <div style={{ marginTop: "40px" }}>
                        <label htmlFor="email" style={{ fontSize: "13px", color: "#898989" }} for="">Email Or Phone No.</label>
                        <input
                            className='input'
                            id="email"
                            name="email"
                            autocomplete="off"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", border: "none", borderRadius: "10px" }} type="text"
                            placeholder="Enter Your Email Address or Phone No." />
                    </div>

                    {check == 2 && <div style={{ marginTop: "15px" }}>
                        <label htmlFor="password" style={{ fontSize: "13px", color: "#898989" }} for="">Password</label>
                        <input
                            className='input'
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", border: "none", borderRadius: "10px" }} type="password"
                            placeholder="Enter Your Password" />

                    </div>}

                    {check == 1 && <div style={{ marginTop: "15px" }}>
                        <label htmlFor="otp" style={{ fontSize: "13px", color: "#898989" }} for="">OTP</label>
                        <input

                            className='input'
                            id="password"
                            name="otp"
                            value={formData.otp}
                            onChange={handleChange}
                            required
                            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", border: "none", borderRadius: "10px" }} type="number"
                            placeholder="Enter Your Otp" />

                    </div>}
                    <button className='newbutton' type="submit" style=
                        {{ marginTop: "10px", backgroundColor: "#007BFF", color: "white", borderRadius: "10px" }}>Log in</button>
                    <p onClick={() => { navigate('/forgot-page') }} style={{ textAlign: "end", fontSize: "13px", marginTop: "15px" }} >Forgot your password?</p>
                </form>

            </div>
        </div>
    )


}

export default MobileView