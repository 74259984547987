import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Select from "../city/cityTable/Agentselect";
import axiosInstance from "../../axios/AxiosInstance";
import Input from "../../components/input/Input";
import { Stack } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DatePickerMui from "../../components/datepickerMUI/FilterDatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import Loading from "../../components/loading/Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: 330,
  },
};

const BasicModal = ({
  flag,
  setFlag,
  city,
  setTableData,
  filters,
  setFilters,
  setTotalPage,
  page,
  limit,
  setPage,
  loading,
  setLoading,
  setStart,
  setEnd,
  setLastPage,
  setFinalPage,
  setMessages,
  setIsDateFilterApplied,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [schedulestart, setScheduleStart] = useState("");
  const [scheduleend, setScheduleEnd] = useState("");
  const [assignedstart, setAssignedStart] = useState("");
  const [assignedend, setAssignedEnd] = useState("");
  const [deliverystart, setDeliveryStart] = useState("");
  const [deliveryend, setDeliveryEnd] = useState("");

  const [modalFlag, setModalFlag] = useState(false);

  //   console.log(assignedstart, assignedend);
  //   console.log(schedulestart, scheduleend);
  //   console.log(deliverystart, deliveryend);

  const handleAssignSChange = (newValue) => {
    setAssignedStart(newValue);
    setScheduleStart("");
    setScheduleEnd("");
    setDeliveryStart("");
    setDeliveryEnd("");
    setModalFlag(!modalFlag);
  };

  const handleAssignEChange = (newValue) => {
    setAssignedEnd(newValue);
    setScheduleStart("");
    setScheduleEnd("");
    setDeliveryStart("");
    setDeliveryEnd("");
    setModalFlag(!modalFlag);
  };

  const handleScheduleSChange = (newValue) => {
    setScheduleStart(newValue);
    setAssignedEnd("");
    setAssignedStart("");
    setDeliveryEnd("");
    setDeliveryStart("");
    setModalFlag(!modalFlag);
  };

  const handleScheduleEChange = (newValue) => {
    setScheduleEnd(newValue);
    setAssignedEnd("");
    setAssignedStart("");
    setDeliveryEnd("");
    setDeliveryStart("");
    setModalFlag(!modalFlag);
  };

  const handleDeliverSChange = (newValue) => {
    setDeliveryStart(newValue);
    setAssignedEnd("");
    setAssignedStart("");
    setScheduleStart("");
    setScheduleEnd("");
    setModalFlag(!modalFlag);
  };

  const handleDeliverEChange = (newValue) => {
    setDeliveryEnd(newValue);
    setAssignedEnd("");
    setAssignedStart("");
    setScheduleStart("");
    setScheduleEnd("");
    setModalFlag(!modalFlag);
  };

  const UserData = JSON.parse(localStorage.getItem("wh_tk")).data;
  const userId = UserData._id;

  const Week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const Month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getData = async () => {
    // console.log(deliverystart, deliveryend);
    setIsDateFilterApplied(true);
    // console.log(schedulestart, scheduleend);

    if (
      (schedulestart !== "" && scheduleend === "") ||
      (assignedstart !== "" && assignedend === "") ||
      (deliverystart !== "" && deliveryend === "")
    ) {
      alert("Select end date !");
      return;
    }

    if (
      (schedulestart === "" && scheduleend !== "") ||
      (assignedstart === "" && assignedend !== "") ||
      (deliverystart === "" && deliveryend !== "")
    ) {
      alert("Select start date !");
      return;
    }

    try {
      let url = "";

      if (assignedstart !== "") {
        const start = new Date(assignedstart).getTime();

        let finaldate = "";
        finaldate =
          finaldate +
          Week[assignedend.$W] +
          " " +
          Month[assignedend.$M] +
          " " +
          assignedend.$D +
          " " +
          assignedend.$y;
        finaldate =
          finaldate + " " + "23:59:59" + " " + "GMT+0530 (India Standard Time)";

        const end = new Date(finaldate).getTime();

        if (start > end) {
          alert("End date cannot be before start date");
          return;
        }

        url = url + `&type=assigned&start=${start}&end=${end}`;
      } else if (schedulestart !== "") {
        const start = new Date(schedulestart).getTime();

        let finaldate = "";
        finaldate =
          finaldate +
          Week[scheduleend.$W] +
          " " +
          Month[scheduleend.$M] +
          " " +
          scheduleend.$D +
          " " +
          scheduleend.$y;
        finaldate =
          finaldate + " " + "23:59:59" + " " + "GMT+0530 (India Standard Time)";

        const end = new Date(finaldate).getTime();
        if (start > end) {
          alert("End date cannot be before start date");
          return;
        }
        url = url + `&type=scheduled&start=${start}&end=${end}`;
        setStart(start);
        setEnd(end);
      } else {
        const start = new Date(deliverystart).getTime();

        let finaldate = "";
        finaldate =
          finaldate +
          Week[deliveryend.$W] +
          " " +
          Month[deliveryend.$M] +
          " " +
          deliveryend.$D +
          " " +
          deliveryend.$y;
        finaldate =
          finaldate + " " + "23:59:59" + " " + "GMT+0530 (India Standard Time)";

        const end = new Date(finaldate).getTime();
        if (start > end) {
          alert("End date cannot be before start date");
          return;
        }
        url = url + `&type=deliver&start=${start}&end=${end}`;
        setStart(start);
        setEnd(end);
      }

      setLoading(true);

      let baseUrl = "";
      if (UserData.type === "agent") {
        baseUrl = `api/ticket/auditTrall?page=${
          page + 1
        }&limit=${limit}&agentId=${userId}&city=${city}`;
      } else {
        baseUrl = `api/ticket/auditTrall?page=${
          page + 1
        }&limit=${limit}&city=${city}`;
      }

      url = baseUrl + url;

      setFilters(url);
      const tableRes = await axiosInstance.get(url);
      if (tableRes.status === 200) {
        setLoading(false);
        setMessages(tableRes.data.data.docs);
        setTotalPage(tableRes.data.data.totalPages);
      } else {
        setLoading(false);
        alert("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong!!");
    }
    setFlag(!flag);
    handleClose();
  };

  const reset = async () => {
    setScheduleStart("");
    setScheduleEnd("");
    setAssignedStart("");
    setAssignedEnd("");
    setDeliveryStart("");
    setDeliveryEnd("");
    setFilters("");
    setPage(0);
    setIsDateFilterApplied(false);
  
    try {
      setLoading(true);
      let baseUrl = "";
      if (UserData.type === "agent") {
        baseUrl = `api/ticket?page=${1}&limit=${limit}&agentId=${userId}&city=${city}`;
      } else {
        baseUrl = `api/ticket?page=${1}&limit=${limit}&city=${city}`;
      }

      const tableRes = await axiosInstance.get(baseUrl);
      if (tableRes.status === 200) {
        setLoading(false);
        setTableData(tableRes.data.data);
        setTotalPage(tableRes.data.count);
        setLastPage(true);
        if (tableRes.data.count === 0) setStart(0);
        else setStart(1);
        if (limit >= tableRes.data.count) setEnd(tableRes.data.count);
        else setEnd(limit);
        if (tableRes.data.count <= limit) setFinalPage(true);
        else setFinalPage(false);
      } else {
        setLoading(false);
        alert("Something went wrong!!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong!!");
    }
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          height: "40px",
          backgroundColor: "#000",
          color: "#fff",
          fontSize: "0.875rem !important",
          "&:hover": {
            backgroundColor: "#000",
            opacity: "0.8",
          },
          "@media (max-width: 785px)": {
            fontSize: "0.6rem !important",
            // width:"100px",
            height: "30px",
          },
        }}
        onClick={handleOpen}
      >
        <CalendarTodayIcon
          sx={{
            marginRight: "10px",
            "@media (max-width: 785px)": {
              fontSize: "1rem !important",
            },
          }}
        />
        Filter by Date
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon
            style={{
              position: "absolute",
              right: "-30px",
              top: "-22px",
              cursor: "pointer",
              color: "white",
              "@media (max-width: 400px)": {
                right: "10px",
                top: "10px",
                color: "black",
              },
            }}
            onClick={() => {
              setScheduleStart("");
              setScheduleEnd("");
              setAssignedStart("");
              setAssignedEnd("");
              setDeliveryStart("");
              setDeliveryEnd("");
              handleClose();
              setFilters("");
            }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filter
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack
              direction="column"
              spacing={3}
              sx={{
                marginBottom: "40px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  Scheduled Date
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    width: "70%",
                  }}
                >
                  <DatePickerMui
                    modalFlag={modalFlag}
                    style={{ maxWidth: "150px" }}
                    other1s={setAssignedStart}
                    other1e={setAssignedEnd}
                    other2s={setDeliveryStart}
                    other2e={setDeliveryEnd}
                    value={schedulestart}
                    handleChange={handleScheduleSChange}
                    label={"Start"}
                  />
                  <DatePickerMui
                    modalFlag={modalFlag}
                    style={{ maxWidth: "150px" }}
                    other1s={setAssignedStart}
                    other1e={setAssignedEnd}
                    other2s={setDeliveryStart}
                    other2e={setDeliveryEnd}
                    value={scheduleend}
                    handleChange={handleScheduleEChange}
                    label={"End"}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{
                  width: "100%",
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  Assigned Date
                </Typography> */}
                {/* <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    width: "70%",
                  }}
                >
                  <DatePickerMui
                    modalFlag={modalFlag}
                    style={{ maxWidth: "150px" }}
                    other1s={setScheduleStart}
                    other1e={setScheduleEnd}
                    other2s={setDeliveryStart}
                    other2e={setDeliveryEnd}
                    value={assignedstart}
                    handleChange={handleAssignSChange}
                    label={"Start"}
                  />
                  <DatePickerMui
                    modalFlag={modalFlag}
                    style={{ maxWidth: "150px" }}
                    other1s={setScheduleStart}
                    other1e={setScheduleEnd}
                    other2s={setDeliveryStart}
                    other2e={setDeliveryEnd}
                    value={assignedend}
                    handleChange={handleAssignEChange}
                    label={"End"}
                  />
                </Stack> */}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{
                  width: "100%",
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  Completed On
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    width: "70%",
                  }}
                >
                  <DatePickerMui
                    modalFlag={modalFlag}
                    style={{ maxWidth: "150px" }}
                    other1s={setAssignedStart}
                    other1e={setAssignedEnd}
                    other2s={setScheduleStart}
                    other2e={setScheduleEnd}
                    value={deliverystart}
                    handleChange={handleDeliverSChange}
                    label={"Start"}
                  />
                  <DatePickerMui
                    modalFlag={modalFlag}
                    style={{ maxWidth: "150px" }}
                    other1s={setAssignedStart}
                    other1e={setAssignedEnd}
                    other2s={setScheduleStart}
                    other2e={setScheduleEnd}
                    value={deliveryend}
                    handleChange={handleDeliverEChange}
                    label={"End"}
                  />
                </Stack> */}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "40px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "0.8rem !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                }}
                onClick={getData}
              >
                {loading ? <Loading /> : "Ok"}
              </Button>
              <Button
                variant="contained"
                sx={{
                  height: "40px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "0.8rem!important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                }}
                onClick={reset}
              >
                {loading ? <Loading /> : "Reset Filters"}
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
