import React, { useEffect, useRef, useState } from "react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import PartyModeRoundedIcon from "@mui/icons-material/PartyModeRounded";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import CaptureImage from "./CaptureImage";
import "./DetailScreen.css";
import { toast } from "react-toastify";
import axiosInstance from "../../../axios/AxiosInstance";
import QrCodeScanner from "./qrCodeScanner";
import Schedule from "./ScheduleModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "react-select";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from "@mui/material/Button";

const DetailScreen = () => {
  const [Data, setData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location?.search);
  const param1 = searchParams?.get("i");
  const userData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
  const [inputValue, setInputValue] = useState(null);
  const [typedValue, setTypedValue] = useState("");
  const [files, setFiles] = useState([]);
  const [OderData, setOderData] = useState([]);
  const [master, setMaster] = useState({});
  const [pickUpmaster, setPickUpMaster] = useState({});
  const [remarkMandatory, setRemarkMandatory] = useState("");
  const [qrCode, setqrCode] = useState(false);
  let [reload, setReload] = useState(0);
  let [HideShow, setHideShow] = useState([]);
  let [remark, setRemark] = useState("");
  let [remarkId, setRemarkId] = useState("");
  let [InArray, setInArray] = useState([]);
  let [OutArray, setOutArray] = useState([]);
  const [lod, setlod] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [newopen, setnewOpen] = useState(false);
  const [newopen1, setnewOpen1] = useState(false);
  const [dataTripStatus, setDataTripStatus] = useState(false);


  const [pickupId, setpickupId] = useState("");
  const [_id, set_id] = useState("");

  const [setindex, setsetindex] = useState(0);
  const [deleteId, setdeleteId] = useState("");
  const handleOpen = () => setnewOpen(true);

  const [masterDate, setmasterDate] = useState("");

  const newhandleOpen = () => setnewOpen1(true);
  const newhandleClose1 = () => {
    setnewOpen1(false);
  };
  const newhandleClose = () => setnewOpen(false);
  const [currentSmsId, setcurrentSmsId] = useState({});
  let disabled = [
    "Completed",
    "Failed",
    "Delivered",
    "Pickup Done",
    "Task Failed",
    "Pickup Failed",
    "Delivery Failed",
    "Pickup Pending",
    "Pending",
    "Order Verified",
  ];
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const RemarOptions = [
    { value: "Delivery Done", label: "Delivery Done" },
    { value: "Partial Delivery Done", label: "Partial Delivery Done" },
    { value: "Pickup Done", label: "Pickup Done" },
    { value: "Partial Pickup Done", label: "Partial Pickup Done" },
    {
      value: "Visit Time Cx not available at Location",
      label: "Visit Time Cx not available at Location",
    },
    {
      value: "Cx want to extend the subscription ",
      label: "Cx want to extend the subscription ",
    },
    { value: "Cx want to Resheduled", label: "Cx want to Resheduled" },
    {
      value: "After 6 pm Not allowed in Society",
      label: "After 6 pm Not allowed in Society",
    },
    {
      value: "Service Lift not working in society ",
      label: "Service Lift not working in society ",
    },
    {
      value: "No Shifting allowed in Society on Sunday",
      label: "No Shifting allowed in Society on Sunday",
    },
  ];

  const remarkRef = useRef(null);
  const handleRemark = (selectedOption) => {
    setSelectedOption(selectedOption);
    setInputValue(selectedOption?.value);
  };

  const handleRemarkInputChange = (newValue, actionMeta, e) => {
    if (actionMeta.action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleInputChangeRemark = (e) => {
    setRemark(e.target.value);
  };
  const handleFileUpload = async (ev) => {
    try {
      const files = ev.target.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      formData.append("userId", userData._id);
      // alert("Your file has been uploaded !!");
      // closeDetail();
      const res = await axiosInstance.post(
        `api/ticket/files/${Data._id}`,
        formData
      );
      if (res.status === 200) {
        toast.success("Your file has been uploaded !!");
        // closeDetail();
      } else {
        toast.error("Something went wrong!!");
      }
    } catch (err) {
      //console.log(err);

      toast.error("Something went wrong!!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...Data, comment: inputValue });
    e.preventDefault();
    if (inputValue) {
      let body = {
        comment: inputValue,
        userId: JSON.parse(localStorage.getItem("wh_tk")).data._id,
      };
      const res = await axiosInstance.post(`api/ticket/${Data._id}`, body);
      if (res.status === 200) {
        toast.success("Update successfull");
      } else {
        return alert("Something went wrong !!");
      }
    }
  };

  const handleRemake = async (e) => {
    e.preventDefault();
    let body = {
      remark: remark,
      id: remarkId,
    };
    if (remarkMandatory) {
      if (remark) {
        const res = await axiosInstance.post(`api/order/remark`, body);
        if (res.status === 200) {
          setReload(++reload);
          toast.success("Update successfull");
        } else {
          return alert("Something went wrong !!");
        }
      } else {
        toast.warning("Please Give Remark");
      }
    } else {
      const res = await axiosInstance.post(`api/order/remark`, body);
      if (res.status === 200) {
        setReload(++reload);
        toast.success("Update successfull");
      } else {
        return alert("Something went wrong !!");
      }
    }
  };
  const click = async (id, barcode, status) => {
    await axiosInstance
      .post(`/api/order`, {
        barcode: barcode,
        id: id,
        status: status,
      })
      .then(() => {
        setReload(++reload);
        toast.success("Done");
      })
      .catch(() => {
        toast.error("Something went wrong!!");
      });
  };
  const click1 = async (id, status) => {
    await axiosInstance
      .post(`/api/order/pickUp`, {
        id: id,
        status: status,
        cityName: Data.city
      })
      .then(() => {
        setReload(++reload);
        toast.success("Done");
      })
      .catch((error) => {
        if (
          error.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!!");
        }
      });
  };
  const click3 = async (id, status, index) => {
    setHideShow([...HideShow, index]);
    await axiosInstance
      .post(`/api/order/pickUp`, {
        id: id,
        status: status,
        cityName: Data.city
      })
      .then(() => {
        setReload(++reload);
        toast.success("Done");
      })
      .catch((error) => {
        if (
          error.message
        ) {
          toast.error(error.response.data.message);

        } else {
          toast.error("Something went wrong!!");

        }
      });
  };

  const updateButoon = async () => {
    if (!dataTripStatus) {
      toast.error("Please Start The Trip First.");
    } else {
      let check = false;
      if (Data.subCategory == "Repair" || Data.subCategory == "Relocation") {
        check = true;
      } else {
        OderData ? (check = true) : (check = false);
      }
      if (!check) {
        toast.error("SO is not linked, Please check with Admin");
      } else if (
        OderData?.filter((item) => item.status != 1).length == OderData?.length
      ) {
        setOpen(true);
      } else {
        toast.error("Please mark all product status.");
      }
    }

  };

  const deleteProduct = async () => {
    if (remarkMandatory) {
      setHideShow([...HideShow, setindex])
    } else {
      click3(deleteId, "3", setindex)
    }
  }
  useEffect(() => {
    getDetail();
  }, [reload]);

  const handleClose = () => {
    setqrCode(false);
  };

  const getDetail = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/ticket?ticketNumber$contains=${param1}`
      );
      const responseForFile = await axiosInstance.post(`/api/ticket/details`, {
        customerId: response.data.data[0].customerId,
        ticketId: response.data.data[0]._id,
      });
      setDataTripStatus(response.data.data[0].tripStatus)
      let body = {
        id: response.data?.data[0]._id,
      };
      let getOderData;
      const res = await axiosInstance.get("api/master");
      if (response.status === 200) {
        if (response.data?.data[0].subCategory == "Pickup and Refund") {
          let so_numbers = "";
          if (
            response.data?.data[0].cf_so_number_1 &&
            response.data?.data[0].cf_so_number_1 != "-" &&
            response.data?.data[0].cf_so_number_1 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_1;
          }
          if (
            response.data?.data[0].cf_so_number_2 &&
            response.data?.data[0].cf_so_number_2 != "-" &&
            response.data?.data[0].cf_so_number_2 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_2;
          }
          if (
            response.data?.data[0].cf_so_number_3 &&
            response.data?.data[0].cf_so_number_3 != "-" &&
            response.data?.data[0].cf_so_number_3 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_3;
          }
          if (
            response.data?.data[0].cf_so_number_4 &&
            response.data?.data[0].cf_so_number_4 !== "-" &&
            response.data?.data[0].cf_so_number_4 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_4;
          }
          if (
            response.data?.data[0].cf_so_number_5 &&
            response.data?.data[0].cf_so_number_5 != "-" &&
            response.data?.data[0].cf_so_number_5 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_5;
          }
          body.so_numbers = so_numbers;
          getOderData = await axiosInstance.post(
            `/api/order/get_pickup_data`,
            body
          );
          setlod(true);
        } else if (response.data?.data[0].subCategory == "Upgrade") {
          let so_numbers = "";
          let Upgrade_so = "";

          if (
            response.data?.data[0].cf_delivery_so_1 &&
            response.data?.data[0].cf_delivery_so_1 != "-" &&
            response.data?.data[0].cf_delivery_so_1 != "."
          ) {
            Upgrade_so = response.data?.data[0].cf_delivery_so_1;
          }
          if (
            response.data?.data[0].cf_delivery_so_2 &&
            response.data?.data[0].cf_delivery_so_2 != "-" &&
            response.data?.data[0].cf_delivery_so_2 != "."
          ) {
            Upgrade_so = response.data?.data[0].cf_delivery_so_2;
          }
          if (
            response.data?.data[0].cf_delivery_so_3 &&
            response.data?.data[0].cf_delivery_so_3 != "-" &&
            response.data?.data[0].cf_delivery_so_3 != "."
          ) {
            Upgrade_so = response.data?.data[0].cf_delivery_so_3;
          }

          if (
            response.data?.data[0]?.cf_so_number_1 &&
            response.data?.data[0]?.cf_so_number_1 != "-" &&
            response.data?.data[0]?.cf_so_number_1 != "."
          ) {
            so_numbers = response.data?.data[0]?.cf_so_number_1;
          }

          if (
            response.data?.data[0].cf_so_number_2 &&
            response.data?.data[0].cf_so_number_2 != "-" &&
            response.data?.data[0].cf_so_number_2 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_2;
          }
          if (
            response.data?.data[0].cf_so_number_3 &&
            response.data?.data[0].cf_so_number_3 != "-" &&
            response.data?.data[0].cf_so_number_3 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_3;
          }
          if (
            response.data?.data[0].cf_so_number_4 &&
            response.data?.data[0].cf_so_number_4 !== "-" &&
            response.data?.data[0].cf_so_number_4 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_4;
          }
          if (
            response.data?.data[0].cf_so_number_5 &&
            response.data?.data[0].cf_so_number_5 != "-" &&
            response.data?.data[0].cf_so_number_5 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_5;
          }
          body.order_id = Upgrade_so;
          let dataRes;
          if (Upgrade_so) {
            dataRes = await axiosInstance.post(`/api/order/order_id`, body);
          }

          delete body.order_id;
          body.so_numbers = so_numbers;
          let dataRes1;
          if (so_numbers) {
            dataRes1 = await axiosInstance.post(
              `/api/order/get_pickup_data`,
              body
            );
            setInArray(dataRes1.data.result);
            setOutArray(dataRes.data.result);
          }


          setlod(true);
        } else if (
          response.data?.data[0].subCategory == "Replace" ||
          response.data?.data[0].subCategory == "Repair"
        ) {
          let so_numbers = "";
          if (
            response.data?.data[0].cf_so_number_1 &&
            response.data?.data[0].cf_so_number_1 != "-" &&
            response.data?.data[0].cf_so_number_1 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_1;
          }
          if (
            response.data?.data[0].cf_so_number_2 &&
            response.data?.data[0].cf_so_number_2 != "-" &&
            response.data?.data[0].cf_so_number_2 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_2;
          }
          if (
            response.data?.data[0].cf_so_number_3 &&
            response.data?.data[0].cf_so_number_3 != "-" &&
            response.data?.data[0].cf_so_number_3 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_3;
          }
          if (
            response.data?.data[0].cf_so_number_4 &&
            response.data?.data[0].cf_so_number_4 !== "-" &&
            response.data?.data[0].cf_so_number_4 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_4;
          }
          if (
            response.data?.data[0].cf_so_number_5 &&
            response.data?.data[0].cf_so_number_5 != "-" &&
            response.data?.data[0].cf_so_number_5 != "."
          ) {
            so_numbers = response.data?.data[0].cf_so_number_5;
          }
          body.so_numbers = so_numbers;
          let { data } = await axiosInstance.post(
            `/api/order/so_numbers`,
            body
          );

          setOutArray(
            data.result?.filter(
              (item) => item?.client_Status === "Delivery Pending"
            )
          );
          setInArray(
            data.result?.filter(
              (item) => item?.client_Status === "Replacement In"
            )
          );

          setlod(true);
        } else {
          let so_numbers = "";
          if (
            response.data?.data[0].cf_delivery_so_1 &&
            response.data?.data[0].cf_delivery_so_1 != "-" &&
            response.data?.data[0].cf_so_number_1 != "."
          ) {
            so_numbers = response.data?.data[0].cf_delivery_so_1;
          }
          if (
            response.data?.data[0].cf_delivery_so_2 &&
            response.data?.data[0].cf_delivery_so_2 != "-" &&
            response.data?.data[0].cf_so_number_2 != "."
          ) {
            so_numbers = response.data?.data[0].cf_delivery_so_2;
          }
          if (
            response.data?.data[0].cf_delivery_so_3 &&
            response.data?.data[0].cf_delivery_so_3 != "-" &&
            response.data?.data[0].cf_so_number_3 != "."
          ) {
            so_numbers = response.data?.data[0].cf_delivery_so_3;
          }
          body.order_id = so_numbers;

          if (body.order_id) {
            getOderData = await axiosInstance.post(`/api/order/order_id`, body);
            console.log(getOderData);
            setlod(true);
          } else {
            setlod(true);
          }
        }
        setData(response.data?.data[0]);
        setFiles(responseForFile?.data?.files);

        if (getOderData?.data?.result) {
          setOderData(getOderData?.data?.result);
        } else {
          if (OutArray.length > 0 || InArray.length > 0) {
            setOderData(OutArray.concat(InArray));
          }
        }

        setMaster(
          res?.data?.data.filter(
            (item) => item._id == "655dcbf8b51e817ee90bd239"
          )[0]
        );

        setmasterDate(res?.data?.data.filter(
          (item) => item._id == "664303146d138e634e90c6d4"
        )[0].rows.filter(row => row.city.includes(response.data?.data[0].city))[0].date);

        setPickUpMaster(
          res?.data?.data.filter(
            (item) => item._id == "664300166d138e634e90c6d3"
          )[0]
        );
        setRemarkMandatory(
          res?.data?.data
            .filter((item) => item._id == "657ab7e29be969f473ba47ad")[0]
            .rows.filter((item) => item.city[0] == Data.city)[0]?.status
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  function convertToIST(utcDateTime) {
    let istDate = moment(utcDateTime).tz("Asia/Kolkata");
    return istDate.format("DD MMM YYYY");
  }
  useEffect(() => { }, [files]);

  const updatemessage = async (message) => {
    const response = await axiosInstance.post(
      `/api/order/message`, {
      message, id: currentSmsId._id
    }
    );
    newhandleClose()
    setReload(++reload)
  }

  function checkDates(esd, orderDate) {

    let format = moment(masterDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    const date1Obj = new Date(format);
    const date2Obj = esd ? new Date(esd) : null;
    const date3Obj = orderDate ? new Date(orderDate) : null;

    // Check if date1 is valid
    if (isNaN(date1Obj.getTime())) {
      throw new Error("The first date is not a valid date.");
    }

    // Check if date1 is later than the valid date(s)
    const isLaterThanDate2 = !date2Obj || date1Obj.getTime() > date2Obj.getTime();
    const isLaterThanDate3 = !date3Obj || date1Obj.getTime() > date3Obj.getTime();
    if (isLaterThanDate2 && isLaterThanDate3) {

      return false;
    } else { return true }
  }

  return (
    <div style={{ padding: "10px", fontFamily: "Roboto" }}>

      <Modal
        open={newopen}
        onClose={newhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{
            display: "Block",
            padding: "5px"
          }}>
            <div className="message">
              <input
                type="radio"
                id="option1"
                name="barcodeIssue"
                value="No physical barcode available"
                checked={"No physical barcode available" === currentSmsId.message}
                onChange={() => updatemessage("No physical barcode available")}
              />
              <label htmlFor="option1"> No physical barcode available</label>
            </div>
            <div className="message">
              <input
                type="radio"
                id="option2"
                name="barcodeIssue"
                value="Barcode has Scratch"
                checked={"Barcode has Scratch" === currentSmsId.message}
                onChange={() => updatemessage("Barcode has Scratch")}
              />
              <label htmlFor="option2"> Barcode has Scratch</label>
            </div>
            <div className="message">
              <input
                type="radio"
                id="option3"
                name="barcodeIssue"
                value="Barcode not matching"
                checked={"Barcode not matching" === currentSmsId.message}
                onChange={() => updatemessage("Barcode not matching")}
              />
              <label htmlFor="option3"> Barcode not matching</label>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={newopen1}
        onClose={newhandleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5>
            Delete product
          </h5>
          < div style={{ marginTop: "10px" }}>
            <Button
              onClick={deleteProduct}
              variant="contained"
              sx={{

                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
            >
              yes
            </Button>
            <Button
              onClick={newhandleClose1}
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
            >
              no
            </Button>

          </div>
        </Box>
      </Modal>

      <div className="stickyTeat">
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIcon
            onClick={() => {
              navigate("/taskscreen", { replace: true });
            }}
          />
          <p className="hading">Tasks Details </p>
          <p className="titleText">{Data?.jobType}</p>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          <div>
            <p className="spac Font">Name</p>
            <p className="spac Font">Email</p>
            <p className="spac Font">Status</p>
            <p className="spac Font">Address</p>
          </div>

          <div style={{ marginLeft: "15px" }}>
            <p className="spac">{Data?.name}</p>
            <p className="spac">{Data?.email}</p>
            <p className="spac">{Data?.status}</p>
            <p className="spac">{`${Data?.address?.cf_address_1}, 
                    ${Data?.address?.cf_address_2},${Data?.address?.cf_area},${Data?.address?.cf_city},${Data?.address?.cf_pincode}`}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "18px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <p style={{ fontWeight: 900 }}>Ticket no.</p>
            <p style={{ marginLeft: "15px" }}>{Data?.ticketNumber}</p>
          </div>

          <div style={{ display: "flex" }}>
            <p style={{ fontWeight: 900 }}>Schedule Date</p>
            <p style={{ marginLeft: "15px" }}>
              {Data.scheduledDate ? convertToIST(Data.scheduledDate) : "Nan"}
            </p>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#ccc", padding: "1px", marginTop: "8px" }}
        ></div>
      </div>

      {lod &&
        (Data.subCategory == "Upgrade" ||
          Data.subCategory == "Replace" ||
          Data.subCategory == "Repair" ?
          (
            <>
              <p style={{ marginTop: "10px", fontSize: "21px", fontWeight: 600 }}>
                Product In
              </p>

              {InArray?.map((item, index) => {
                return (
                  <div key={item._id}>
                    <div></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      {Data.subCategory != "Pickup and Refund" &&
                        master?.rows?.length > 0 &&
                        master.rows.filter((item) => item.city[0] == Data.city)[0]
                          .status && (
                          <QrCodeScannerIcon
                            sx={{ fontSize: "40px", color: "gray" }}
                            onClick={() => dataTripStatus ? (setqrCode(true)) : toast.error("Please Start The Trip First")}
                          />
                        )}
                      <div>
                        <p style={{ fontSize: "14px" }}>{item.Product_name}</p>
                        {Data.subCategory != "Pickup and Refund" && (
                          <p style={{ fontSize: "12px" }}>{item.barcode}</p>
                        )}
                        {item.remark && (
                          <p style={{ marginTop: "5px" }}>{item.remark}</p>
                        )}
                        {HideShow.includes(index) && (
                          <form onSubmit={handleRemake}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "10px"
                              }}
                            >
                              <input
                                onChange={handleInputChangeRemark}
                                className="inputForRema"
                              />
                              <button
                                onClick={() => {
                                  setRemarkId(item._id);
                                }}
                                type="submit"
                                className="inputButot"
                              >
                                submit
                              </button>
                            </div>
                          </form>
                        )}
                      </div>

                      <div>
                        <TaskAltIcon
                          sx={{
                            fontSize: "40px",
                            color: item.status == 2 ? "green" : "gray",
                          }}
                          onClick={() => {
                            Data.subCategory == "Pickup and Refund" ||
                              Data.subCategory == "Upgrade" ||
                              Data.subCategory == "Replace" ||
                              Data.subCategory == "Repair"
                              ? click1(item._id, "2")
                              : master?.rows?.length > 0 &&
                                master.rows.filter(
                                  (item) => item.city[0] == Data.city
                                )[0].status
                                ? toast.info("Please Scan The Product")
                                : click(item._id, item.barcode, "2");
                          }}
                        />
                        <UnpublishedIcon
                          onClick={() => {
                            remarkMandatory
                              ? setHideShow([...HideShow, index])
                              : click3(item._id, "3", index);
                          }}
                          sx={{
                            fontSize: "40px",
                            color: item.status == 3 ? "red" : "gray",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    </div>

                    <div></div>
                  </div>
                );
              })}
              <p style={{ marginTop: "10px", fontSize: "21px", fontWeight: 600 }}>
                Product Out
              </p>
              {OutArray?.map((item, index) => {
                return (
                  <div key={item._id}>
                    <div></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      {Data.subCategory != "Pickup and Refund" &&
                        master?.rows?.length > 0 &&
                        master.rows.filter((item) => item.city[0] == Data.city)[0]
                          .status && (
                          <QrCodeScannerIcon
                            sx={{ fontSize: "40px", color: "gray" }}
                            onClick={() => dataTripStatus ? (set_id(item._id), setqrCode(true)) : toast.error("Please Start The Trip First")}
                          />
                        )}
                      <div>
                        <p style={{ fontSize: "14px" }}>{item.Product_name}</p>
                        {Data.subCategory != "Pickup and Refund" && (
                          <p style={{ fontSize: "12px" }}>{item.barcode}</p>
                        )}
                        {item.remark && (
                          <p style={{ marginTop: "5px" }}>{item.remark}</p>
                        )}
                        {HideShow.includes(index) && (
                          <form onSubmit={handleRemake}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "10px"
                              }}
                            >
                              <input
                                onChange={handleInputChangeRemark}
                                className="inputForRema"
                              />
                              <button
                                onClick={() => {
                                  setRemarkId(item._id);
                                }}
                                type="submit"
                                className="inputButot"
                              >
                                submit
                              </button>
                            </div>
                          </form>
                        )}
                      </div>

                      <div>
                        <TaskAltIcon
                          sx={{
                            fontSize: "40px",
                            color: item.status == 2 ? "green" : "gray",
                          }}
                          onClick={() => {
                            Data.subCategory == "Pickup and Refund"
                              ? click1(item._id, "2")
                              : master?.rows?.length > 0 &&
                                master.rows.filter(
                                  (item) => item.city[0] == Data.city
                                )[0].status
                                ? toast.info("Please Scan The Product")
                                : click(item._id, item.barcode, "2");
                          }}
                        />
                        <UnpublishedIcon
                          onClick={() => {
                            remarkMandatory
                              ? setHideShow([...HideShow, index])
                              : click3(item._id, "3", index);
                          }}
                          sx={{
                            fontSize: "40px",
                            color: item.status == 3 ? "red" : "gray",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    </div>

                    <div></div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <p style={{ marginTop: "10px", fontSize: "21px", fontWeight: 600 }}>
                Product
              </p>
              <div>
                {OderData?.map((item, index) => {
                  return (
                    <div key={item._id}>
                      <div></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "20px",
                        }}
                      >
                        {Data.subCategory != "Pickup and Refund" &&
                          master?.rows?.length > 0 &&
                          master.rows.filter(
                            (item) => item.city[0] == Data.city
                          )[0].status && (
                            <QrCodeScannerIcon
                              sx={{ fontSize: "40px", color: "gray" }}
                              onClick={() =>  dataTripStatus ? (set_id(item._id), setqrCode(true)) : toast.error("Please Start The Trip First")}
                            />
                          )}

                        {Data.subCategory == "Pickup and Refund" &&
                          pickUpmaster?.rows?.length > 0 &&
                          pickUpmaster.rows.filter(
                            (item) => item.city[0] == Data.city
                          )[0].status && checkDates(item.esd, item.OrderEsd) && (
                            <QrCodeScannerIcon
                              sx={{ fontSize: "40px", color: "gray" }}
                              onClick={() => dataTripStatus ? (setpickupId(item._id), setqrCode(true)) : toast.error("Please Start The Trip First")}
                            />
                          )}


                        <div>
                          <p style={{ fontSize: "14px" }}>{item.Product_name}</p>
                          <p style={{ fontSize: "12px" }}>{item.barcode}</p>
                          {item.remark && (
                            <p style={{ marginTop: "5px" }}>{item.remark}</p>
                          )}
                          {HideShow.includes(index) && (
                            <form onSubmit={handleRemake}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  width: "10px"
                                }}
                              >
                                <input
                                  onChange={handleInputChangeRemark}
                                  className="inputForRema"
                                />
                                <button
                                  onClick={() => {
                                    setRemarkId(item._id);
                                  }}
                                  type="submit"
                                  className="inputButot"
                                >
                                  submit
                                </button>
                              </div>
                            </form>
                          )}
                        </div>

                        <div style={{ gap: "5px", display: "flex" }}>
                          {checkDates(item.esd, item.OrderEsd) && <ReportProblemIcon sx={{
                            fontSize: "40px",
                            color: "#FFBF00",
                          }} onClick={() => { setcurrentSmsId(item); handleOpen() }} />}
                          <TaskAltIcon
                            sx={{
                              fontSize: "40px",
                              color: item.status == 2 ? "green" : "gray",

                            }}
                            onClick={() => {

                              Data.subCategory == "Upgrade" ||
                                Data.subCategory == "Replace"
                                ? click1(item._id, "2")

                                : Data.subCategory == "Pickup and Refund" ?
                                  (pickUpmaster?.rows?.length > 0 &&
                                    pickUpmaster.rows.filter(
                                      (item) => item.city[0] == Data.city
                                    )[0].status)
                                    ? item.message ? click1(item._id, "2") : checkDates(item.esd, item.OrderEsd) ? toast.info("Please Scan The Product") : click1(item._id, "2")
                                    : click1(item._id, "2") :

                                  master?.rows?.length > 0 &&
                                    master.rows.filter(
                                      (item) => item.city[0] == Data.city
                                    )[0].status
                                    ? toast.info("Please Scan The Product")
                                    : click(item._id, item.barcode, "2")
                            }}
                          />
                          <UnpublishedIcon
                            onClick={() => {
                              remarkMandatory
                                ? setHideShow([...HideShow, index])
                                : click3(item._id, "3", index);
                            }}
                            sx={{
                              fontSize: "40px",
                              color: item.status == 3 ? "red" : "gray",

                            }}
                          />


                        </div>
                      </div>

                      <div></div>
                    </div>
                  );
                })}
              </div>
            </>
          ))}

      <div className="someclass">
        <p>Total Product</p>
        <p style={{ marginRight: "15px" }}>
          {OderData?.length > 0
            ? OderData?.length
            : (InArray?.length ?? 0) + (OutArray?.length ?? 0)}
        </p>
      </div>

      <div
        style={{ backgroundColor: "#ccc", padding: "1px", marginTop: "20px" }}
      ></div>

      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "12px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {/* 
                    <div style={{
                        width: "-webkit-fill-available",
                        borderRadius: "12px"
                    }} >
                        <input value={inputValue}
                            onChange={handleInputChange} placeholder={Data.comment ? Data.comment : "Enter remark..."} className='input' style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", textAlign: "center", borderRadius: "10px", fontSize: "16px" }} type="text" />
                    </div> */}

          <Select
            value={selectedOption}
            defaultInputValue={selectedOption}
            onChange={handleRemark}
            onInputChange={handleRemarkInputChange}
            options={RemarOptions}
            className="remark_selector"
            placeholder={Data.comment ? Data.comment : "Enter remark..."}
            isSearchable
          />

          <div>
            <button type="submit" className="submitbutton">
              Submit
            </button>
          </div>
        </div>
      </form>

      <div
        style={{ backgroundColor: "#ccc", padding: "1px", marginTop: "10px" }}
      ></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <p style={{ fontWeight: "600" }}>Capture Product Photo </p>
        <div className="input-with-icon">
          <input
            type="file"
            id="file-input"
            name="file-input"
            multiple
            onChange={handleFileUpload}
          />
          <label
            className="photobutton"
            id="file-input-label"
            htmlFor="file-input"
          >
            <PartyModeRoundedIcon />
          </label>
        </div>

        <CaptureImage ticketId={Data._id} />
      </div>

      <div style={{ marginTop: "10px" }}>
        {files?.length > 0 &&
          files?.map((data) => {
            return (
              <p
                key={data._id}
                onClick={() => {
                  window.open(data.s3Data.Location, "_blank");
                }}
              >
                {data?.name}{" "}
              </p>
            );
          })}{" "}
      </div>

      <div
        onClick={() => updateButoon()}
        className="updateButoon"
        id={disabled.includes(Data.status) ? "disabledNew" : "2"}
      >
        Update
      </div>
      <Schedule
        setOpen={setOpen}
        open={open}
        data={Data}
        setReloard={setReload}
        reloard={reload}
      />
      {qrCode && (
        <QrCodeScanner
          cityName={Data.city}
          pickupId={pickupId}
          type={Data.jobType}
          id={Data._id}
          _id={_id}
          set_id={set_id}
          qrCode={qrCode}
          handleClose={handleClose}
          setReloard={setReload}
          reloard={reload}
        />
      )}
    </div>
  );
};

export default DetailScreen;
