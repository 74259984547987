import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from "../../../components/input/Input";
import { Stack } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import axiosInstance from '../../../axios/AxiosInstance';
import ClearIcon from '@mui/icons-material/Clear';
import Loading from '../../../components/loading/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const BasicModal = ({
    setManagers,
    setLoading,
    createData,
    loading
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [email , setEmail] = useState("");
  const [name , setName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState('');
  const [status , setStatus] = useState("");

  const getdata = async () => {

    let url = '' ;
    if(phone !== '')
    {
        url = url + `phone$contains=${phone}&`;
    }
    if(email !== '')
    {
        url = url + `email$contains=${email}&`;
    }
    if(name !== '')
    {
        url = url + `name$contains=${name}&`;
    }

    const base_url = `api/manager?`;

    url = base_url + url;

    // console.log(url);
    try {
        setLoading(true);
        const response = await axiosInstance.get(url);
        if (response.status === 200) {
            setLoading(false);
            let allAgents = response.data.data;
            allAgents = allAgents.map((agent) => {
                return createData(
                    agent
                )
            });
            setManagers(allAgents);
        }
    } catch (err) {
        setLoading(false);
        console.log(err);
    }
    handleClose();
  }

  const reset = async () => {
    setManagers([]);
    setEmail('');
    setName('');
    setPhone('');
    setCity('');

    try {
        setLoading(true);
        const response = await axiosInstance.get(`api/manager`);
        if (response.status === 200) {
            setLoading(false);
            let allAgents = response.data.data;
            allAgents = allAgents.map((agent) => {
                return createData(
                    agent
                )
            });
            setManagers(allAgents);
        }
    } catch (err) {
        setLoading(false);
        console.log(err);
    }
    handleClose();
  }

  return (
    <div>
        <Button variant="contained"
                sx={{
                    height:"40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize:"0.875rem !important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }} onClick={handleOpen}>
                <FilterAltIcon sx={{marginRight:"10px"}} />
                Filter
                </Button> 
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <ClearIcon style={{position:"absolute", right:"-30px", top:"-22px", cursor:"pointer"}} onClick={() => {
                setEmail('');
                setName('');
                setPhone('');
                setCity('');
                handleClose();
            }} />
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Filter
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack
            direction="column"
            >
                <Stack
                direction="column"
                spacing={3}
                sx={{
                    marginBottom:"50px",
                }}
                >
                    <Stack
                    direction="row" 
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >Name</Typography>
                        <Input  value ={name} setvalue={setName} style={{width:"70%"}} type="text" placeholder="Enter name" />    
                    </Stack>
                    <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >Email</Typography>
                        <Input value={email} setvalue={setEmail} style={{width:"70%"}} type="text" placeholder="Enter email" />    
                    </Stack>
                    {/* <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >Phone</Typography>
                        <Input value={phone} setvalue={setPhone} style={{width:"70%"}} type="number" placeholder="Enter phone number" />    
                    </Stack> */}
                    <Stack
                    direction="row" 
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >City</Typography>
                        <Input  value ={city} setvalue={setCity} style={{width:"70%"}} type="text" placeholder="Enter City" />    
                    </Stack>
                    {/* <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >Status</Typography>
                        <Input value={status} setvalue={setStatus} style={{width:"70%"}} type="text" placeholder="Enter status" />    
                    </Stack> */}
                </Stack>
                <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    width:"100%",
                }} 
                >
                    <Button variant="contained"
                    sx={{
                        height:"40px",
                        backgroundColor: '#000',
                        color: '#fff',
                        fontSize:"0.8rem !important",
                        '&:hover': {
                            backgroundColor: '#000',
                            opacity: '0.8'
                        }
                    }} onClick={getdata} >
                    {
                        loading ?
                        <Loading />
                        :
                        "Ok"
                    }
                    </Button>
                    <Button
                    variant="contained"
                    sx={{
                        height: '40px',
                        backgroundColor: '#000',
                        color: '#fff',
                        fontSize:"0.8rem!important",
                        '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                        }
                    }} onClick={reset} >
                        {
                        loading ?
                        <Loading />
                        :
                        "Reset Filter"
                    }
                    </Button>
                </Stack>
            </Stack>
            </Typography>
            </Box>
        </Modal>
        </div>
  );
}

export default BasicModal;

