import * as React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({ city, setCity, data }) {

  const handleChange = (event) => {
    const newarr = [...city];
    newarr.push(JSON.parse(event.target.value));
    setCity(newarr);
  };

  const onRemoveWarehouse = (item) => {
      let newData = city.filter((city) => { return city._id !== item._id; })
      setCity(newData); 
  }

  return (
    <>
    <Box sx={{ minWidth: 120, color:"black", marginBottom:"10px"  }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value="Select"
          label="Age"
          onChange={handleChange}
        >
          
          {
            data && data.length > 0 ?
            data.map((warehouse) => {
              return <MenuItem value={JSON.stringify(warehouse)}>{warehouse.name}</MenuItem>
            }) : <MenuItem value="loading">Loading...</MenuItem>
          }

        </Select>
      </FormControl>
    </Box>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
        {
          city && city.length > 0 &&
        city.map((value) => (
        <Chip onClick={() => onRemoveWarehouse(value)} style={{backgroundColor:"black", color:"white", borderRadius:"5px"}} key={value._id} label={value.name} />
        ))
        }
    </Box>
    </>
  );
}
