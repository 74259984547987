import React from 'react'
import styled from 'styled-components'

const Tag = styled.div`
    width:15%;
    height:40px;
    background-color:black;
    color:white;
    border-radius:5px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:5px;
    margin-bottom:10px;
    margin-right:20px;
`

const ActionLabel = () => {
  return (
    <Tag>Action</Tag>
  )
}

export default ActionLabel