import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {

  const handleChange = (event) => {
    props.setValue(event.target.value);
  };

  return (
    <Box sx={{...props.boxstyle ,minWidth: 120 }}>
      <FormControl fullWidth sx={{
        '& .Mui-focused': { border: '1px solid grey' },
        '& .MuiInputLabel-shrink': {border: 'none',  color: 'grey !important' } 
      }}>
        <InputLabel sx={{...props.lablestyle}} id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.value}
          label={props.label}
          onChange={handleChange}
          sx={{...props.style,
          }}
        >
        {
            props.items.map((curr) => {
                return(
                    <MenuItem value={curr.value}>{curr.text}</MenuItem>
                )
            })
        }
        </Select>
      </FormControl>
    </Box>
  );
}
