import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Stack } from '@mui/material';
import "./Timepicker.css";

export default function BasicTimePicker({value, setValue, style}) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
    <Stack spacing={3} sx={style}>
      <TimePicker
        label="Select Time"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField sx={{
                                                    width:"100%",
                                                    '& .Mui-focused': { border: '1px solid grey' },
                                                    '& .MuiInputLabel-shrink': {border: 'none',  color: 'grey !important' }
                                                }} 
                                            {...params} />}
      />
    </Stack>
    </LocalizationProvider>
  );
}