import * as React from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function MaterialUIPickers(props) {
  const handleChange = (newValue) => {
    props.setValue(newValue);
    if(props.name) props.handleDate(newValue,props.name);
    // props.setDateKey(props.name);
    // console.log(props.name);
    // props.setStartMinDate();
  };

  const handleDate = ()=>{

    if( props.status === "Scheduled"){

      // console.log(props.schedule , props.status);
      let today = new Date(props.schedule);
      let tomorrow = new Date();
      return tomorrow.setDate(today.getDay() +1);
      // let scheduleDate = new Date(props.schedule);
      // let month = scheduleDate.getMonth()+1;
      // let day = scheduleDate.getDate() + 1;
      // let date = scheduleDate.getFullYear()+"-"+(month < 10 ? '0'+month : month) +"-"+day;
      // date = String(date);
      // console.log(date);
      // return dayjs(date);


    }
    return props.minDate;
  }

  // console.log(props.value);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3} sx={{...props.style}} >
        <DesktopDatePicker
          minDate={ handleDate()}
          label={props.label}
          inputFormat="DD/MM/YYYY"
          value={props.value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          disablePast
        />
      </Stack>
    </LocalizationProvider>
  );
}
