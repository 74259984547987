// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#input_input__8qvUX{\n    height: \"48px\";\n    font-size: \"14px\";\n}\n\n", "",{"version":3,"sources":["webpack://./src/pages/new-page/input.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":["#input{\n    height: \"48px\";\n    font-size: \"14px\";\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input_input__8qvUX"
};
export default ___CSS_LOADER_EXPORT___;
