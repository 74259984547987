import React, { useEffect, useState } from 'react';
import axiosInstance from "../../axios/AxiosInstance";
import Input from '../../components/input/Input';
import { toast } from 'react-toastify';

const LocationField = (data) => {
    const [location, setLocation] = useState();
    const handleUpdateLocationAndPoint = async (id,) => {

        let body = {
            id: id,
            location: location,
        }
        try {
            const res = await axiosInstance.post(`/api/ticket/start/location`, body);
            toast.success('Location Update successFully')

        } catch (error) {
            console.error(error);
            toast.error('SomeThing went Wrong')
        }
    }


    return (
        <div>
            <Input some={() => handleUpdateLocationAndPoint(data?.data?.cell?.row.original?._id)} defaultValue={data?.data?.cell?.row?.original?.location} setvalue={setLocation} style={{ height: "30px", fontSize: '12px' }} placeholder="Update Location" />
        </div>
    )
}

export default LocationField 