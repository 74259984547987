import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axiosInstance from "../../../axios/AxiosInstance";
import { Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import Webcam from "react-webcam";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: 330,
  },
};

const VideoConstraint = {
  width: 540,
  facingMode: "environment",
};

const BasicModal = ({
  setLoading,
  ticketId,
  closeDetail,
  getdata,
  isSmall,
  getAgentdata,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const webcamref = useRef(null);
  const [imageUrl, setUrl] = useState(null);
  const [screenshot, setScreenshot] = useState([]);

  const userData = JSON.parse(localStorage.getItem("wh_tk")).data;
  const userId = userData._id;

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr?.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const sendImage = async () => {
    if (imageUrl === null) {
      alert("Capture the image");
      return;
    }



    try {
      const formData = new FormData();
      for (let i = 0; i < screenshot.length; i++) {
        const timestamp = new Date();
        var file = dataURLtoFile(screenshot[i], `${timestamp}.jpeg`);
        formData.append(`file`, file);
      }
      formData.append("userId", userId);
      const res = await axiosInstance.post(`api/ticket/files/${ticketId}`, formData);
      if (res.status === 200) {
        // alert("Your file has been uploaded !!");
        setScreenshot([])
        handleClose()
        toast.success("Your file has been uploaded !!")
        if (userData.type === 'agent') {

        }
        else {

        }
      }
      else {

        alert("Something went wrong!!");
      }
    } catch (err) {
      // console.log(err);

      alert("Something went wrong!!");
    }
  };

  const captureImage = useCallback(async () => {
    const imgSrc = webcamref.current.getScreenshot();
    setUrl(imgSrc);
    screenshot.push(imgSrc);
  }, [webcamref]);
  let handleDelete = useCallback(async () => {
    screenshot.pop();
    setUrl(null);
  }, [webcamref]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
      >
        Upload
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: "100000",
        }}
      >
        <Box sx={style}>
          <ClearIcon
            style={{
              position: "absolute",
              right: isSmall === true ? "10px" : "-30px",
              top: isSmall === true ? "10px" : "-22px",
              cursor: "pointer",
              color: isSmall === true ? "black" : "white",
              // '@media (max-width: 400px)':{
              //     right:"10px",
              //     top:"10px",
              //     color:"black",
              // },
            }}
            onClick={() => {
              setUrl(null);
              handleClose();
            }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Capture Image
          </Typography>
          <Box position="relative" height="10px">
            <DeleteIcon
              sx={{
                "@media (max-width: 785px)": {
                  fontSize: "1.5rem !important",
                },
                position: "absolute",
                right: 0,
              }}
              onClick={() => {
                handleDelete();
              }}
            />
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack direction="column">
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  marginBottom: "50px",
                }}
              >
                {imageUrl === null && (
                  <Webcam
                    ref={webcamref}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={VideoConstraint}
                    onUserMedia={(ev) => { }}
                    mirrored={false}
                  />
                )}
                {imageUrl && (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={imageUrl}
                      style={{ width: "100%" }}
                      alt="capturedImg"
                    />
                  </Stack>
                )}
              </Stack>
              {screenshot?.length ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  flexWrap="wrap"
                  sx={{
                    overflowY: "scroll",
                    height: "210px",
                    width: "350px",
                  }}
                >
                  {screenshot?.length
                    ? screenshot.map((data) => {
                      return (
                        <img
                          src={data}
                          style={{
                            padding: "4px",
                            height: "100px",
                            width: "100px",
                            marginTop: "-25px",
                            marginBottom: "25px",
                          }}
                          alt="Logo"
                        />
                      );
                    })
                    : null}
                </Stack>
              ) : null}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                    "@media (max-width: 785px)": {
                      fontSize: "0.6rem !important",
                      // width:"60px",
                      height: "30px",
                    },
                  }}
                  onClick={() => {
                    captureImage();
                  }}
                >
                  Capture
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                    "@media (max-width: 785px)": {
                      fontSize: "0.6rem !important",
                      // width:"60px",
                      height: "30px",
                    },
                  }}
                  onClick={() => {
                    setUrl(null);
                  }}
                >
                  Take More
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem!important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                    "@media (max-width: 785px)": {
                      fontSize: "0.6rem !important",
                      // width:"60px",
                      height: "30px",
                    },
                  }}
                  onClick={() => {
                    sendImage();
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default BasicModal;
