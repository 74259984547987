import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { Container, Row, Col } from 'react-grid-system';
import RoleCard from './RolecardOther';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axios/AxiosInstance';
import { useLocation } from 'react-router-dom';


const View = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Main = styled.div`
    width:90%;
    height:80%;
`
const Head = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
`
const Heading = styled.div`
    color:black;
    font-size:1.5rem;
    font-weight:700;        
`

const UserRole = () => {

    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);
    const [localupdate, setLocalupdate] = useState(false);

    const location = useLocation();
    const { state } = location;
    const { row } = state;

    const userid = JSON.parse(localStorage.getItem('wh_tk')).data._id; 

    const getRoles = async () => {
        const res = await axiosInstance.get(`api/role?userId=${userid}`);
       
        setRoles(res.data.data);
    }

    useEffect(() => {
        getRoles();
        // console.log(row);
    },[localupdate])

    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('wh_tk')).data;
        // console.log(userData);
        const userRole = userData.role;
        const outArr = userData.out;
        const userPermissions = userRole[0].permissions;

        userPermissions.map((curr,index) => {
                if(outArr[index].key === 'user_roles' && curr.create === true)
                {
                    setShowAdd(true);
                }
        })
    },[localupdate])

    const renderEle = (roles) => {
        if(roles?.length > 0)
        {
            return (
                roles.map((curr) => {
                return(
                    <RoleCard data={curr} row={row} localupdate={localupdate} setLocalupdate={setLocalupdate} />
                );
            })
            );
        }
        else return null;
    }

  return (
    <View>
        <Main>
            <Head>
                <Heading>User Role</Heading>
                {showAdd && 
                    <Button
                        variant="contained"
                        sx={{
                            width: '250px',
                            height:"40px",
                            backgroundColor: '#000',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#000',
                                opacity: '0.8'
                            }
                        }}
                        onClick={() => {navigate("/userrole/add_role")}}  
                    >
                        <AddIcon sx={{
                            marginRight: '0.5rem'
                        }} />
                        Add new user role
                    </Button> 
                }
            </Head>
            <Container style={{marginTop:"30px"}}>
            <Row gutterWidth={20} >
            {
                renderEle(roles) 
            }
            </Row>
            </Container>
        </Main>
    </View>
  )
}

export default UserRole