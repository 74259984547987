import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const HolidayCard = ({name}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'max-content',
        border: '1px solid #3D3C42',
        borderRadius: '5px',
        paddingX: '0.3rem',
        marginTop: '1rem',
        flexGrow: '1',
        width:"120px"
      }}>
      <Typography
        sx={{
          fontSize: '0.85rem',
          fontWeight: '500',
          paddingLeft: '1.3rem',
          paddingRight: '1.3rem',
        }}
      >
        {name}
      </Typography>
    </Box>
  )
}

export default HolidayCard