import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

const Nopermission = () => {

    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem("wh_tk");
        navigate('/login', { replace: true });
      }

  return (
    <>
        <div>You don't have any permissions to view the page</div>
        <Button
        variant="contained"
        sx={{
            height:"40px",
            backgroundColor: '#000',
            color: '#fff',
            marginTop:"20px",
            fontSize:"0.875rem !important",
            '&:hover': {
                backgroundColor: '#000',
                opacity: '0.8'
            }
        }}
        onClick={handleLogout}
        > Logout </Button>
    </>
  )
}

export default Nopermission