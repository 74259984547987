import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FormControl, Stack } from "@mui/material";
import {
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import RemarkEdit from "../../pages/city/cityTable/RemarksEdit";
import Select from "../select/Select";
import axiosInstance from "../../axios/AxiosInstance";
import Input from "./InputFiles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CaptureImage from "./CaptureImage";
import Loading from "../loading/Loading";
import Edit from "../../pages/city/cityTable/Agentmodal";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import { toast } from "react-toastify";

const DetailBox = ({
  Cityname,
  style,
  setOverlay,
  setDetailStyle,
  data,
  tableData,
  setTableData,
  flag,
  setFlag,
  getdata,
  getAgentdata,
  openAudit,
  isSmall,
}) => {
  const [dataArr, setDataArr] = useState([]);
  const [agentall, setAgentall] = useState([]);
  const [agent, setAgent] = useState("");
  const [loading, setLoading] = useState(false);
  const [transportItem, setTransportItem] = useState([
    {
      value: "",
      text: "",
    },
  ]);
  const [transport, setTransport] = useState("");
  const [selectedChoice, setSelectedChoice] = useState("transport");
  const [detailRefresh, setDetailRefresh] = useState(true);
  const [cityMsg, setCityMsg] = useState("");
  const [type, setType] = useState(false);
  const navigate = useNavigate();
  const [adhocNo, setAdhocNo] = useState("");
  const userData = JSON.parse(localStorage.getItem("wh_tk")).data;
  const userId = userData._id;

  const closeDetail = () => {
    setOverlay(false);
    setDetailStyle({ ...style, transform: "translateX(110%)" });

    setTimeout(() => {
      setDetailStyle({
        ...style,
        transform: "translateX(110%)",
        display: "none",
      });
    }, 1000);
  };

  const handleRadioChange = (event) => {
    setSelectedChoice(event.target.value);
  };

  const handleChange = async (ev) => {
    if (userData.type === "agent") {
      toast.warning("You dont have permission for this action!");
      return;
    }

    if (
      data.scheduledDate === null ||
      data.scheduledDate === undefined ||
      data.scheduledDate === "-"
    ) {
      toast.warning("Plz assign schedule date before assigning agent");
      return;
    }

    try {
      let newAgent = agentall.find((data) => data.value == ev.target.value);
      newAgent.name = newAgent.text;
      newAgent._id = newAgent.value;
      setAgent(newAgent);
      const userid = JSON.parse(localStorage.getItem("wh_tk")).data._id;

      const body = {
        agentId: ev.target.value,
        userId: userid,
      };

      const res = await axiosInstance.post(`api/ticket/${data._id}`, body);
      if (res.status === 200) {
        toast.success("Update successfull");
      } else {
        toast.error("Something went wrong!!");
      }
      // window.location.reload();
      setFlag(!flag);
      if (userData.type === "agent") {
        getAgentdata();
      } else {
        getdata();
      }
    } catch (err) {
      //console.log(err);
      toast.error("Something went wrong!!");
    }
  };

  const transportUpdate = async (ev) => {
    if (userData.type === "agent") {
      toast.warning("You dont have permission for this action!");
      return;
    }

    try {
      setTransport(ev.target.value);
      const userid = JSON.parse(localStorage.getItem("wh_tk")).data._id;

      const body = {
        transport: ev.target.value,
        userId: userid,
      };

      const res = await axiosInstance.post(`api/ticket/${data._id}`, body);

      if (res.status === 200) {
        toast.success("Update successfull");
        for (let i = 0; i < tableData?.length; i++) {
          if (tableData[i]._id === data._id) {
            tableData[i].transport = ev.target.value;
            break;
          }
        }

        setTableData(tableData);
      } else {
        toast.error("Something went wrong !!1");
      }
      // console.log(res);
      setFlag(!flag);
      if (userData.type === "agent") {
        getAgentdata();
      } else {
        getdata();
      }
      // closeDetail();
    } catch (err) {
      toast.error("Something went wrong !!2");
    }
  };

  const updateAdhocVehicleNo = () => {
    const user_id = JSON.parse(localStorage.getItem("wh_tk")).data._id;
    var wordsAndNumbersRegex = /^(?=.*[a-zA-Z])(?=.*\d).+$/;

    if (!wordsAndNumbersRegex.test(adhocNo)) {
      toast.warning("please give right vehicle number ");
    } else {
      const body = {
        adhoc_vehicle: adhocNo,
        userId: user_id,
      };

      axiosInstance
        .post(`api/ticket/${data._id}`, body)
        .then((res) => {
          if (res.data.success) {
            toast.success(res?.data?.data);
            // closeDetail();
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const getAgents = async () => {
    const res = await axiosInstance.get("api/agent");
    const newarr = [];
    const data = res.data.data;
    for (let i = 0; i < data?.length; i++) {
      const cities = data[i].warehouses.map((house) => {
        return house.city;
      });

      if (cities.includes(Cityname)) {
        newarr.push({
          value: data[i]._id,
          text: data[i].name,
        });
      }
    }
    // console.log("new arr", newarr);
    setAgentall(newarr);
  };

  const getMasterData = async () => {
    const res = await axiosInstance.get("api/master");
    const Data = res.data.data;
    let rows = [];
    let smsrows = [];
    let key;
    for (let i = 0; i < Data?.length; i++) {
      //console.log(Data[i]);
      if (Data[i].master === "transport") {
        rows = Data[i].rows;
        key = Data[i].key.toLowerCase();
        //console.log(key);
      }

      if (Data[i].master === "sms") {
        smsrows = Data[i].rows;
      }
    }

    // console.log(rows, key);

    const items = [];
    for (let i = 0; i < rows.length; i++) {
      // console.log("row ", rows[i]);
      if (rows[i].city === Cityname && rows[i].status) {
        const obj = {
          value: rows[i]["vehicle number"],
          text: rows[i]["vehicle number"],
        };
        items.push(obj);
      }
    }
    //console.log(items);
    setTransportItem(items);

    for (let i = 0; i < smsrows?.length; i++) {
      if (smsrows[i].city === Cityname) {
        setCityMsg(smsrows[i].message);
        break;
      }
    }
  };

  const sendMsg = async (
    city,
    mobile,
    deviceType,
    transport,
    agent,
    taskId
  ) => {
    const body = {
      phone: mobile,
      device_type: deviceType,
      transport: transport,
      agentId: agent,
      task_id: taskId,
    };

    try {
      const res = await axiosInstance.post(`api/ticket/send_sms/${city}`, body);
      if (res.status === 200) toast.success("Message sent!");
      else toast.warming(res?.data?.data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  const redirect = (url) => {
    window.open(url, "_blank");
  };

  const handleFileUpload = async (ev) => {
    try {
      const files = ev.target.files;
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      formData.append("userId", userId);
      alert("Your file has been uploaded !!");
      // closeDetail();
      const res = await axiosInstance.post(
        `api/ticket/files/${data._id}`,
        formData
      );
      if (res.status === 200) {
        setLoading(false);
        toast.success("Your file has been uploaded !!");
        // closeDetail();
        if (userData.type === "agent") {
          getAgentdata();
        } else {
          getdata();
        }
      } else {
        setLoading(false);
        toast.error("Something went wrong!!");
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      toast.error("Something went wrong!!");
    }
  };

  useEffect(() => {
    getAgents();

    getMasterData();
  }, [Cityname]);

  useEffect(() => {

    if (data.adhoc_vehicle) {
      setSelectedChoice("adhocNo");
      setAdhocNo(data.adhoc_vehicle);
    } else {
      setSelectedChoice("transport");
    }
  }, [data]);

  useEffect(() => {
    setTransport("");
    setAgent("");

    if (userData.type === "agent") {
      setType(true);
    }

    const newarr = [];
    if (data.firstName === null) {
      newarr.push(["name", data.lastName]);
    } else if (data.lastName === null) {
      newarr.push(["name", data.firstName]);
    } else newarr.push(["name", data.firstName + " " + data.lastName]);

    let isCommentPresent = false;
    let isAttachment = false;

    for (const key in data) {
      if (
        key === "firstName" ||
        key === "lastName" ||
        key === "__v" ||
        key === "auditTrailId" ||
        key === "user"
      ) {
        continue;
      }

      if (key === "comment") {
        isCommentPresent = true;
      }

      if (key === "attachment") {
        isAttachment = true;
      }

      if (key === "agent") {
        if (data[key]?.length !== 0) {
          setAgent(data[key][0]);
        }
      }

      if (key === "transport") {
        if (data[key] !== "-") {
          //console.log(data[key])
          setTransport(data[key]);
        }
      }
      if (key === "newData") {
        continue;
      }

      if (key === "address") {
        const val = data[key];

        const address =
          (val.cf_address_1 === null ? "" : val.cf_address_1 + " ") +
          (val.cf_address_2 === null ? "" : val.cf_address_2 + " ") +
          (val.cf_area === null ? "" : val.cf_area + ", ") +
          (val.cf_city === null ? "" : val.cf_city + ", ") +
          (val.cf_pincode === null ? "" : val.cf_pincode + ", ") +
          (val.cf_state === null ? "" : val.cf_state);

        const arr = [];
        arr.push(key);

        if (data[key] === null || data[key].length === 0) arr.push("-");
        else arr.push(address?.length > 0 ? address : data[key]);
        newarr.push(arr);
      } else {
        newarr.push([key, data[key]]);
      }
    }

    if (isCommentPresent === false) {
      const a = [];
      a.push("comment");
      a.push("-");
      newarr.push(a);
    }

    if (isAttachment === false) {
      const a = [];
      a.push("attachment");
      a.push("-");
      newarr.push(a);
    }

    setDataArr(newarr);
    setFlag(!flag);
  }, [data]);


  return (
    <>
      <div style={style}>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{
              width: "100%",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                }}
              >
                Details
              </Typography>

              <ClearIcon
                onClick={closeDetail}
                sx={{ color: "black", cursor: "pointer" }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              >
                Key
              </Typography>
              <div style={{ width: "40%" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Value
                </Typography>
              </div>
            </Stack>
            <Stack
              direction="column"
              spacing={3}
              sx={{
                width: "100%",
                overflowY: "scroll",
              }}
            >
              {isSmall && (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Stack direction="column" spacing={0.5}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        Audit
                      </Typography>
                    </Stack>

                    {data.auditTrailId === undefined ||
                      data.auditTrailId === null ? (
                      <Stack
                        direction="column"
                        spacing={0.5}
                        sx={{
                          width: "40%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: "500",
                            marginTop: "10px",
                          }}
                        >
                          No Audit Record
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack
                        direction="column"
                        spacing={0.5}
                        sx={{
                          width: "40%",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            height: "30px",
                            // width:"60%",
                            backgroundColor: "#000",
                            color: "#fff",
                            fontSize: "0.7rem!important",
                            marginTop: "10px",
                            "&:hover": {
                              backgroundColor: "#000",
                              opacity: "0.8",
                            },
                          }}
                          onClick={() => {
                            openAudit(data.auditTrailId);
                          }}
                        >
                          Audit Trail
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Stack direction="column" spacing={0.5}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        Edit Status
                      </Typography>
                    </Stack>

                    <Stack
                      direction="column"
                      spacing={0.5}
                      sx={{
                        width: "40%",
                      }}
                    >
                      <Edit
                        flag={flag}
                        setFlag={setFlag}
                        schedule={data.scheduledDate}
                        deliver={data.deliverDate}
                        rowStatus={data.status}
                        ticketId={data._id}
                        subcategory={data.subCategory}
                        getdata={getdata}
                        row={data}
                        closeDetail={closeDetail}
                      />
                    </Stack>
                  </Stack>
                </>
              )}
              {dataArr.map((curr) => {
                // console.log("data arr:", dataArr);
                if (curr[0] === "comment") {
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0]}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="column"
                          spacing={0.5}
                          sx={{
                            width: "40%",
                          }}
                        >
                          {curr[1] !== "-" && (
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                              }}
                            >
                              {curr[1]}
                            </Typography>
                          )}
                          <RemarkEdit
                            flag={flag}
                            tableData={tableData}
                            setTableData={setTableData}
                            data={data}
                            ticketId={data._id}
                            getdata={getdata}
                            closeDetail={closeDetail}
                            getAgentdata={getAgentdata}
                          />
                        </Stack>
                      </Stack>
                    </>
                  );
                } else if (userData.type !== "agent" && curr[0] === "agent") {
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0]}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="column"
                          spacing={0.5}
                          sx={{
                            width: "40%",
                          }}
                        >
                          <Select
                            items={agentall}
                            label="Select Agent"
                            value={agent?._id}
                            handleChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                    </>
                  );
                } else if (
                  curr[0] === "createdAt" ||
                  curr[0] === "scheduledDate" ||
                  curr[0] === "deliverDate" ||
                  curr[0] === "deadline"
                ) {
                  if (curr[1] === "-" || !curr[1]) {
                    return (
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Stack direction="column" spacing={0.5}>
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                              }}
                            >
                              {curr[0]}
                            </Typography>
                          </Stack>

                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                            sx={{
                              width: "40%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                              }}
                            >
                              -
                            </Typography>
                          </Stack>
                        </Stack>
                      </>
                    );
                  }
                  const date = new Date(curr[1]).toISOString().split("T")[0];
                  const time = new Date(curr[1])
                    .toTimeString()
                    .split(":")
                    .slice(0, 2)
                    .join(":");
                  const combinedDateTime = `${date}, ${time}`;
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0]}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                          sx={{
                            width: "40%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {combinedDateTime}
                          </Typography>
                        </Stack>
                      </Stack>
                    </>
                  );
                } else if (
                  userData.type !== "agent" &&
                  curr[0] === "transport"
                ) {
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {"Transport Choice"}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: "500",
                          }}
                        >
                          <label>
                            <input
                              type="radio"
                              value="transport"
                              checked={selectedChoice === "transport"}
                              onChange={handleRadioChange}
                              style={{ marginRight: "0.8rem" }}
                            />
                            Transport
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="adhocNo"
                              checked={selectedChoice === "adhocNo"}
                              onChange={handleRadioChange}
                              style={{ marginRight: "0.8rem" }}
                            />
                            Adhoc Vehicle Number
                          </label>
                        </Stack>
                      </Stack>

                      {selectedChoice === "transport" && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Stack direction="column" spacing={0.5}>
                            <Typography
                              sx={{ fontSize: "1rem", fontWeight: "500" }}
                            >
                              {curr[0]}
                            </Typography>
                          </Stack>

                          <Stack
                            direction="column"
                            spacing={0.5}
                            sx={{
                              width: "40%",
                            }}
                          >
                            <Select
                              items={transportItem}
                              label="Select"
                              value={transport}
                              handleChange={transportUpdate}
                            />
                          </Stack>
                        </Stack>
                      )}

                      {selectedChoice === "adhocNo" && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Stack direction="column" spacing={0.5}>
                            <Typography
                              sx={{ fontSize: "1rem", fontWeight: "500" }}
                            >
                              Adhoc Vehicle Number
                            </Typography>
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={0.5}
                            sx={{ width: "40%" }}
                          >
                            <TextField
                              type="text"
                              value={adhocNo}
                              variant="filled"
                              onChange={(e) => setAdhocNo(e.target.value)}
                            />

                            <Button
                              variant="contained"
                              sx={{
                                width: "40%",
                                backgroundColor: "#000",
                                color: "#fff",
                                fontSize: "0.7rem!important",
                                marginTop: "10px",
                                "&:hover": {
                                  backgroundColor: "#000",
                                  opacity: "0.8",
                                },
                              }}
                              onClick={updateAdhocVehicleNo}
                            >
                              Update
                            </Button>
                          </Stack>
                        </Stack>
                      )}
                    </>
                  );
                } else if (curr[0] === "caseUrl") {
                  return (

                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0]}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{
                            width: "40%",
                            cursor: "pointer",
                          }}
                        >
                          {curr[1] === "-" ? (
                            curr[1]
                          ) : (
                            <Link
                              onClick={() => {
                                redirect(curr[1]);
                              }}
                            >
                              {curr[1]}
                            </Link>
                          )}
                        </Stack>
                      </Stack>
                    </>
                  );
                } else if (curr[0] === "invoiceUrl") {
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0]}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{
                            width: "40%",
                            cursor: "pointer",
                          }}
                        >
                          {curr[1] === "-" ? (
                            curr[1]
                          ) : (
                            <Link
                              onClick={() => {
                                redirect(curr[1]);
                              }}
                            >
                              {curr[1]}
                            </Link>
                          )}
                        </Stack>
                      </Stack>
                    </>
                  );
                } else if (curr[0] === "attachment" && curr[1] === "-") {
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                          paddingBottom: 3,
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0]}
                          </Typography>
                        </Stack>

                        <Stack
                          // direction={ window.innerWidth > 600 ? "row" : "column" }
                          justifyContent="space-between"
                          spacing={0.5}
                          sx={{
                            width: "40%",
                            flexDirection: "column",
                            "@media (max-width: 785px)": {
                              fontSize: "0.6rem !important",
                              // width:"80px",
                              height: "30px",
                              flexDirection: "column",
                            },
                          }}
                        >
                          <div style={{ position: "relative", height: "40px" }}>
                            <Input
                              type="file"
                              style={{
                                position: "absolute",
                                zIndex: "2",
                                opacity: "0",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              setLoading={setLoading}
                              setFlag={setFlag}
                              handleChange={handleFileUpload}
                            />
                            <Button
                              variant="contained"
                              sx={{
                                position: "absolute",
                                height: "40px",
                                width: "100%",
                                backgroundColor: "#000",
                                color: "#fff",
                                fontSize: "0.8rem!important",
                                zIndex: "1",
                                "&:hover": {
                                  backgroundColor: "#000",
                                  opacity: "0.8",
                                },
                                "@media (max-width: 785px)": {
                                  fontSize: "0.6rem !important",
                                  width: "100%",
                                  height: "30px",
                                },
                              }}
                            >
                              <AddCircleIcon sx={{ marginRight: "10px" }} />
                              Upload
                            </Button>
                          </div>
                          {loading && (
                            <Loading
                              style={{
                                zIndex: "10",
                                margin: 0,
                                height: "30px",
                                width: "30px",
                              }}
                              color={isSmall === true ? "white" : "black"}
                            />
                          )}
                          <CaptureImage
                            getAgentdata={getAgentdata}
                            isSmall={isSmall}
                            setLoading={setLoading}
                            ticketId={data._id}
                            closeDetail={closeDetail}
                            getdata={getdata}
                          />
                        </Stack>
                      </Stack>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack direction="column" spacing={0.5}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0]}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="column"
                          spacing={0.5}
                          sx={{
                            width: "40%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {curr[0] === "agent" ? agent?.name : curr[1]}
                          </Typography>
                        </Stack>
                      </Stack>
                    </>
                  );
                }
              })}
              {data?.newData?.customer && (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Stack direction="column" spacing={0.5}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        mobile
                      </Typography>
                    </Stack>

                    <Stack
                      direction="column"
                      spacing={0.5}
                      sx={{
                        width: "40%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        {data?.newData?.customer[0]?.phone}{" "}
                      </Typography>
                    </Stack>
                  </Stack>
                </>
              )}
              {data?.newData?.customer && (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Stack direction="column" spacing={0.5}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        address
                      </Typography>
                    </Stack>

                    <Stack
                      direction="column"
                      spacing={0.5}
                      sx={{
                        width: "40%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        {data?.newData?.customer[0]?.street}{" "}
                        {data?.newData?.customer[0]?.city}{" "}
                        {data?.newData?.customer[0]?.state}
                      </Typography>
                    </Stack>
                  </Stack>
                </>
              )}
              {data?.newData?.files && (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Stack direction="column" spacing={0.5}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        files
                      </Typography>
                    </Stack>
                    {data?.newData?.files?.length > 0 ? (
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                          width: "40%",
                        }}
                      >
                        {data.newData.files.map((file) => {
                          return (
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                  color: "#000",
                                  fontWeight: "700",
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={() => {
                                window.open(file.s3Data.Location, "_blank");
                              }}
                            >
                              {file.name}
                            </Typography>
                          );
                        })}
                      </Stack>
                    ) : (
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                          width: "40%",
                        }}
                      >
                        {"-"}
                      </Stack>
                    )}
                  </Stack>
                </>
              )}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Stack direction="column" spacing={0.5}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Send SMS
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{
                    width: "40%",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      height: "40px",
                      width: "100%",
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem!important",
                      zIndex: "1",
                      "&:hover": {
                        backgroundColor: "#000",
                        opacity: "0.8",
                      },
                      "@media (max-width: 785px)": {
                        fontSize: "0.6rem !important",
                        width: "100%",
                        height: "30px",
                      },
                    }}
                    onClick={() => {
                      sendMsg(
                        Cityname,
                        data?.newData?.customer[0]?.phone,
                        "Android",
                        transport,
                        agent?._id,
                        data?.newData?.files[0]?.ticketId
                      );
                    }}
                  >
                    <AndroidIcon sx={{ marginRight: "10px" }} />
                    Send
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      height: "40px",
                      width: "100%",
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem!important",
                      zIndex: "1",
                      "&:hover": {
                        backgroundColor: "#000",
                        opacity: "0.8",
                      },
                      "@media (max-width: 785px)": {
                        fontSize: "0.6rem !important",
                        width: "100%",
                        height: "30px",
                      },
                    }}
                    onClick={() => {
                      sendMsg(
                        Cityname,
                        data?.newData?.customer[0]?.phone,
                        "IOS",
                        transport,
                        agent?._id,
                        data?.newData?.files[0]?.ticketId
                      );
                    }}
                  >
                    <AppleIcon sx={{ marginRight: "10px" }} />
                    Send
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default DetailBox;
