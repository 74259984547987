import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Input from "../../../components/input/Input";
import { Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import axiosInstance from "../../../axios/AxiosInstance";
import ClearIcon from "@mui/icons-material/Clear";
import Loading from "../../../components/loading/Loading";
import { Select, MenuItem } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BasicModal = ({
  messages,
  setMessages,
  setLoading,
  createData,
  exportData,
  loading,
  setCityName,
  cityname,
  setTotalPage,
  setIsCityFilterApplied,
  setPage,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [smsType, setSmsType] = useState("");
  const handleChange = (event) => {
    setCityName(event.target.value);
  };
  const handleChange1 = (event) => {
    setSmsType(event.target.value);
  };
  const getdata = async () => {
    let url = "";
    if (cityname !== "") {
      url = url + `city$contains=${cityname}&`;
      setIsCityFilterApplied(true);
    }
    if (email !== "") {
      url = url + `email$contains=${email}&`;
    }
    if (name !== "") {
      url = url + `name$contains=${name}&`;
    }
    if (smsType !== "") {
      url = url + `smsType$contains=${smsType}&`;
    }

    const base_url = `api/ticket/sms/record?page=${1}&limit=${10}&`;

    url = base_url + url;

    try {
      setLoading(true);
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        setLoading(false);
        let allMessages = response.data.data.docs;
        allMessages = allMessages.map((message) => {
          return createData(message);
        });
        setMessages(allMessages);
        exportData(url);
        setTotalPage(response.data.data.totalPages);
        setPage(1);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    handleClose();
  };

  const reset = async () => {
    setMessages([]);
    setEmail("");
    setName("");
    setPhone("");
    setCityName("");

    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/api/ticket/sms/record?page=${1}&limit=${10}`
      );
      if (response.status === 200) {
        setLoading(false);
        let allMessages = response.data.data.docs;
        allMessages = allMessages.map((message) => {
          return createData(message);
        });
        setMessages(allMessages);
        setTotalPage(response.data.data.totalPages);
        setPage(0);
        setIsCityFilterApplied(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          height: "40px",
          backgroundColor: "#000",
          color: "#fff",
          fontSize: "0.875rem !important",
          "&:hover": {
            backgroundColor: "#000",
            opacity: "0.8",
          },
        }}
        onClick={handleOpen}
      >
        <FilterAltIcon sx={{ marginRight: "10px" }} />
        Filter
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon
            style={{
              position: "absolute",
              right: "-30px",
              top: "-22px",
              cursor: "pointer",
            }}
            onClick={() => {
              setEmail("");
              setName("");
              setPhone("");
              setCityName("");
              handleClose();
            }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filter
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack direction="column">
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  marginBottom: "50px",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Name
                  </Typography>
                  <Input
                    value={name}
                    setvalue={setName}
                    style={{ width: "70%" }}
                    type="text"
                    placeholder="Enter name"
                    some={getdata}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Email
                  </Typography>
                  <Input
                    value={email}
                    setvalue={setEmail}
                    style={{ width: "70%" }}
                    type="text"
                    placeholder="Enter email"
                    some={getdata}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    City
                  </Typography>
                  <Select
                    value={cityname}
                    style={{ width: "70%", borderColor: "black" }}
                    type="text"
                    placeholder="Enter City"
                    some={getdata}
                    onChange={handleChange}
                  >
                    <MenuItem value={"Mumbai"}>Mumbai</MenuItem>
                    <MenuItem value={"Noida"}>Noida</MenuItem>
                    <MenuItem value={"Pune"}>Pune</MenuItem>
                    <MenuItem value={"Gurgaon"}>Gurgaon</MenuItem>
                    <MenuItem value={"Hyderabad"}>Hyderabad</MenuItem>
                    <MenuItem value={"Jodhpur"}>Jodhpur</MenuItem>
                    <MenuItem value={"Bangalore"}>Bangalore</MenuItem>
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Sms Type 
                  </Typography>
                  <Select
                    value={smsType}
                    style={{ width: "70%", borderColor: "black" }}
                    type="text"
                    placeholder="Enter City"
                    some={getdata}
                    onChange={handleChange1}
                  >
                    <MenuItem value={"Android"}>Android</MenuItem>
                    <MenuItem value={"IOS"}>IOS</MenuItem>
                  </Select>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={getdata}
                >
                  {loading ? <Loading /> : "Ok"}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem!important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={reset}
                >
                  {loading ? <Loading /> : "Reset Filter"}
                </Button>
              </Stack>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
