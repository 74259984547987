import React from 'react'
import styled from 'styled-components'
import { Stack } from '@mui/material'
import {Typography} from '@mui/material'
import AuditTable from "./AuditTable"
import ClearIcon from '@mui/icons-material/Clear';

const auditBox = ({style,setAuditStyle, auditId, setOverlay, flag, setFlag}) => {

    const closeAudit = () => {
        setOverlay(false);
        setAuditStyle({...style, transform:"translateX(110%)"});

        setTimeout(() => {
            setAuditStyle({...style, transform:"translateX(110%)", display:"none"});
        },1000)
    }

  return (
    <div style={style}>
        <Stack
        direction="row"
        // alignItems="center"
        justifyContent="center"
        sx={{
            width:"100%",
            height:"100%"
        }}
        >
            <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{
                width:"100%",
            }}
            >
                <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    width:"100%",
                    zIndex:"50"
                }}
                >
                     <Typography sx={{
                    fontSize: '1.5rem',
                    fontWeight: '700'
                    }} >Audit Changes</Typography>

                    <ClearIcon onClick={closeAudit} sx={{color:"black", cursor:"pointer"}} />
                </Stack>
                <AuditTable auditStyle={style} auditId={auditId} setAuditStyle={setAuditStyle} flag={flag} />  
            </Stack>
        </Stack>
    </div>
  )
}

export default auditBox