import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import axiosInstance from "../../axios/AxiosInstance";
export default function SelectSmall({ agenct, setReloard, reloard }) {
  const [age, setAge] = React.useState(agenct?.cell?.row?.original?.agentId);
  let disabled = [
    "Completed",
    "Failed",
    "Delivered",
    "Pickup Done",
    "Task Failed",
    "Pickup Failed",
    "Delivery Failed",
  ];

  useEffect(() => {
    let id = setTimeout(() => {
      setAge(agenct?.cell?.row?.original?.agentId)
      clearTimeout(id)
    }, 1000);
  }, [agenct?.cell?.row?.original?.agentId])
  

  const handleChange = async (event) => {
    setAge(event.target.value);
    let body = {
      agentId: event.target.value,
      userId: JSON.parse(localStorage.getItem("wh_tk")).data._id,
    };
    const res = await axiosInstance.post(
      `api/ticket/${agenct.cell.row.original._id}`,
      body
    );
    if (res.status === 200) {
      setReloard(++reloard);
      toast.success("Update successfull");
    } else {
      return alert("Something went wrong !!");
    }
  };

  const inputStyles = {
    padding: "-3px",
  };
  return (
    <Box
      InputProps={{
        style: inputStyles,
      }}
    >
      <FormControl sx={{ minWidth: 120 }} size="small" some>
        <InputLabel id="demo-select-small-label">Agent</InputLabel>
        <Select
          disabled={disabled.includes(agenct.cell.row.original.status)}
          sx={{
            width: "140px",
          }}
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={
            age
          }
          label="Agent"
          onChange={handleChange}
          defaultValue={true}
        >
          <MenuItem onKeyDown={(e) => e.stopPropagation()} value={"empty"}>
            empty
          </MenuItem>
          {agenct?.headers
            ?.filter((item) => item.Header == "Agent")?.[0]
            ?.as?.map((item) => (
              <MenuItem key={item.name} value={item._id}>
                {item?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
