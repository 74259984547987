import React from 'react'
import DatePicker from "../../components/datepicker/DatePicker";
import { Stack, Typography } from '@mui/material';
import Input from '../../components/input/Input';
import Select from "../../components/select/Select";


const Items = [
    {
        value:'one',
        text:'one'
    },
    {
        value:'two',
        text:'two'
    },{
        value:'three',
        text:'three'
    },
    {
        value:'four',
        text:'four'
    },
];

const AddData = () => {
  return (
    <div style={{padding:"20px", backgroundColor:"#fff"}}>
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width:"100%",
                marginBottom:"20px"
            }}
        >
            <Typography sx={{
            fontSize: '1.5rem',
            fontWeight: '700'
            }} >
                Add new data
            </Typography>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Enter Date
                </Typography>
                <div style={{height: "40px", width:"100%"}}>
                <DatePicker 
                style={{
                    fontSize: "18px",
                    padding: "10px",
                    borderRadius: "8px",
                    border: "1px solid grey",
                    width:"40%"
                }} />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Transport
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Point
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Enter point" type="text" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        LOB
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Case ID
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Eg. 32HDF57SHGE54" type="text" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Customer name
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Enter customer name" type="text" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Location 
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Enter location" type="text" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        SKU
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Case URL
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Enter case url" type="text" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Job type
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Deadline
                </Typography>
                <div style={{height: "40px", width:"100%"}}>
                <DatePicker 
                style={{
                    fontSize: "18px",
                    padding: "10px",
                    borderRadius: "8px",
                    border: "1px solid grey",
                    width:"40%"
                }} />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Done
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Enter time
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Enter time" type="text" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Vendor name
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Enter vendor name" type="text" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Status
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        FMP status
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Ranger 1
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Ranger 2
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Tigersheet
                </Typography>
                <div style={{height: "40px", width:"100%"}}>
                <DatePicker 
                style={{
                    fontSize: "18px",
                    padding: "10px",
                    borderRadius: "8px",
                    border: "1px solid grey",
                    width:"40%"
                }} />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Enter Rewards
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"1px solid grey"}} placeholder="Enter rewards" type="text" />
                </div>
            </Stack>
        </Stack>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            width:"100%",
            marginBottom:"15px"
        }}
        >
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Truck type
                </Typography>
                <div style={{height: "40px", width:"100%", borderRadius:"8px"}}>
                    <Select style={{height:"40px", width:"100%", backgroundColor:"#fff", borderRadius:"8px"}} items={Items} lablestyle={{top:"-5px"}} label="Select Dropdown" />
                </div>
            </Stack>
            <Stack
            direction="column"
            justifyContent="center"
            sx={{width:"45%"}}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    }} >
                        Truck Number
                </Typography>
                <div style={{width:"100%"}}>
                    <Input style={{borderRadius:"8px",fontSize:"0.9rem", border:"10.9x solid grey"}} placeholder="Enter truck number" type="text" />
                </div>
            </Stack>
        </Stack>
    </div>
  )
}

export default AddData