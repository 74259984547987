import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axiosInstance from '../../axios/AxiosInstance';
import { Select, Stack } from '@mui/material';
import Loading from '../../components/loading/Loading';
import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import ChipSelect from '../userrole/ChipEditRole'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': {
    width: 330
  }
};


const BasicModal = ({
  id,
  masterId,
  column,
  flag,
  setFlag,
  curValue
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rolename,setRolename] = useState({});
  const [list,setList] = useState([]);

  const [value, setValue] = useState(curValue);
  const [loading, setLoading] = useState(false);
  const [data,setData] = useState([]);

  const handleChange = (ev) => {
    setValue(ev.target.value)
  }

  const EditRow = async (id, masterId, column) => {

    // console.log(rolename[column]);

    const toEdit = `rows.$.${column}`
    const body = {
      "masterId": masterId,
      [toEdit]: rolename[column]
    }

    setLoading(true);
    try {
      const res = await axiosInstance.post(`api/master/row/${id}`, body);
      if (res.status === 200) {
        alert("Row update successfull");
        setFlag(!flag);
        setLoading(false);
      }
      else {
        setLoading(false);
        alert("Something went wrong!!")
      }
    }
    catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong!!")
    }

    handleClose();
  }

  useEffect(() => {
    const getData = async () => {
      const res = await axiosInstance.get('api/master');
      // console.log(res);
    //   setData(res.data.data);

      const newarr = res.data.data;
    //   console.log(newarr);

      for (let i = 0; i < newarr.length; i++) {
        let flag = false;
        if (newarr[i]._id === masterId) {

            for( let j = 0; j < newarr[i].fields.length ; j++){

                
                if( newarr[i].fields[j].val === column){
                    // console.log( newarr[i].fields[j]);
                    flag = true;
                    let name = newarr[i].fields[j].items.map((item)=>item.value);
                    setData(name);
                    break;
                }
            }
          
        }
        if(flag) break;
      }
    //   let names = [];
    //   data.map((val)=>{
    //       names.push(val.value);
    //   })
    //   setList(names);
    //   setData(names); 
    //   console.log(data);
    }
    getData();
  }, [])

//   console.log(data);
  let newarr = [];
  return (
    <div>
      <Button variant="contained"
        sx={{
          // height: "40px",
          // backgroundColor: '#000',
          boxShadow: '0 0 0',
          color: '#grey',
          fontSize: "0.875rem !important",
          '&:hover': {
            // backgroundColor: '#000',
            opacity: '0.8'
          }
        }} onClick={handleOpen}>
        <CreateIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon sx={{
            position: "absolute",
            right: "-30px",
            top: "-22px",
            cursor: "pointer",
            color: "white",
            '@media (max-width: 400px)': {
              right: "10px",
              top: "10px",
              color: "black",
            },
          }} onClick={() => {
            handleClose();
          }} />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack
              direction="column"
            >
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  marginBottom: "30px",
                }}
              >
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: '500',
                    }}
                  >
                  </Typography>
                  {/* <Select
                    native
                    value={column}
                    onChange={handleChange}
                    sx={{
                      width: "120px",
                      height: "40px",
                      fontSize: "1rem",
                    }}
                  >
                    {
                      options.map((option, index) => {
                        return (
                          <option
                            key={index}
                            value={option}
                          >
                            {option}
                          </option>
                        )
                      })
                    }
                  </Select> */}
                  <ChipSelect names = {data} newarr={newarr} rolename={rolename} setRolename={setRolename} permissionId={column}/>

                </div>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Button variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize: "0.8rem !important",
                    '&:hover': {
                      backgroundColor: '#000',
                      opacity: '0.8'
                    }
                  }}
                  onClick={() => { EditRow(id, masterId, column) }} >
                  {
                    loading ?
                      <Loading />
                      :
                      "Ok"
                  }
                </Button>
              </Stack>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;

