import React, {useState} from 'react'
import styled from 'styled-components'

const InputBox = styled.input`
    width:100%;
    height:40px;
    padding:10px;
    font-size:18px;
`

const Input = ({style, placeholder, value, setvalue, type, handleChange}) => {

  return (
    <InputBox type={type} style={style} placeholder = {placeholder} value = {value} onChange={handleChange}/>
  )
}

export default Input