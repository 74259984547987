import React, { useState, useEffect, useMemo } from 'react';
import styles from "./style.module.css";
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import BounceLoader from "react-spinners/BounceLoader";
import DataNotFound from '../../assets/dataNotfound.svg'
import {
    Select,
    MenuItem,
} from "@mui/material";
import axiosInstance from "../../axios/AxiosInstance";
import moment from 'moment';



const DatePickerContainer = styled.div`
    margin: 20px 0;
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
`;

const Index = () => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("scheduled");
    const [statusForTrip, setstatusForTrip] = useState("scheduled");


    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate1, endDate1] = dateRange;

    const handleChange = (event) => {
        setStatus(event.target.value);
        setstatusForTrip(event.target.value == "Assigned" ? "assigned" : "scheduled")
    };

    let CityNames = JSON.parse(localStorage.getItem("wh_tk")).data.warehouses;
    let checkAdmin = JSON.parse(localStorage.getItem("wh_tk")).data.type;

    // if (checkAdmin == "admin") {
    //     CityNames = house;
    // }


    let house = [
        {
            _id: "6353dd2fc9383308bab3b855",
            name: "Noida",
            createdAt: "2022-10-22T12:08:15.928Z",
            updatedAt: "2022-10-22T12:08:15.928Z",
            __v: 0,
            city: "Noida",
        },
        {
            _id: "6385d82369d7b11ec8dbce2f",
            name: "Pune",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Pune",
        },
        {
            _id: "639ee663a0a2c3856669742c",
            name: "Mumbai",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Mumbai",
        },
        {
            _id: "639ee6aaa0a2c3856669742e",
            name: "Jodhpur",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Jodhpur",
        },
        {
            _id: "639ee6d6a0a2c38566697430",
            name: "Gurgaon",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Gurgaon",
        },
        {
            _id: "639ee6ffa0a2c38566697431",
            name: "Bangalore",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Bangalore",
        },
        {
            _id: "639ee735a0a2c38566697433",
            name: "Hyderabad",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Hyderabad",
        },
    ];
    if (checkAdmin == "admin") {
        CityNames = house;
    }

    const [city, setCity] = useState(
        CityNames[1]?.name ? CityNames[1]?.name : CityNames[0]?.name
    );


    const handleOrderData = () => {
        if (startDate && endDate) {
            getData()

        }
    }

    const handleDate = (dates) => {
        const [start, end] = dates;
        const date = new Date();

        setStartDate(start);
        setEndDate(end);
    };
    const getData = async (sD, eD) => {
        try {
            setLoading(true)
            const res = await axiosInstance.get(`/api/time-Duration?city=${city}&startDate=${sD ? moment(sD.toUTCString()).format("YYYY-MM-DD") : moment(startDate.toUTCString()).format("YYYY-MM-DD")}&endDate=${eD ? moment(eD.toUTCString()).format("YYYY-MM-DD") : moment(endDate.toUTCString()).format("YYYY-MM-DD")}`);
            setTableData(res?.data?.data);
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getData()
    }, [city, startDate, endDate])

    const getInitialDate = () => {
        const today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        let dayBeforeYesterday = new Date(today);
        dayBeforeYesterday.setDate(today.getDate() - 2);

        setStartDate(dayBeforeYesterday)
        setEndDate(yesterday);
        getData(dayBeforeYesterday, yesterday)
    }

    useEffect(() => {
        getInitialDate()
    }, [])

    const handleTask = (value, cat) => {
        const data = value.jobTypes.filter((item) => {
            if (item?.jobType == cat) {
                return item
            }
        })
        return data[0]
    }


    function convertMilliseconds(milliseconds) {
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    const totalTime = (value) => {
        const grandTotalTime = value?.jobTypes?.reduce((total, job) => Math.abs(total) + Math.abs(job.totalTime), 0);
        const data = convertMilliseconds(grandTotalTime);

        return data;
    }
    const totalNumberOfTask = (value) => {
        let newarray = [];
        const data = tableData?.map((item) => {
            item?.jobTypes?.map((i) => {
                if (i.jobType == value) {
                    newarray.push(i)
                }
            })
        })
        const totalSum = newarray?.reduce((sum, job) => sum + job.numberOfTasks, 0);
        return totalSum

    }
    const reset = () => {
        setStartDate(null);
        setEndDate(null);
    };


    const totalNumberofTime = (value) => {
        let newarray = [];
        const data = tableData?.map((item) => {
            item?.jobTypes?.map((i) => {
                if (i.jobType == value) {
                    newarray.push(i)
                }
            })
        })
        const totalSum = newarray?.reduce((sum, job) => sum + job.totalTime, 0);
        return convertMilliseconds(totalSum)
    }

    const grandTotalOfTime = useMemo(() => {
        const timearray = [];
        tableData?.forEach((item) => {
            item.jobTypes?.forEach((i) => {
                timearray.push(i?.totalTime);
            });
        });
        const sum = timearray.reduce((acc, num) => acc + num, 0);
        return convertMilliseconds(sum);
    }, [tableData]);



    const exportTicket = async () => {
        const sD = new Date(startDate).getTime();
        const eD = new Date(endDate).getTime();
        console.log(sD, eD)


        let url = `${process.env.REACT_APP_API_URL}api/trip/export?&download=csv&type=${statusForTrip}&`;
        // let start = new Date(dateRange[0]).toUTCString();
        // let end = new Date(dateRange[1]);
        // end = end.setHours(23, 59, 59, 999);
        // end = new Date(end).toUTCString();
        // if (start > end) {
        //     alert("End date cannot be before start date");
        //     reset();
        //     return;
        // }
        url =
            url +
            `&start=${sD}&end=${eD}&city=${city}`;
        var newWindow = window.open(url);
    };

    return (
        <div className={styles.MainContainer}>
            <div className={styles.Heading}> Task Time Duration</div>
            <div className={styles.DateContainer}>
                <DatePickerContainer>
                    <div style={{ display: 'flex', gap: "20px" }}>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDate}
                            id="DashboardInput"
                        />
                        <div >
                            <Select
                                value={city || CityNames[1]?.name}
                                style={{
                                    marginLeft: "3px",
                                    width: "220px",
                                    borderColor: "black",
                                    height: "30px",
                                    padding: "18px"
                                }}
                                type="text"
                                placeholder="Enter City"
                                onChange={(event) => {
                                    setCity(event.target.value);
                                    setPage(1);
                                }}
                            >
                                {CityNames?.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data?.name}>
                                            {data?.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <button onClick={() => exportTicket()} className="TatSubmitbutton">Export</button>
                        <button onClick={() => handleOrderData()} className="TatSubmitbutton">Submit</button>
                    </div>

                </DatePickerContainer>
            </div>

            <div className={styles.TableContainer}>


                <div style={{ overflow: 'scroll' }}>
                    {!loading ? <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <th colSpan="50" style={cellStyleHeader}>Task</th>
                            </tr>
                            <tr>
                                <th rowSpan="2" style={cellStyleHeader}>Transport</th>
                                <th rowSpan="2" style={cellStyleHeader}>Agent Name</th>
                                <th colSpan="2" style={cellStyleHeader}>B2B</th>
                                <th colSpan="2" style={cellStyleHeader}>New - Rental</th>
                                <th colSpan="2" style={cellStyleHeader}>Pickup and Refund</th>
                                <th colSpan="2" style={cellStyleHeader}>Relocation</th>
                                <th colSpan="2" style={cellStyleHeader}>Repair</th>
                                <th colSpan="2" style={cellStyleHeader}>Upgrade</th>
                                <th colSpan="2" style={cellStyleHeader}>Replace</th>
                                <th colSpan="2" style={cellStyleHeader}>Installation</th>
                                <th colSpan="2" style={cellStyleHeader}>New - Buy</th>
                                <th rowSpan="2" style={cellStyleHeader}>Grand Total</th>
                            </tr>
                            <tr>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((item) => {

                                return <tr>
                                    <td style={cellStyle}>{item?.transport}</td>
                                    <td style={cellStyle}>{item?.agent}</td>

                                    <td style={cellStyle}>{handleTask(item, "B2B")?.numberOfTasks ? handleTask(item, "B2B")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "B2B")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "B2B")?.totalTime) * 1000).format('HH:mm:ss')

                                        ? convertMilliseconds(handleTask(item, "B2B")?.totalTime) : 0
                                    }
                                    </td>

                                    <td style={cellStyle}>{handleTask(item, "New - Rental")?.numberOfTasks ? handleTask(item, "New - Rental")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "New - Rental")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "New - Rental")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "New - Rental")?.totalTime) : 0
                                    }</td>

                                    <td style={cellStyle}>{handleTask(item, "Pickup and Refund")?.numberOfTasks ? handleTask(item, "Pickup and Refund")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Pickup and Refund")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Pickup and Refund")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Pickup and Refund")?.totalTime) : 0
                                    }</td>

                                    <td style={cellStyle}>{handleTask(item, "Relocation")?.numberOfTasks ? handleTask(item, "Relocation")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Relocation")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Relocation")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Relocation")?.totalTime) : 0
                                    }</td>


                                    <td style={cellStyle}>{handleTask(item, "Repair")?.numberOfTasks ? handleTask(item, "Repair")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Repair")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Repair")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Repair")?.totalTime) : 0

                                    }</td>


                                    <td style={cellStyle}>{handleTask(item, "Upgrade")?.numberOfTasks ? handleTask(item, "Upgrade")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Upgrade")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Upgrade")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Upgrade")?.totalTime) : 0
                                    }</td>


                                    <td style={cellStyle}>{handleTask(item, "Replace")?.numberOfTasks ? handleTask(item, "Replace")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Replace")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Replace")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Replace")?.totalTime) : 0
                                    }</td>


                                    <td style={cellStyle}>{handleTask(item, "Installation")?.numberOfTasks ? handleTask(item, "Installation")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Installation")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Installation")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Installation")?.totalTime) : 0
                                    }</td>



                                    <td style={cellStyle}>{handleTask(item, "New - Buy")?.numberOfTasks ? handleTask(item, "New - Buy")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "New - Buy")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "New - Buy")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "New - Buy")?.totalTime) : 0
                                    }</td>

                                    <td style={cellStyle}>{totalTime(item)}</td>
                                </tr>
                            })}
                        </tbody>
                        <tfoot>
                            <tr style={{ backgroundColor: "#d9d9d9" }}>
                                <th colSpan="2" style={cellStyleHeader}>Grand Total</th>
                                <td style={cellStyleHeader}>{totalNumberOfTask("B2B")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("B2B")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("New - Rental")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("New - Rental")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("Pickup and Refund")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("Pickup and Refund")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("Relocation")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("Relocation")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("Repair")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("Repair")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("Upgrade")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("Upgrade")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("Replace")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("Replace")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("Installation")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("Installation")}</td>
                                <td style={cellStyleHeader}> {totalNumberOfTask("New - Buy")}</td>
                                <td style={cellStyleHeader}> {totalNumberofTime("New - Buy")}</td>
                                <td style={cellStyleHeaderBold}>{grandTotalOfTime}</td>
                            </tr>
                        </tfoot>
                    </table> :
                        <div style={{ height: "40vh", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                            <BounceLoader />
                        </div>}
                </div>
            </div>

        </div>
    );
};

export default Index;

const cellStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#fff",
    whiteSpace: "nowrap"
};

const cellStyleHeader = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#D8E1F1",
    whiteSpace: "nowrap"
};

const cellStyleHeaderBold = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#D8E1F1",
    whiteSpace: "nowrap",
    fontWeight: "bold"
};