import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography } from "@mui/material";
import Loading from "../../components/loading/Loading";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
// import Data from "./Data";
import AddColumn from "./AddColumn";
import AddRows from "./AddRow";
import axiosInstance from "../../axios/AxiosInstance";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import EditRowModal from "./EditRowModal";
import EditTimeModal from "./EditTimeModal";
import EditDropdownModal from "./EditDropdownModal";
import EditDateModal from "./EditDateModal";
import EditArrayModal from "./EditArrayModal";

function createData(curr) {
  return { ...curr };
}

const MasterTable = () => {
  const [page, setPage] = useState(0);
  const [flag, setFlag] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState({});
  const [Data, setAllData] = useState([]);
  const [edit, setEdit] = useState(false);

  const location = useLocation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const switchChange = async (rowid, status) => {
    const body = {
      masterId: data._id,
      "rows.$.status": !status,
    };
    const res = await axiosInstance.post(`api/master/row/${rowid}`, body);
    if (res.status === 200) {
      alert("Status update successfull");
      setFlag(!flag);
    }
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  function convertToIST(utcDateTime) {
    var date = new Date(utcDateTime);
    var offset = 0; // IST offset
    var IST = new Date(date.getTime() + offset * 60 * 60 * 1000);
    return IST.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  useEffect(() => {
    const getData = async () => {
      const res = await axiosInstance.get("api/master");
      setAllData(res.data.data);

      const newarr = res.data.data;
      // console.log("transport data", newarr)

      const { data } = location.state;

      for (let i = 0; i < newarr.length; i++) {
        if (newarr[i]._id === data._id) {
          setData(newarr[i]);
          const columns = newarr[i].fields.map((curr) => {
            const curr_id = curr.val.toLowerCase();
            return {
              id: curr_id,
              label: curr.val,
              minWidth: 170,
              type: curr.type,
            };
          });
          setColumns(columns);

          setTotalRows(newarr[i].rows.length);

          break;
        }
      }
    };
    getData();
  }, [flag]);

  const dropdownFieldVals = data?.fields?.filter(
    (item) => item.type === "dropdown"
  );

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
          }}
        >
          {data.master}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={2}>
          {/* <AddColumn flag={flag} setFlag={setFlag} data={data} /> */}
          <Button
            variant="contained"
            sx={{
              height: "40px",
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "0.875rem !important",
              "&:hover": {
                backgroundColor: "#000",
                opacity: "0.8",
              },
              "@media (max-width: 785px)": {
                fontSize: "0.6rem !important",
                // width:"60px",
                height: "30px",
              },
            }}
            onClick={toggleEdit}
          >
            {edit === true ? "Cancel Edit" : "Edit"}
          </Button>

          <AddRows flag={flag} setFlag={setFlag} data={data} />
        </Stack>
      </Stack>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "none",
        }}
      >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                backgroundColor: "#F2F4FF",
              }}
            >
              <TableRow>
                {columns?.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#F2F4FF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
                <TableCell
                  key="status"
                  align="left"
                  style={{ minWidth: "170px", backgroundColor: "#F2F4FF" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <Loading color="#000" />
              ) : data.rows && data.rows.length > 0 ? (
                data.rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns?.map((column) => {
                         
                          const value = row[column.id];
                          let str = "";
                          if (Array.isArray(value)) {
                            value.map((val) => {
                              str = str + val + ",";
                            });
                            str = str.substring(0, str.length - 1);
                          }

                          if (value === null || value === undefined) {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                <Stack direction="row" alignItems="center">
                                  {value === null
                                    ? "-"
                                    : value === undefined
                                      ? "-"
                                      : value}
                                  {edit && (
                                    <EditRowModal
                                      flag={flag}
                                      setFlag={setFlag}
                                      id={row._id}
                                      masterId={data._id}
                                      column={column.id}
                                      sx={{ marginLeft: "10px" }}
                                    />
                                  )}
                                </Stack>
                              </TableCell>
                            );
                          } else if (
                            column.type === "text" ||
                            column.type === "number"
                          ) {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                <Stack direction="row" alignItems="center">
                                  {value}
                                  {edit && (
                                    <EditRowModal
                                      flag={flag}
                                      setFlag={setFlag}
                                      id={row._id}
                                      masterId={data._id}
                                      column={column.id}
                                      sx={{ marginLeft: "10px" }}
                                    />
                                  )}
                                </Stack>
                              </TableCell>
                            );
                          } else if (column.type === "date") {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                <Stack direction="row" alignItems="center">
                                  {value}
                                  {edit && (
                                    <EditDateModal
                                      flag={flag}
                                      setFlag={setFlag}
                                      id={row._id}
                                      masterId={data._id}
                                      column={column.id}
                                      sx={{ marginLeft: "10px" }}
                                    />
                                  )}
                                </Stack>
                              </TableCell>
                            );
                          } else if (column.type === "time") {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                <Stack direction="row" alignItems="center">
                                  {convertToIST(value)}
                                  {edit && (
                                    <EditTimeModal
                                      flag={flag}
                                      setFlag={setFlag}
                                      id={row._id}
                                      masterId={data._id}
                                      column={column.id}
                                      curValue={value}
                                      sx={{ marginLeft: "10px" }}
                                    />
                                  )}
                                </Stack>
                              </TableCell>
                            );
                          } else if (column.type === "dropdown") {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                <Stack direction="row" alignItems="center">
                                  {value}
                                  {edit && (
                                    <EditDropdownModal
                                      flag={flag}
                                      setFlag={setFlag}
                                      id={row._id}
                                      masterId={data._id}
                                      column={column.id}
                                      curValue={value}
                                      fields={dropdownFieldVals}
                                      sx={{ marginLeft: "10px" }}
                                    />
                                  )}
                                </Stack>
                              </TableCell>
                            );
                          }
                          else if (column.type === "array") {
                            // console.log(value);
                            // const arrStr = "";
                            // value.map((val)=>{
                            //   arrStr = arrStr + val + ',';
                            // })
                            // arrStr = arrStr.substring(0,arrStr.length-1);
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                <Stack direction="row" alignItems="center">
                                  {str}
                                  {edit && (
                                    <EditArrayModal
                                      flag={flag}
                                      setFlag={setFlag}
                                      id={row._id}
                                      masterId={data._id}
                                      column={column.id}
                                      curValue={value}
                                      sx={{ marginLeft: "10px" }}
                                    />
                                  )}
                                </Stack>
                              </TableCell>
                            );
                          }
                          else if (column.type === "boolean") {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key="status"
                                align="left"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        sx={{
                                          "& .MuiButtonBase-root.Mui-checked": {
                                            color: "#484da7",
                                          },
                                          "& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track":
                                            { backgroundColor: "#484da7" },
                                        }}
                                        defaultChecked={row.status}
                                        onChange={() => {
                                          switchChange(row._id, row.status);
                                        }}
                                      />
                                    }
                                    label="Active"
                                  />
                                </FormGroup>
                              </TableCell>
                            );
                          }
                          else {
                            {console.log(column)}
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {value}
                              </TableCell>
                            );
                          }
                        })}
                       
                        <TableCell
                          sx={{
                            border: "none",
                          }}
                          key="status"
                          align="left"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  sx={{
                                    "& .MuiButtonBase-root.Mui-checked": {
                                      color: "#484da7",
                                    },
                                    "& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track":
                                      { backgroundColor: "#484da7" },
                                  }}
                                  defaultChecked={row.status}
                                  onChange={() => {
                                    switchChange(row._id, row.status);
                                  }}
                                />
                              }
                              label="Active"
                            />
                          </FormGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <Typography sx={{ padding: "1rem" }}>
                    No Data Found
                  </Typography>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default MasterTable;
