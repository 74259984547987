import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "./Table";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import BasicSelect from "./dropDown";
import BasicTransport from "./transportDropDown";
import AOS from "aos";
import "aos/dist/aos.css";
import BasicPagination from "./pagination";
import BasicTextFields from "./input";
import BasicModal from "./ScheduleModal";
import UpdateRemark from "./upDateRemark";
import Contect from "./contect";
import Product from "./product"
import { Unstable_Popup as Popup } from "@mui/base/Unstable_Popup";
import axiosInstance from "../../axios/AxiosInstance";
import AuditTrail from "./auditTrail";
import Details from "./details";
import moment from "moment";
import Input from "../../components/input/Input";
import LocationField from "./LocationField";
import PointField from "./PointField";

import Category from "./category";
import { toast } from "react-toastify";
import {
  Modal,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";

const Styles = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  font-family: Open Sans, sans-serif;
  font-size: 15px;
  margin-left: -37px;

  .table {
    border-spacing: 0;
    width: 100%;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th {
      background-color: #ddd !important;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #panding {
      background-color: #dcf9fc;
      color: #0abacc;
    }

    #completed {
      background-color: #d2fcd6;
      color: #04b312;
    }

    #Failed {
      background-color: #fabbbb;
      color: #cc2f2f;
    }

    .th,
    .td {
      margin: 0;
      //  padding: 0.2rem;
      border-bottom: 1px solid #e5e4e2;
      border-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255);
      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background: black;
        width: 3px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
        &.isResizing {
          background: red;
        }
      }
    }

    .rowww {
      display: flex;
      justify-content: center;
    }
  }
`;

const Some = () => {

  const currentDate = moment().valueOf();
  const startDate = moment().subtract(30, "days").valueOf();


  const [buttonState, setbuttonState] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);
  const [flag, setflag] = useState(false);
  const [filter, setfilter] = useState();
  const [pagination, setpagination] = useState({});
  const [count, setCount] = useState(0);
  const [agenct, setAllAgent] = useState([]);
  //stat for hide columan
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [vehicle, setvehicle] = useState([]);
  const [anchor, setAnchor] = React.useState(null);
  const [reloard, setReloard] = useState(1);
  const [dataForTranAge, setDataForTranAge] = useState({});
  const [ticketNumber, seTicketNumber] = useState([]);
  const [AdhocVehicle, seAdhocVehicle] = useState("");
  const [vehicleData, setVehicleData] = useState();

  const [location, setLocation] = useState();
  const [point, setPoint] = useState();

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };







  const newopen = Boolean(anchor);
  const id = newopen ? "simple-popup" : undefined;
  const columnPermission = JSON.parse(localStorage.getItem("wh_tk")).data
    .column_selector;

  let CityNames = JSON.parse(localStorage.getItem("wh_tk")).data.warehouses;
  let checkAdmin = JSON.parse(localStorage.getItem("wh_tk")).data.type;

  let house = [
    {
      _id: "6353dd2fc9383308bab3b855",
      name: "Noida",
      createdAt: "2022-10-22T12:08:15.928Z",
      updatedAt: "2022-10-22T12:08:15.928Z",
      __v: 0,
      city: "Noida",
    },
    {
      _id: "6385d82369d7b11ec8dbce2f",
      name: "Pune",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Pune",
    },
    {
      _id: "639ee663a0a2c3856669742c",
      name: "Mumbai",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Mumbai",
    },
    {
      _id: "639ee6aaa0a2c3856669742e",
      name: "Jodhpur",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Jodhpur",
    },
    {
      _id: "639ee6d6a0a2c38566697430",
      name: "Gurgaon",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Gurgaon",
    },
    {
      _id: "639ee6ffa0a2c38566697431",
      name: "Bangalore",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Bangalore",
    },
    {
      _id: "639ee735a0a2c38566697433",
      name: "Hyderabad",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Hyderabad",
    },
  ];
  if (checkAdmin == "admin") {
    CityNames = house;


  }



  const [city, setCity] = useState(
    CityNames[1]?.name ? CityNames[1]?.name : CityNames[0]?.name
  );
  let cols = [
    {
      Header: "Name",
      width: 130,
      sticky: "left",
      Cell: (data) => {
        return <Details data={data.cell.row.original} />;
      },
    },
    {
      Header: "Ticket ID",
      accessor: "ticketNumber",
      width: 40,
      sticky: "left",
      Cell: (data) => {
        return (
          <p>
            {" "}
            <a target="_blank" href={data.cell.row.original.caseUrl}>
              {" "}
              {data.cell.row.original.ticketNumber}
            </a>{" "}
          </p>
        );
      },
    },
    {
      Header: "Contact",
      width: 50,
      Cell: (data) => {
        return <Contect data={data.cell.row.original} />;
      },
    },
    {
      Header: "product",
      width: 10,
      Cell: (data) => {
        return <Product data={data.cell.row.original} city={city} />;
      },
    },

    {
      Header: "Category",
      accessor: "category",
      width: 50,
      Cell: (data) => {
        return (
          <Category
            data={data.cell.row.original}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Sub Category",
      accessor: "subCategory",
      width: 150,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 138,
    },
    {
      Header: "Sub status",
      accessor: "subStatus",
      width: 138,
    },
    {
      Header: "Scheduled Date",
      accessor: "scheduledDate",
      width: 120,
    },
    {
      Header: "Location",
      accessor: "location",
      width: 121,
      Cell: (data) => {
        return (
          <div style={{ padding: "5px" }}>
            <LocationField data={data} />
          </div >
        );
      },
    },
    {
      Header: "Point",
      accessor: "point",
      width: 121,
      Cell: (data) => {
        return (
          <div style={{ padding: "5px" }}>
            <PointField data={data} />
            {/* <Input some={() => handleUpdateLocationAndPoint(data.cell.row.original?._id)} defaultValue={data.cell.row.original.point} setvalue={setPoint} style={{ height: "30px", fontSize: '12px' }} placeholder="Update Point" /> */}
          </div>
        );
      },
    },
    {
      Header: "Transport",
      accessor: "transport",
      as: vehicle,
      width: 200,
      Cell: (data) => {
        return (
          <BasicTransport
            data={data}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Agent",
      accessor: "agentId",
      width: 150,
      as: agenct,
      Cell: (data) => {
        return (
          <BasicSelect
            agenct={data}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Assigned Date",
      accessor: "createdAt",
      width: 111,
    },

    {
      Header: "Completed On",
      accessor: "deliverDate",
      width: 111,
    },

    {
      Header: "City",
      accessor: "city",
      width: 138,
    },
    {
      Header: "Trips",
      width: 90,
      Cell: (data) => {
        return (
          <BasicModal
            data={data.cell.row.original}
            setReloard={setReloard}
            reloard={reloard}
          />
        );
      },
    },
    {
      Header: "Remarks",
      accessor: "comment",
      width: 150,
      Cell: (data) => {
        return <UpdateRemark data={data.cell.row.original} />;
      },
    },
    {
      Header: "Audit Trail",
      width: 40,
      Cell: (data) => {
        return <AuditTrail data={data.cell.row.original} />;
      },
    },
  ];
  const [columns, setColumns] = useState([]);
  const [toogleCols, setToogleCols] = useState(columnPermission);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const hideColumn = (columnHeader) => {
    setHiddenColumns((prevHiddenColumns) => [
      ...prevHiddenColumns,
      columnHeader,
    ]);
  };

  const showColumn = (columnHeader) => {
    // console.log("first");
    setHiddenColumns((prevHiddenColumns) =>
      prevHiddenColumns.filter((column) => column !== columnHeader)
    );
  };
  const visibleColumns = columns.filter(
    (column) =>
      //  {return column}
      // !hiddenColumns.includes(column.Header) &&  toogleCols[column.Header]
      !hiddenColumns.includes(column.Header) && column.Header
  );

  useEffect(() => {

  }, [hiddenColumns]);
  const getInitialData = async () => {
    setLoading(true);
    const tableRes = await axiosInstance.get(
      `api/ticket?page=${page}&limit=${limit}&city=${"Pune"}`
    );

    if (tableRes.status === 200) {
      setLoading(false);
      setGetData([...tableRes.data.data]);
    }
  };

  // calling for today and tomorrow Time with filter
  const getTodayAndTomorrowData = async (isToday) => {
    isToday ? setbuttonState("Today") : setbuttonState("Tomorrow");
    setLoading(true);
    setPage(1);
    let filters = makeFilterString(filter);
    const now = new Date();
    const yesterday = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    yesterday.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    const start = isToday ? now.getTime() : yesterday.getTime();
    now.setHours(23, 59, 59, 999);
    yesterday.setHours(23, 59, 59, 999);
    const end = isToday ? now.getTime() : yesterday.getTime();
    let url = `type=scheduled&start=${start}&end=${end}`;
    const UserData = JSON.parse(localStorage.getItem("wh_tk")).data;
    const userId = UserData._id;
    let baseUrl = "";

    if (UserData.type === "agent") {
      baseUrl = `api/ticket?page=${page}&limit=${limit}&agentId=${userId}&city=${city}&${filters}`;
    } else {
      baseUrl = `api/ticket?page=${page}&limit=${limit}&city=${city}${filters}`;
    }

    setfilter({ ...filter, type: "scheduled", start: start, end: end });
    // const tableRes = await axiosInstance.get(baseUrl + url);
    // if (tableRes.status === 200) {

    // }
  };

  // create all filter in single string
  function makeFilterString(filter) {
    let base = "&";
    for (const property in filter) {
      base = base + `${property}=${filter[property]}&`;
    }
    return base;
  }

  let getDataOnFlterChange = async () => {
    let base = "";
    for (const property in filter) {
      base = base + `&${property}=${filter[property]}`;
    }
    setLoading(true);
    const tableRes = await axiosInstance.get(
      `api/ticket?page=${page}&limit=${limit}&city=${city || CityNames[0]?.name
      }${base}`
    );

    if (tableRes.status === 200) {
      setLoading(false);
      setGetData([...tableRes.data.data]);
      setCount(tableRes.data.count);
    }
  };

  useEffect(() => {
    if (agenct && vehicle) {
      setColumns(cols);
    }
  }, [agenct, vehicle]);

  const getAgents = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/api/agent?city=${city}&onlyOnlineAgents=true`
      );
      if (response.status === 200) {
        setLoading(false);
        let allAgents = response.data.data;
        allAgents = allAgents.map((agent) => {
          return agent;
        });
        setAllAgent(allAgents);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const getMasterData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/master`);

      if (response.status === 200) {
        setTransportNumber(response?.data?.data)
        setLoading(false);
        let allAgents = response.data.data;
        allAgents = allAgents.map((agent) => {
          return agent;
        });
        let getTransportData = allAgents.filter(
          (items) => items.master == "transport"
        );
        let newArray = getTransportData[0].rows.filter(
          (items) => items.city == city && items.status == true
        );
        setvehicle(newArray);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const setTransportNumber = (data) => {
    let newdata = [];
    data?.filter((item, index) => {
      if (item.master === "transport") {
        item.rows?.filter((e) => {
          if (e?.status) {
            if (e?.city == city) {
              newdata.push(e);
            }
          }
        })
      }
    })
    setVehicleData(newdata)
  }

  useEffect(() => {
    getInitialData();
    AOS.init({ duration: 1500 });
  }, []);

  useEffect(() => {

    getAgents();
    getMasterData();
    getDataOnFlterChange();
  }, [filter, limit, page, city, reloard]);

  const exportData = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: city,
      filters: makeFilterString(filter),
    };
    // console.log(
    //   `${BASE_URL}api/ticket?&city=${Cityname}&download=csv${filters}`
    // );
    var newWindow = window.open(
      `${process.env.REACT_APP_API_URL
      }api/ticket?&city=${city}&download=csv${makeFilterString(filter)}`
    );
    newWindow.passedData = myObj;
  };

  const exportTicket = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: city,
      filters: makeFilterString(filter),
    };
    // console.log(`${BASE_URL}api/trip/export?&city=${Cityname}&download=csv${filters}`);
    var newWindow = window.open(
      `${process.env.REACT_APP_API_URL
      }api/trip/export?&city=${city}&download=csv${makeFilterString(filter)}`
    );
    newWindow.passedData = myObj;
  };
  const exportOder = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: city,
      filters: makeFilterString(filter),
    };
    // console.log(`${BASE_URL}api/trip/export?&city=${Cityname}&download=csv${filters}`);

    var newWindow = window.open(
      filter ? `${process.env.REACT_APP_API_URL}api/ticket/order?&city=${city}&download=csv${makeFilterString(filter)}` : `${process.env.REACT_APP_API_URL}api/ticket/order?&city=${city}&download=csv&type=scheduled&start=${startDate}&end=${currentDate}}`,
    );
    newWindow.passedData = myObj;
  };

  const handleChange = async (e) => {
    setDataForTranAge({ ...dataForTranAge, [e.target.name]: e.target.value })
  }

  const handleKeyDown = async (event) => {
    event.stopPropagation();
    if (event.key == "Enter") {
      seAdhocVehicle(event.target.value)
    }
  }

  const submitmulti = async () => {
    let body = { ...dataForTranAge, ticketNumber }
    if (AdhocVehicle) {
      body.adhoc_vehicle = AdhocVehicle
    }

    try {
      await axiosInstance.post(`/api/ticket/update/updatemulti`, body);
      setReloard(prev => Math.random());
      seAdhocVehicle("")
      setDataForTranAge({})
      toast.success("Update successfull");

    } catch (error) {
      console.log(error.response.data.message);
    }

  }

  return (
    <Styles>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              "@media (max-width: 785px)": {
                fontSize: "0.6rem !important",
                // width:"60px",
                height: "30px",
                marginTop: "-20px",
              },
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              sx={{
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
              onClick={() => {
                setflag(!flag);
              }}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: buttonState == "Today" ? "grey" : "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
              onClick={() => {
                getTodayAndTomorrowData(true);
              }}
            >
              Today
            </Button>
            <Button
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: buttonState == "Tomorrow" ? "grey" : "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
              onClick={() => {
                getTodayAndTomorrowData(false);
              }}
            >
              Tomorrow
            </Button>
            <Button
              aria-describedby={id}
              type="button"
              onClick={handleClick}
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
            >
              Export
            </Button>
            <Popup id={id} open={newopen} anchor={anchor}>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "3px",
                  border: "1px solid black",
                }}
              >
                {" "}
                <MenuItem
                  onClick={() => {
                    exportData();
                    handleClick();
                  }}
                >
                  City
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exportTicket();
                    handleClick();
                  }}
                >
                  Trip
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    exportOder();
                    handleClick();
                  }}
                >
                  Barcode
                </MenuItem>
              </div>
            </Popup>
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
            >
              <SettingsSuggestIcon />
            </Button>
            <Select
              value={city || CityNames[1]?.name}
              style={{
                marginLeft: "3px",
                width: "30%",
                borderColor: "black",
                height: "30px",
              }}
              type="text"
              placeholder="Enter City"
              onChange={(event) => {
                setCity(event.target.value);
                setPage(1);
              }}
            >
              {CityNames?.map((data, index) => {
                return (
                  <MenuItem key={index} value={data?.name}>
                    {data?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Select
              id="demo-select-small"
              onChange={handleChange}
              name="transport"
              label="Transport"
              style={{
                marginLeft: "3px",
                width: "50%",
                borderColor: "black",
                height: "30px",
                minWidth: "80px"
              }}
            >
              <MenuItem
                onKeyDown={(e) => e.stopPropagation()}
                value={null}
              >
                empty
              </MenuItem>

              {vehicle?.map((item) => (
                <MenuItem
                  onKeyDown={(e) => e.stopPropagation()}
                  key={item._id}
                  value={item?.["vehicle number"]}
                >
                  {item["vehicle number"]}
                </MenuItem>
              ))}

              {
                <TextField
                  id="standard-basic"
                  label="Enter Adhoc Vehicle"
                  value={AdhocVehicle}
                  onKeyDown={handleKeyDown}
                />
              }
            </Select>
            <Select
              sx={{
                marginLeft: "3px",
                width: "50%",
                borderColor: "black",
                height: "30px",
                minWidth: "50px"
              }}
              name="agentId"
              id="demo-select-small"
              label="Agent"
              onChange={handleChange}
            >
              <MenuItem onKeyDown={(e) => e.stopPropagation()} value={"empty"}>
                empty
              </MenuItem>
              {agenct?.map((item) => (
                <MenuItem key={item.name} value={item._id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              sx={{
                marginLeft: "5px",
                whiteSpace: "nowrap",
                height: "30px",
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "13px !important",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem !important",
                  // width:"60px",
                  height: "30px",
                },
              }}
              onClick={() => submitmulti()}
            >
              Submit
            </Button>
          </Box>

          <Box sx={{ display: "flex" }}>
            <BasicPagination count={count} limit={limit} setPage={setPage} />
            <Box sx={{ marginTop: "-6px" }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 20 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={limit}
                  onChange={(event) => {
                    setLimit(event.target.value);
                    setPage(1);
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

        {flag ? (
          <div data-aos="fade-right">
            <BasicTextFields vehicleData={vehicleData} filter={filter} setfilter={setfilter} />
          </div>
        ) : null}
        {
          !!getData?.length &&
          <Table
            columns={visibleColumns}
            data={getData}
            open={open}
            setOpen={setOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            setColumns={setColumns}
            hideColumn={hideColumn}
            showColumn={showColumn}
            toogleCols={toogleCols}
            setToogleCols={setToogleCols}
            seTicketNumber={seTicketNumber}
            ticketNumber={ticketNumber}
          />
        }
      </>
    </Styles>
  );
};

export default Some;
