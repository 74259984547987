import React, { useEffect, useRef, useState } from "react";
import { useTable, useBlockLayout, useResizeColumns, useRowSelect } from "react-table";
import moment from "moment-timezone";
import styles from "./new-page.module.css";
import { useSticky } from "react-table-sticky";
import { Modal, Box } from "@mui/material";
import axiosInstance from "../../axios/AxiosInstance";

function convertToIST(utcDateTime) {
  let istDate = moment(utcDateTime).tz("Asia/Kolkata");
  return istDate.format("YYYY-MM-DD HH:mm:ss");
}

const Table = ({
  columns,
  data,
  open,
  setOpen,
  handleOpen,
  handleClose,
  setColumns,
  hideColumn,
  showColumn,
  toogleCols,
  setToogleCols,
  seTicketNumber,
  ticketNumber
}) => {

  const [remark, setRemark] = useState("");
  const firstDivRef = useRef(null);
  const secondDivRef = useRef(null);
  const rowRef = useRef(null);
  const tbodyRef = useRef(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const defaultColumn = {
    minWidth: 100,
    width: 255,
    maxWidth: 600,
  };
  let pandding = [
    "Pickup Pending",
    "Delivery Scheduled",
    "Pending",
    "Order Verified",
    "Pickup Scheduled",
    "Scheduled",
  ];
  let faild = ["Delivery Failed"]
  let completed = ["Pickup Done", "Delivered", "Completed"];
  let colorHaders = ["Status", "Sub Status"];
  for (let i = 0; i < data.length; ++i) {
    if (data[i].scheduledDate) {
      data[i].scheduledDate = convertToIST(data[i].scheduledDate)
      // data[i].scheduledDate = convertToIST(data[i].scheduledDate) 
    }
    if (data[i].deliverDate) {
      data[i].deliverDate = convertToIST(data[i].deliverDate)
    }
    data[i].createdAt = convertToIST(data[i].createdAt);
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
    useSticky,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <input type="checkbox" {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          minWidth: 20,
          width: 20,
          maxWidth: 20,
          sticky: 'left'
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    const selectedTicketIds = Object.keys(selectedRowIds).map(
      (rowId) => rows[rowId]?.original?.ticketNumber
    );
    seTicketNumber(selectedTicketIds)
  }, [selectedRowIds]);

  const handleFirstDivScroll = () => {
    if (secondDivRef.current && secondDivRef.current.scrollLeft) {
      secondDivRef.current.scrollLeft = firstDivRef.current.scrollLeft;
    }
  };

  const handleSecondDivScroll = () => {
    if (firstDivRef.current) {
      firstDivRef.current.scrollLeft = secondDivRef.current.scrollLeft;
    }
  };
  const UserData = JSON.parse(localStorage.getItem("wh_tk")).data;
  const sendColumnData = async (UserData, newColumnsObj) => {
    try {
      const result = await axiosInstance.post("api/user/column/selector", {
        user_id: UserData._id,
        column_selector: newColumnsObj,
      });
      if (result.status === 200) {
        const newUserData = {
          data: { ...UserData, column_selector: newColumnsObj },
          success: true,
        };
        localStorage.setItem("wh_tk", JSON.stringify(newUserData));
        // setCheckedColumns(newColumnsObj);
      }
    } catch (error) {
      alert("Something wrong happend!");
    }
  };
  let togArr = [];
  for (const key in toogleCols) {
    if (Object.hasOwnProperty.call(toogleCols, key)) {
      if (true) {
        togArr.push(
          <div style={{}}>
            <div key={key}>
              <input
                type="checkbox"
                checked={toogleCols[key]}
                onClick={() => {
                  if (toogleCols[key]) {
                    hideColumn(key);
                    setToogleCols((prev) => {
                      // key = !prev[key];
                      sendColumnData(UserData, {
                        ...prev,
                        [key]: !toogleCols[key],
                      });
                      return { ...prev, [key]: !toogleCols[key] };
                    });
                  } else {
                    showColumn(key);
                    setToogleCols((prev) => {
                      sendColumnData(UserData, {
                        ...prev,
                        [key]: !toogleCols[key],
                      });
                      return { ...prev, [key]: !toogleCols[key] };
                    });
                  }
                }}
              />
              <label
                style={{
                  padding: "7px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {key},
              </label>
            </div>
          </div>
        );
      }
    }
  }
  return (
    <div className={` ${styles.tableContainer}`}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Box sx={style}>{togArr}</Box>
        </Box>
      </Modal>

      <div
        {...getTableProps()}
        className={`table ${styles.tablePosi}`}
        ref={firstDivRef}
        onScroll={handleFirstDivScroll}
        style={{
          padding: "0px !important",
          height: "73vh",
        }}
      >
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: "12",
          }}
        >
          {headerGroups.map((headerGroup, index) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className="tr"
              ref={rowRef}
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <div
                  {...column.getHeaderProps()}
                  className={`th ${columnIndex === 0 ? styles.stickyColumn : ""
                    }`}
                >
                  <div style={{ textAlign: "center" }}>
                    {column.render("Header")}
                  </div>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${column.isResizing ? "isResizing" : ""
                      }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} ref={tbodyRef}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr px">
                {row.cells.map((cell, innerIndex) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className="td rowww"
                    // style={
                    //   innerIndex === 0 ? { position: "fixed", left: 0 } : {}
                    // }
                    >
                      {/* {index}
                      {innerIndex} */}
                      {colorHaders.includes(cell.column.Header) ? (
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              {...(pandding?.includes(cell.row.original.status)
                                ? { id: "panding" }
                                : "")}
                              {...(completed?.includes(cell.row.original.status)
                                ? { id: "completed" }
                                : "")}
                              {...(faild?.includes(cell.row.original.status)
                                ? { id: "Failed" }
                                : "")}
                              style={{
                                borderRadius: "20px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "100%"
                              }}
                            >
                              {cell.render("Cell")}{" "}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div style={{ height: "100%", display: 'flex', justifyContent: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              justifyItems: "center",
                            }}>
                            {cell.render("Cell")}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div
        className={styles.scrollablediv}
        onScroll={handleSecondDivScroll}
        ref={secondDivRef}
        // style={{ width: rowRef.current?.offsetWidth }}
      >
        <div style={{ width: rowRef.current?.offsetWidth }}>&nbsp;</div>
      </div> */}
      {/* <div className={styles.scrollabledivY} style={{ overflowY: "scroll" }}>
        <div style={{ height: tbodyRef.current?.offsetHeight }}>&nbsp;</div>
      </div> */}
    </div>
  );
};

export default Table;
