import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axiosInstance from '../../../axios/AxiosInstance';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import Loading from '../../../components/loading/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': {
    width: 330
  }
};

const BasicModal = ({ flag, tableData, setTableData, data, ticketId, getdata, getAgentdata, closeDetail }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const [remark, setRemark] = useState('');

  const handleChange = (event) => {
    setRemark(event.target.value);
  };

  const userid = JSON.parse(localStorage.getItem('wh_tk')).data._id;
  const userData = JSON.parse(localStorage.getItem('wh_tk')).data;

  const handleUpdate = async () => {

    // console.log(date.$d);
    setLoading(true);
    try {

      const body = {
        comment: remark,
        userId: userid
      }

      const res = await axiosInstance.post(`api/ticket/${ticketId}`, body)
      if (res.status === 200) {
        setLoading(false);
        alert("Update successfull");
      }
      else {
        setLoading(false);
        alert("Something went wrong!!");
      }
      // console.log(res);
      if (userData.type === 'agent') {
        getAgentdata();
      }
      else {
        getdata();
      }

      handleClose();
      closeDetail();
    }
    catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong!!");
    }
  }


  useEffect(() => {
  }, [flag])

  return (
    <div>
      <Button variant="contained"
        sx={{
          height: "40px",
          backgroundColor: '#000',
          color: '#fff',
          // marginTop:"10px",
          fontSize: "0.875rem !important",
          '&:hover': {
            backgroundColor: '#000',
            opacity: '0.8'
          }
        }} onClick={handleOpen}>Update</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon sx={{
            position: "absolute",
            right: "-30px",
            top: "-22px",
            cursor: "pointer",
            color: "white",
            '@media (max-width: 400px)': {
              right: "10px",
              top: "10px",
              color: "black",
            },
          }} onClick={() => {
            setRemark('');
            handleClose();
          }} />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Remark
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Remark"
              multiline
              maxRows={4}
              value={remark}
              onChange={handleChange}
              sx={{
                marginTop: "20px",
                width: "100%",
                display: "block",
                '& .MuiInputBase-root': { width: '100%' },
                '& .Mui-focused': { border: '1px solid grey' },
                '& .MuiInputLabel-shrink': { border: 'none', color: 'grey !important' }
              }}
            />
            <Button variant="contained"
              sx={{
                height: "40px",
                backgroundColor: '#000',
                color: '#fff',
                marginTop: "10px",
                fontSize: "0.8rem !important",
                '&:hover': {
                  backgroundColor: '#000',
                  opacity: '0.8'
                }
              }} onClick={() => {
                handleUpdate()
              }}>
              {
                loading === true ?
                  <Loading />
                  :
                  'OK'
              }
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;

