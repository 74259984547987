import React from 'react'
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import AuditChanges from "../../components/audit/AuditTable";
import { Typography } from '@mui/material';
const AuditTrail = ({ data }) => {

    const [state, setState] = React.useState({
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 550 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Divider />
        </Box>
    );

    return (
        <div className="filter">
            <div>
                {['right'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button variant="contained"
                            sx={{
                                whiteSpace: "nowrap",
                                height: "30px",
                                backgroundColor: "#000",
                                color: "#fff",
                                fontSize: "13px !important",
                                "&:hover": {
                                    backgroundColor: "#000",
                                    opacity: "0.8",
                                },
                                "@media (max-width: 785px)": {
                                    fontSize: "0.6rem !important",
                                    // width:"60px",
                                    height: "30px",
                                },
                            }} onClick={toggleDrawer(anchor, true)}>History</Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                            <div style={{ padding: "20px" }}>
                                <Typography sx={{
                                    fontSize: '1.5rem',
                                    fontWeight: '700'
                                }} >Audit Changes</Typography>
                                <AuditChanges auditId={data.auditTrailId} flag={true} /></div>
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default AuditTrail