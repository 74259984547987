import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@mui/system'
import { Chip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Select from "./SelectRole"
import Input from '../../components/input/Input'
import axiosInstance from '../../axios/AxiosInstance'
import { useNavigate } from 'react-router-dom'
import ChipSelect from './ChipEditRole'
import { useLocation } from 'react-router-dom'
import RoleCard from './RoleCard'

const View = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Main = styled.div`
    width:90%;
    height:80%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Cards = styled.div`
    width:50%;
    background-color:white;
    padding:15px;
`
const Button = styled.div`
    height:40px;
    background-color:black;
    color:white;
    border-radius:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:15px;
    margin-top:30px;
    margin-bottom:20px;
    cursor:pointer;
`
const Heading = styled.div`
    fonst-size:20px;
    font-weight:700;
`
const SelDiv = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:20px;
`

const Items = [
    'create',
    'read',
    'delete',
    'update'
];


const EditRole = () => {

    const [permissions, SetPermissions] = useState([]);
    const [allPer, setAllPer] = useState([]);
    const [out, setOut] = useState([]);
    const[name, setName] = useState("");
    const [rolename, setRolename] = useState({});
    const navigate = useNavigate();

    const location = useLocation();
    const {state} = location;
    const {role, id} = state;

    const getdata =  async () => {
        const res = await axiosInstance.get("api/permission");
        // console.log(res); 
        const perarr = role.permissions.map((curr, index) => {
            return role.out[index].name;
        })
        // console.log(perarr);
        const data = res.data.data;
        let arr = [];
        for(let i = 0; i< data?.length; i++)
        {
            if(perarr.includes(data[i].name))
            {
                continue;
            }
            else{
                arr.push({
                    name:data[i].name,
                    permissionId:data[i]._id
                })
            }
        }
        // console.log(arr);
        setAllPer(arr);    
    }

    const handleEdit = async () => {

        var newarr = [];
        for (const key in rolename) {
            const newObj = {permissionId : key};
            rolename[key].map((curr) => {
                newObj[curr] = true;
            })
            newarr.push(newObj);
        }

        const body = {
            name:role.name,
            permissions:newarr,
        }

        const addrole = await axiosInstance.post(`api/role/${id}`, body);

        if(addrole.status === 200)
        {
            alert("Role Edited successfully");
            navigate("/userrole");
        }
        else
        {
            alert("Something went wrong");
        }
    }

    useEffect(() => {
        getdata(); 
        SetPermissions(role.permissions);
        setOut(role.out);

        let rolearr = {};
        role.permissions.map((curr) => {
            const newarr = [];
            if(curr.create === true) newarr.push('create');
            if(curr.delete === true) newarr.push('delete');
            if(curr.read === true) newarr.push('read');
            if(curr.update === true) newarr.push('update');

            rolearr = {...rolearr, [curr.permissionId]:newarr};

        })

        setRolename(rolearr);

    }, [])

  return (
    <View>
        <Main>
            <Cards style={{paddingLeft:"20px", paddingRight:"20px"}}>
                <div style={{width:"100%", display:"flex",justifyContent:"center", alignItems:"center", marginTop:"20px", marginBottom:"20px"}}>
                    <Heading>Edit user role</Heading>
                </div>
                <div style={{marginBottom:"30px"}}>
                    <div style={{fontWeight:"700"}}>Role Name</div>
                    <Input value={role.name} placeholder="Enter Role name" />
                </div>
                <div style={{fontWeight:"700", fontSize:"20px"}}>User permissions</div>
                {
                    permissions.map((curr, index) => {

                        const newarr = [];
                        if(curr.create === true) newarr.push('create');
                        if(curr.delete === true) newarr.push('delete');
                        if(curr.read === true) newarr.push('read');
                        if(curr.update === true) newarr.push('update');

                        return(
                            <SelDiv>
                                <div style={{fontWeight:"500", padding:"10px", border:"1px solid black", borderRadius:"5px"}}>{out[index].name}</div>
                                {/* <Select items={Items}  permissionId={curr._id} rolename={rolename} setRolename={setRolename}  label = "Operations" /> */}
                                <ChipSelect names = {Items} newarr={newarr} permissionId={curr.permissionId} rolename={rolename} setRolename={setRolename} />
                            </SelDiv>
                        );
                    })
                }
                {
                    allPer.map((curr) => {
                        const newarr = [];
                        // console.log(curr.name);
                        return(
                            <SelDiv>
                                <div style={{fontWeight:"500", padding:"10px", border:"1px solid black", borderRadius:"5px"}}>{curr.name}</div>
                                <ChipSelect names = {Items} newarr={newarr} permissionId={curr.permissionId} rolename={rolename} setRolename={setRolename} />
                            </SelDiv>
                        )
                    })
                }
                {/* <SelDiv>
                    <div style={{fontWeight:"500", padding:"10px", border:"1px solid black", borderRadius:"5px"}}>Task</div>
                    <Select items={Items} label = "Operations" />
                </SelDiv>
                <SelDiv>
                    <div style={{fontWeight:"500", padding:"10px", border:"1px solid black", borderRadius:"5px"}}>Rangermaster1</div>
                    <Select items={Items} label = "Operations" />
                </SelDiv> */}
                <div style={{width:"100%", display:"flex",justifyContent:"center", alignItems:"center"}}>
                    <Button onClick={handleEdit}>
                        <AddIcon />
                        &nbsp;
                        Confirm Edit 
                    </Button>
                </div>
            </Cards>
        </Main>
    </View>
  )
}

export default EditRole