import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import axiosInstance from "../../axios/AxiosInstance";
import Loading from "../../components/loading/Loading";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { maxWidth } from "@mui/system";
import Pagination from "@mui/material/Pagination";
// import MessageEditModal from "./MessageEditModal";
import moment from "moment/moment";
const columns = [
  {
    id: "ticketNumber",
    label: "ticket Number",
    minWidth: 150,
  },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 150,
  },
  {
    id: "customerEmail",
    label: "Customer Email",
    minWidth: 150,
  },
  {
    id: "city",
    label: "City",
    minWidth: 150,
  },
  {
    id: "oldData",
    label: "Old scheduledDate",
    minWidth: 150,
  },
  {
    id: "newData",
    label: "New scheduledDate",
    minWidth: 150,
  },
  {
    id: "userName",
    label: "User Name",
    minWidth: 150,
  },
  {
    id: "createdAt",
    label: "created At",
    minWidth: 150,
  },
];

const AuditTrailTable = ({
  messages,
  setMessages,
  loading,
  setLoading,
  createData,
  city,
  setTotalPage,
  totalPage,
  start,
  end,
  filters,
  isDateFilterApplied,
}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [update, setUpdate] = useState(false);
  const [isMessage, setIsmessage] = useState(false);
  const [flag, setFlag] = useState(true);

  const navigate = useNavigate();

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getMessages = async () => {
    try {
      setLoading(true);
      let response;
      if (isDateFilterApplied) {
        response = await axiosInstance.get(
          `/api/ticket/auditTrall?page=${page}&limit=${limit}&city=${city}&type=scheduled&start=${start}&end=${end}`
        );
      } else {
        response = await axiosInstance.get(
          `/api/ticket/auditTrall?page=${page}&limit=${limit}&city=${city}`
        );
      }
      if (response.status === 200) {
        setLoading(false);
        let allMessages = response.data.data.docs;
        setTotalPage(response.data.data.totalPages);
        allMessages = allMessages.map((message) => {
          return createData(message);
        });
        // console.log(filters);
        setMessages(allMessages);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getMessages();
  }, [flag, page]);
  useEffect(() => {}, [messages]);

  return (
    <div style={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "none",
        }}
      >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                backgroundColor: "#F2F4FF",
              }}
            >
              <TableRow>
                {columns?.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#F2F4FF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <Loading color="#000" />
              ) : messages && messages.length > 0 ? (
                messages
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell
                          sx={{
                            border: "none",
                          }}
                          key={row.id}
                          align={row.align}
                        >
                          {row?.ticketNumber}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                          }}
                          key={row.id}
                          // align={row.align}
                        >
                          {row?.firstName}
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "none",
                          }}
                          key={row.id}
                          // align={row.align}
                        >
                          {row?.email}
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "none",
                          }}
                          key={row.id}
                          // align={row.align}
                        >
                          {row?.city}
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "none",
                            // display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                          key={row.id}
                          // align={row.align}
                        >
                          {row?.result.auditTrail?.oldData?.scheduledDate
                            ? moment(
                                row?.result?.auditTrail?.oldData?.scheduledDate
                              ).format("MMMM Do YYYY, h:mm a")
                            : null}
                          <br />
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "none",
                            // display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                          key={row.id}
                          // align={row.align}
                        >
                          {row?.result.auditTrail?.newData?.scheduledDate
                            ? moment(
                                row?.result.auditTrail?.newData?.scheduledDate
                              ).format("MMMM Do YYYY, h:mm a")
                            : null}
                          <br />
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "none",
                          }}
                          key={row.id}
                          // align={row.align}
                        >
                          {row?.result?.auditTrail?.userId?.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                          }}
                          key={row.id}
                          // align={row.align}
                        >
                          {moment(row?.result?.auditTrail?.createdAt).format(
                            "MMMM Do YYYY, h:mm a"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <Typography sx={{ padding: "1rem" }}>
                    No Data Found
                  </Typography>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          <Pagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onChange={(e, value) => setPage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default AuditTrailTable;
