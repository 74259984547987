import React from "react";
import styled from "styled-components";
import MobileTableRow from "./MobileTableRow";
import { Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Loading from "../../../components/loading/Loading";

const MobileTable = ({
  rows,
  openDetails,
  page,
  handleChangePageMobile,
  lastPage,
  finalPage,
  start,
  end,
  totalPage,
  loading,
}) => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{
        marginTop: "20px",
      }}
      spacing={1}
    >
      {loading === true ? (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            width: "100%",
          }}
        >
          <Loading color="black" />
        </Stack>
      ) : rows && rows.length > 0 ? (
        rows.map((row) => {
          // console.log("ASDFGrow", row);
          return <MobileTableRow row={row} openDetails={openDetails} />;
        })
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            width: "100%",
          }}
        >
          No Data Found
        </Stack>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        spacing={1}
        sx={{
          width: "100%",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
            }}
          >{`${start} - ${end} of ${totalPage}`}</Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{
            marginTop: "10px",
          }}
        >
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              handleChangePageMobile(page - 1);
            }}
            disabled={lastPage}
          >
            <ArrowBackIosIcon
              sx={{
                color: lastPage ? "" : "black",
              }}
              fontSize="inherit"
            />
          </IconButton>

          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              handleChangePageMobile(page + 1);
            }}
            disabled={finalPage}
          >
            <ArrowForwardIosIcon
              sx={{
                color: finalPage ? "" : "black",
              }}
              fontSize="inherit"
            />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MobileTable;
