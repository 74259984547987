
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Input from '../../../components/input/Input';
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Select, MenuItem } from "@mui/material";

const FilterModal = ({ setOpen, open, setFilter, reload, setReload, setReset, reset }) => {
    const handleClose = () => setOpen(false);
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Category, setCategory] = useState("");
    const [SubCategory, setSubCategory] = useState("");
    const [Status, setStatus] = useState("");
    const [TicketId, setTicketId] = useState("");

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleChangeSubCategory = (event) => {
        setSubCategory(event.target.value);
    };

    const resetNew = () => {
        handleClose()
        setReset(++reset)
        
    }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    let OnClickCheck = async () => {
        let obj = {}
        if (Name) {
            let some = "name" + "$contains"
            obj[some] = Name
        }
        if (Email) {
            let some = "email" + "$contains"
            obj[some] = Email
        }
        if (Category) {
            let some = "category" + "$contains"
            obj[some] = Category
        }
        if (SubCategory) {
            let some = "subCategory" + "$contains"
            obj[some] = SubCategory
        }
        if (Status) {
            let some = "status" + "$contains"
            obj[some] = Status
        }
        if (TicketId) {
            let some = "ticketNumber" + "$contains"
            obj[some] = TicketId
        }

        let base = "&";
        for (const property in obj) {
            base = base + `${property}=${obj[property]}&`;
        }

        setFilter(base)
        setReload(++reload)
        handleClose()
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction="column">
                    <Stack
                        direction="column"
                        spacing={3}
                        sx={{
                            marginBottom: "50px",
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    width: "20%",
                                }}
                            >
                                Name
                            </Typography>
                            <Input
                                value={Name}
                                setvalue={setName}
                                style={{ width: "70%" }}
                                type="text"
                                placeholder="Enter name"
                            />
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    width: "20%",
                                }}
                            >
                                Email
                            </Typography>
                            <Input
                                value={Email}
                                setvalue={setEmail}
                                style={{ width: "70%" }}
                                type="text"
                                placeholder="Enter email"
                            />
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    width: "20%",
                                }}
                            >
                                Category
                            </Typography>
                            <Select
                                value={Category}
                                style={{ width: "70%", borderColor: "black" }}
                                type="text"
                                placeholder="Enter City"
                                onChange={handleChangeCategory}
                            >
                                <MenuItem value={"Service Request"}>
                                    Service Request
                                </MenuItem>
                                <MenuItem value={"Order"}>Order</MenuItem>
                            </Select>
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    width: "20%",
                                }}
                            >
                                Sub Category
                            </Typography>
                            <Select
                                value={SubCategory}
                                style={{ width: "70%", borderColor: "black" }}
                                type="text"
                                placeholder="Enter City"
                                onChange={handleChangeSubCategory}
                            >
                                <MenuItem value={"Pickup and Refund"}>
                                    Pickup and Refund
                                </MenuItem>
                                <MenuItem value={"Replace"}>Replace</MenuItem>
                                <MenuItem value={"New - Rental"}>New - Rental</MenuItem>
                                <MenuItem value={"Repair"}>Repair</MenuItem>
                                <MenuItem value={"Relocation"}>Relocation</MenuItem>
                                <MenuItem value={"New - Buy"}>New - Buy</MenuItem>
                                <MenuItem value={"Offline - Rental"}>
                                    Offline - Rental
                                </MenuItem>
                                <MenuItem value={"Repair"}>Repair</MenuItem>
                                <MenuItem value={"Upgrade"}>Upgrade</MenuItem>
                                <MenuItem value={"Installation"}>Installation</MenuItem>
                            </Select>
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    width: "20%",
                                }}
                            >
                                Status
                            </Typography>
                            <Select
                                value={Status}
                                style={{ width: "70%", borderColor: "black" }}
                                type="text"
                                placeholder="Enter City"
                                onChange={handleChange}
                            >
                                <MenuItem value={"Pickup Pending"}>Pickup Pending</MenuItem>
                                <MenuItem value={"Pending"}>Pending</MenuItem>
                                <MenuItem value={"Order Verified"}>Order Verified</MenuItem>
                                <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                                <MenuItem value={"Delivered"}>Delivered</MenuItem>
                                <MenuItem value={"Completed"}>Completed</MenuItem>
                                <MenuItem value={"Pickup Done"}>Pickup Done</MenuItem>
                                <MenuItem value={"Pickup Scheduled"}>
                                    Pickup Scheduled
                                </MenuItem>
                                <MenuItem value={"Delivery Scheduled"}>
                                    Delivery Scheduled
                                </MenuItem>
                            </Select>
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    width: "20%",
                                }}
                            >
                                Ticket Id
                            </Typography>
                            <Input
                                value={TicketId}
                                setvalue={setTicketId}
                                style={{ width: "70%" }}
                                type="text"
                                placeholder="Enter Ticket Id"
                            />
                        </Stack>


                    </Stack>
                </Stack>

                <Button
                    variant="contained"
                    sx={{
                        whiteSpace: "nowrap",
                        height: "40px",
                        backgroundColor: "#000",
                        color: "#fff",
                        fontSize: "0.8rem !important",
                        "&:hover": {
                            backgroundColor: "#000",
                            opacity: "0.8",
                        },
                    }}
                    onClick={OnClickCheck}
                >
                    OK
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        height: '40px',
                        backgroundColor: '#000',
                        color: '#fff',
                        fontSize: "0.8rem!important",
                        marginLeft: "10px",
                        '&:hover': {
                            backgroundColor: '#000',
                            opacity: '0.8'
                        }
                    }}
                    onClick={resetNew}>
                    Reset Filters
                </Button>
            </Box>
        </Modal>
    )
}


export default FilterModal