import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


function Loading({ width, height, color, style }) {
    return (
        // <Box sx={{ display: 'flex', justifyContent:"center", width:"100%" }}>
            <CircularProgress  style={{...style, color: color }} />
        // </Box>
    )
}

export default Loading