import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Analytics from "./pages/analytics/Analytics";
import Manager from "./pages/manager/Manager";
import Agent from "./pages/agent/Agent";
import Customers from "./pages/customers/Customers";
import Home from "./pages/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import AddAgent from "./pages/agent/AddAgent";
import AddManager from "./pages/manager/AddManager";
import AddCustomer from "./pages/customers/AddCustomer";
import UserRole from "./pages/userrole/UserRole";
import AddRole from "./pages/userrole/AddRole";
import MasterData from "./pages/master/MasterData";
import AddMaster from "./pages/master/AddMaster";
import Login from "./pages/login/Login";
import City from "./pages/city/City";
import AddData from "./pages/dashboard/AddData";
import UpdateRoleother from "./components/updateRoleother/UpdateRoleother";
import AddRoleother from "./components/updateRoleother/AddRoleother";
import EditRole from "./pages/userrole/EditRole";
import Nopermission from "./pages/Nopermission";
import MasterTable from "./pages/master/MasterTable";
import Forgot from "./pages/forgotPass/Forgot";
import Slot from "./pages/slot/Slot";
import Message from "./pages/messages/Message";
import AddMessage from "./pages/messages/AddMessage";
import AuditTrail from "./pages/auditTrail/auditTrail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPage from "./pages/new-page/new-page";
import TatPage from "./pages/tatpage/TatPage";
import OderIdData from "./pages/oderIdData/OderIdData";
import MobileView from "./pages/mobileView/MobileView";
import TaskScreen from "./pages/mobileView/TaskScreen/TaskScreen";
import DetailScreen from "./pages/mobileView/DetailScreen/DetailScreen";
import ForgotPassword from "./pages/mobileView/Forgotpassword/forgotPassword";
import BarcodeCount from "./pages/barcodeCount/barcodeCount";
import VehicleDuration from "./pages/VehicleDuration"

function App() {
  let checkRole =
    JSON.parse(localStorage.getItem("wh_tk"))?.data?.type != "agent";
  console.log(checkRole);
  return (
    <div className="App">
      <ToastContainer autoClose={2000} theme="dark" />

      <BrowserRouter>
        <Routes>
          {checkRole && <Route path="/" element={<Home />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/manager" element={<Manager />} />
            <Route path="/agent" element={<Agent />} />
            <Route path="/agent/add_agent" element={<AddAgent />} />
            <Route path="/message" element={<Message />} />
            <Route path="/message/add_message" element={<AddMessage />} />
            <Route path="/agent/add_manager" element={<AddManager />} />
            <Route path="/customers/add_customers" element={<AddCustomer />} />
            <Route path="/userrole" element={<UserRole />} />
            <Route path="/userrole/add_role" element={<AddRole />} />
            <Route path="/masterdata" element={<MasterData />} />
            <Route path="/mastertable" element={<MasterTable />} />
            <Route path="/masterdata/add_master" element={<AddMaster />} />
            <Route path="/city/:name" element={<City />} />
            <Route path="/dashboard/add_data" element={<AddData />} />
            <Route path="/updateroleother" element={<UpdateRoleother />} />
            <Route path="updateroleother/add_role" element={<AddRoleother />} />
            <Route path="/editrole" element={<EditRole />} />
            <Route path="/nopermission" element={<Nopermission />} />
            <Route path="/slot" element={<Slot />} />
            <Route path="/auditTrail" element={<AuditTrail />} />
            <Route path="/task-page" element={<NewPage />} />
            <Route path="/tat-page" element={<TatPage />} />
            <Route path="/order-page" element={<OderIdData />} />
            <Route path="/barcode-page" element={<BarcodeCount />} />
            <Route path="/vehicle-duration" element={<VehicleDuration />} />
          </Route>}
          <Route path="/forGotPAssword" element={<MobileView />} />
          <Route path="/forgot-page" element={<ForgotPassword />} />
          <Route path="/detailscreen" element={<DetailScreen />} />
          <Route path="/taskscreen" element={<TaskScreen />} />
          <Route path="/login-page" element={<MobileView />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
