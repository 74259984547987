import React from 'react'
import styled from 'styled-components'
import { Box } from '@mui/system'
import { Chip } from '@mui/material'
import { Col } from 'react-grid-system';
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import pairs from './MasterDatapairs'

const Cards = styled.div`
    width:100%;
    background-color:white;
    padding:15px;
`
const Heading = styled.div`
    fonst-size:20px;
    font-weight:700;
    padding:10px;
    background-color:black;
    color:white;
    border-radius:5px;
`

const items = [
  {
    value: 'transport',
    text: "Transport"
  },
  {
    value: 'sms',
    text: "SMS"
  },
  {
    value: 'working_hours',
    text: "Working Hours"
  },
  {
    value: 'weekly_off',
    text: "Weekly Off"
  },
  {
    value: 'daily_limit',
    text: "Daily Limit"
  }
]



const MasterCard = ({ data }) => {

  const navigate = useNavigate();

  // console.log(data);

  const openMasterTable = (data) => {
    navigate('/mastertable', { state: { data: data } });
  }


  return (
    <Col style={{ textAlign: "center", padding: "0", margin: "0.2rem", display:'flex', flexGrow:'1' }} sm={3.9}>
      <Cards>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
          <Heading>
            {pairs[data.master]}
            {/* {data.master} */}
          </Heading>
        </div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: "center" }}>
          {
            data.fields.map((curr, index) => {
              return (
                <Chip style={{ backgroundColor: "white", border: "1px solid black", borderRadius: "5px", cursor: "pointer" }} key={index} label={curr.val} />
              )
            })
          }
        </Box>
        <div style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
          <Button variant="contained"
            sx={{
              height: "40px",
              backgroundColor: '#000',
              color: '#fff',
              marginTop: "20px",
              fontSize: "0.8rem !important",
              '&:hover': {
                backgroundColor: '#000',
                opacity: '0.8'
              }
            }}
            onClick={() => { openMasterTable(data) }}
          >
            Edit
          </Button>
        </div>
      </Cards>
    </Col>
  )
}

export default MasterCard