// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @tailwind base;
@tailwind components;
@tailwind utilities;
 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#Custom-Select>.css-1nmdiq5-menu {
  z-index: 9876545678987654567 !important;
}

#Custom-Select>.css-13cymwt-control {
  border: none !important;
  border-bottom: 1px solid black !important;
}

.Mui-selected {
  background-color: #000 !important;
  color: #fff !important;
}

#menu- {
  z-index: 99999999999999999999 !important;
}

.DatePickerTable {
  width: 37ch !important;
  padding: 5px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

#DataPickerNewPage {
  width: 100% !important;
}

#Select-Filter-option {
  padding: 4px !important;
  padding-left: 10px !important;

}

.whiteSpace-nowrap {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;EAGE;;AAEF;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;EACE,iCAAiC;EACjC,sBAAsB;AACxB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,6BAA6B;;AAE/B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* @tailwind base;\n@tailwind components;\n@tailwind utilities;\n */\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n#Custom-Select>.css-1nmdiq5-menu {\n  z-index: 9876545678987654567 !important;\n}\n\n#Custom-Select>.css-13cymwt-control {\n  border: none !important;\n  border-bottom: 1px solid black !important;\n}\n\n.Mui-selected {\n  background-color: #000 !important;\n  color: #fff !important;\n}\n\n#menu- {\n  z-index: 99999999999999999999 !important;\n}\n\n.DatePickerTable {\n  width: 37ch !important;\n  padding: 5px !important;\n}\n\n.react-datepicker-wrapper {\n  width: 100%;\n}\n\n#DataPickerNewPage {\n  width: 100% !important;\n}\n\n#Select-Filter-option {\n  padding: 4px !important;\n  padding-left: 10px !important;\n\n}\n\n.whiteSpace-nowrap {\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
