import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "./Home.css";
import { Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import wh_analytics_img from "../assets/wh_analytics_img.png";
import wh_analytics_img_grey from "../assets/wh_analytics_img_grey.png";
import wh_city_img from "../assets/wh_city_img.png";
import wh_customers_img from "../assets/wh_customers_img.png";
import wh_dashboard_img from "../assets/wh_dashboard_img.png";
import wh_manager_img from "../assets/wh_manager_img.png";
import wh_support_agent_img from "../assets/wh_support_agent_img.png";
import wh_logout_img from "../assets/wh_logout_img.png";
import wh_settings_img from "../assets/wh_settings_img.png";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const drawerWidth = 240;
let checkRole =
  JSON.parse(localStorage.getItem("wh_tk"))?.data?.type == "admin";
console.log("checkRole", checkRole);

const tabItems = [
  {
    name: "Dashboard",
    image: wh_dashboard_img,
    key: "dashboard",
    show: false,
  },
  {
    name: "Analytics",
    image: wh_analytics_img_grey,
    key: "analytics",
    show: false,
  },
  {
    name: "Customers",
    image: wh_customers_img,
    key: "customers",
    show: false,
  },
  {
    name: "Manager",
    image: wh_manager_img,
    key: "manager",
    show: false,
  },
  {
    name: "Agent",
    image: wh_support_agent_img,
    key: "agent",
    show: false,
  },
  {
    name: "Message",
    image: wh_analytics_img_grey,
    key: "message",
    show: true,
  },
  {
    name: "Slot",
    image: wh_analytics_img_grey,
    key: "slot",
    show: false,
  },
  {
    name: "Manage Ticket",
    image: wh_dashboard_img,
    key: "new page",
    show: true,
  },
  {
    name: "Check Tat",
    image: wh_analytics_img_grey,
    key: "tat page",
    show: true,
  },
  {
    name: "Order Analytics",
    image: wh_analytics_img_grey,
    key: "order page",
    show: true,
  },
  {
    name: "Barcode Analytics",
    image: wh_analytics_img_grey,
    key: "Barcode page",
    show: true,
  },
  {
    name: "Vehicle Duration",
    image: wh_analytics_img_grey,
    key: "vehicle-time",
    show: true,
  },
];

const tabDownItems = [
  {
    name: "Logout",
    image: wh_logout_img,
  },
];
if (checkRole) {
  tabDownItems.unshift({
    name: "Settings",
    image: wh_settings_img,
  })
}
const settingItems = [
  {
    name: "User roles",
    key: "user_roles",
    show: true,
  },
  {
    name: "Master Data",
    key: "master_data",
    show: false,
  },
];

const cityItems = [
  {
    name: "Noida",
    show: false,
  },
  {
    name: "Hyderabad",
    show: false,
  },
  {
    name: "Bangalore",
    show: false,
  },
  {
    name: "Pune",
    show: false,
  },
  {
    name: "Mumbai",
    show: false,
  },
  {
    name: "Gurgaon",
    show: false,
  },
  {
    name: "Jodhpur",
    show: false,
  },
];

function Home(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [cities, setCities] = useState(cityItems);
  const [settings, setSettings] = useState(settingItems);
  const [tabs, setTabs] = useState(tabItems);
  const [state, setState] = useState({
    showCity: false,
    showSettings: false,
    userRole: "",
  });
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigate = (name) => {
    console.log("name", name)
    switch (name) {
      case "Dashboard":
        navigate("/");
        break;
      case "Analytics":
        navigate("/analytics");
        break;
      case "Customers":
        navigate("/customers");
        break;
      case "Manager":
        navigate("/manager");
        break;
      case "Agent":
        navigate("/agent");
        break;
      case "Message":
        navigate("/message");
        break;
      case "Slot":
        navigate("/slot");
        break;
      case "Manage Ticket":
        navigate("/task-page");
        break;
      case "Check Tat":
        navigate("/tat-page");
        break;
      case "Order Analytics":
        navigate("/order-page");
        break;
      case "Barcode Analytics":
        navigate("/barcode-page");
        break;
      case "Vehicle Duration":
        navigate("/vehicle-duration");
        break;
      default:
        break;
    }
  };

  const handleOnClick = (name) => {
    switch (name) {
      case "Settings":
        setState({ ...state, showSettings: !state.showSettings });
        setMobileOpen(true);
        break;
      case "Logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  const [flag, setFlag] = useState(false);

  const handleSettingNavigate = (ev, flag, setFlag) => {
    const text = ev.target.outerText;
    if (text === "User roles") {
      navigate("/userrole", { state: { flag, setFlag } });
    } else if (text === "Master Data") {
      navigate("/masterdata");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("wh_tk");
    navigate("/login", { replace: true });
    document.location.reload();
  };

  const handleCityNavigate = (name) => {
    handleDrawerToggle();
    switch (name) {
      case "Noida":
      case "Hyderabad":
      case "Bangalore":
      case "Pune":
      case "Mumbai":
      case "Gurgaon":
      case "Jodhpur":
        navigate(`/city/${name}`, { state: { Cityname: name } });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const authData = localStorage.getItem("wh_tk");
    if (!authData) {
      navigate("/login", { replace: true });
      return;
    }

    const userData = JSON.parse(authData).data;
    setState({ ...state, userRole: userData.type });

    const outArr = userData.out;
    if (userData?.role?.length === 0) {
      navigate("./nopermission");
      return;
    }

    const userPermissions = userData?.role[0]?.permissions;
    const updatedTabItems = [...tabItems];
    const updatedSettingItems = [...settingItems];
    const updatedCityItems = [...cityItems];

    userPermissions.forEach((curr, index) => {
      if (curr.read === true) {
        updatedTabItems.forEach((tabItem) => {
          if (tabItem.key === outArr[index].key) {
            tabItem.show = true;
          }
        });

        updatedSettingItems.forEach((settingItem) => {
          if (settingItem.key === outArr[index].key) {
            settingItem.show = true;
          }
        });
      }
    });

    if (userData.type === "admin") {
      updatedCityItems.forEach((cityItem) => {
        cityItem.show = true;
      });
    } else if (userData.type === "manager") {
      userData.warehouses.forEach((curr) => {
        const city = curr.city;
        updatedCityItems.forEach((cityItem) => {
          if (cityItem.name === city) {
            cityItem.show = true;
          }
        });
      });
    } else if (userData.type === "agent") {
      const cities = userData.warehouses.map((house) => house.city);
      updatedCityItems.forEach((cityItem) => {
        if (cities.includes(cityItem.name)) {
          cityItem.show = true;
        }
      });
    }

    setTabs(updatedTabItems);
    setSettings(updatedSettingItems);
    setCities(updatedCityItems);
  }, [flag]);

  const drawerWidthMobile = 240;
  const drawerWidthDesktop = 60;
  const drawer = (
    <div
      style={{
        height: "100vh",
        borderRight: "1px solid #f3f3f3",
        overflowX: "hidden",
      }}
      className="sidebar-div"
    >
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            cursor: "pointer",
            display: mobileOpen ? "block" : "none",
          }}
        >
          <Typography
            sx={{
              color: "#EF4130",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            {state.userRole ? state.userRole : "ADMIN"} ACCOUNT
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              color: "#EF4130",
              fontWeight: "700",
              border: "1.20029px solid #F3F3F3",
              borderRadius: "50%",
              boxShadow: "0px 0px 4.80116px 2.40058px rgba(71, 91, 232, 0.04)",
              marginLeft: "0.5rem",
              display: mobileOpen ? "none" : "block",
            }}
          />
        </Stack>
      </Toolbar>
      {/* <Divider /> */}
      <List sx={{ width: mobileOpen ? drawerWidthMobile : drawerWidthDesktop }}>
        {tabs?.map((item, index) => {
          if (item.show === true) {
            return (
              <>
                <ListItem
                  key={item.name + index}
                  disablePadding
                  sx={{
                    width: "90%",
                    margin: "0rem auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: "50px",
                      "&:hover": {
                        backgroundColor: "#000!important",
                        color: "#fff",
                      },
                    }}
                    onClick={() => handleNavigate(item.name)}
                    className={
                      state.showCity
                        ? "wh_listItemButton wh_tab_active"
                        : "wh_listItemButton"
                    }
                  >
                    <Tooltip title={item.name}>
                      <ListItemIcon
                        sx={{
                          minWidth: "36px",
                        }}
                      >
                        <img src={item.image} className="" alt="tab img" />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={item.name}
                      sx={{
                        display: mobileOpen ? "block" : "none", // hide text if mobileOpen is false
                      }}
                    />
                    {item.name === "City" && (
                      <KeyboardArrowDownIcon
                        sx={{
                          color: "#000",
                          fontWeight: "700",
                          border: "1.20029px solid #F3F3F3",
                          borderRadius: "50%",
                          boxShadow:
                            "0px 0px 4.80116px 2.40058px rgba(71, 91, 232, 0.04)",
                          marginLeft: "0.5rem",
                          display: mobileOpen ? "block" : "none",
                        }}
                        className={
                          state.showCity
                            ? "wh_downArrawIcon wh_icon_rotate"
                            : "wh_downArrawIcon"
                        }
                      />
                    )}
                  </ListItemButton>

                </ListItem>
                {item.name === "City" &&
                  state.showCity &&
                  cities.map((item, index) => {
                    if (item.show === true) {
                      return (
                        <ListItem
                          key={item.name + index}
                          disablePadding
                          sx={{
                            width: "90%",
                            margin: "0rem auto",
                          }}
                          onClick={() => {
                            handleCityNavigate(item.name);
                          }}
                        >
                          <ListItemButton
                            sx={{
                              borderRadius: "50px",
                              "&:hover": {
                                backgroundColor: "#fff!important",
                              },
                            }}
                          >
                            <ListItemText
                              primary={item.name}
                              sx={{
                                display: mobileOpen ? "block" : "none", // hide text if mobileOpen is false
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  })}
              </>
            );
          }
        })}
        <Stack
          sx={{
            marginTop: "5rem",
          }}
        >
          {tabDownItems.map((item, index) => (
            <>
              <ListItem
                key={item.name + index}
                disablePadding
                sx={{
                  width: "90%",
                  margin: "0rem auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "50px",
                    "&:hover": {
                      backgroundColor: "#000!important",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleOnClick(item.name)}
                  className="wh_listItemButton"
                >
                  <Tooltip title={item.name}>
                    <ListItemIcon
                      sx={{
                        minWidth: "36px",
                      }}
                    >
                      <img src={item.image} className="" alt="tab img" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={item.name}
                    sx={{
                      display: mobileOpen ? "block" : "none", // hide text if mobileOpen is false
                    }}
                  />
                  {item.name === "Settings" && checkRole && (
                    <KeyboardArrowDownIcon
                      sx={{
                        color: "#000",
                        fontWeight: "700",
                        border: "1.20029px solid #F3F3F3",
                        borderRadius: "50%",
                        boxShadow:
                          "0px 0px 4.80116px 2.40058px rgba(71, 91, 232, 0.04)",
                        marginLeft: "0.5rem",
                        display: mobileOpen ? "block" : "none",
                      }}
                      className={
                        state.showSettings
                          ? "wh_downArrawIcon wh_icon_rotate"
                          : "wh_downArrawIcon"
                      }
                    />
                  )}
                </ListItemButton>
              </ListItem>
              {item.name === "Settings" &&
                state.showSettings && checkRole &&
                settings.map((item, index) => {
                  if (item.show === true) {
                    return (
                      <ListItem
                        key={item.name + index}
                        disablePadding
                        sx={{
                          width: "90%",
                          margin: "0rem auto",
                        }}
                        onClick={(event) => {
                          handleSettingNavigate(event, flag, setFlag);
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: "50px",
                            "&:hover": {
                              backgroundColor: "#fff!important",
                            },
                          }}
                        >
                          <ListItemText
                            primary={item.name}
                            sx={{
                              display: mobileOpen ? "block" : "none", // hide text if mobileOpen is false
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  }
                })}
            </>
          ))}
        </Stack>
      </List>
    </div>
  );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          flex: "0 1 auto",
          width: `calc(100% - ${mobileOpen ? drawerWidthMobile : drawerWidthDesktop
            }px)`,
          zIndex: "100",
        }}
        className="warehouse_appbar"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {state.userRole == "admin" ? "Admin" : state.userRole} Account
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: mobileOpen ? drawerWidthMobile : drawerWidthDesktop,
          },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="permanent"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          width: `calc(100% - ${mobileOpen ? drawerWidthMobile : drawerWidthDesktop
            }px)`,
          mt: 6,
          pl: 3,
          pt: 3,
          overflow: "hidden",
          "@media (max-width: 900px)": {
            marginTop: "56px",
          },
          "@media (max-width: 600px)": {
            p: 0,
          },
        }}
        style={{
          marginLeft: `${mobileOpen ? drawerWidthMobile : drawerWidthDesktop
            }px`,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Home;
