import React, {useState} from 'react'
import styled from 'styled-components'
import axiosInstance from '../../axios/AxiosInstance'

const InputBox = styled.input`
    width:112px;
    height:40px;
    padding:10px;
    font-size:18px;
`

const Input = ({id,name,style, placeholder, value, handleChange, type}) => {

  return (
    <InputBox id={id} name={name} type={type} multiple style={style} placeholder = {placeholder} value = {value} onChange={handleChange}/>
  )
}

export default Input