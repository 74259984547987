import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from "../../components/input/Input";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
// import Data from "./Data";
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axios/AxiosInstance';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const BasicModal = ({flag, setFlag, data}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

    const [val, setVal] = useState("");
    const [datalist, setList] = useState([]);
    const [Data , setData] = useState([]);

    const handleClick = () => {
        if(val !== "")
        {
            const newarr = [...datalist];
            newarr.push(val);
            setList(newarr);
            setVal("");
        }
    }
    const removeField = (val) => {
        const newarr = datalist.filter((curr) => {
            return curr !== val
        })
        setList(newarr);
    }

    const addMasterData =  async () => {

        if(datalist.length === 0)
        {
            alert("atleast 1 field is required");
            return;
        }

        let newfields = [];

        Data.map((curr) => {
            if(curr.master === data.master)
            {
                const arr = curr.fields;
                newfields = [...arr, ...datalist];
            }
        })

        const UserData = JSON.parse(localStorage.getItem('wh_tk')).data;
        const userId = UserData._id;

        const body = {
            fields:newfields
        }

        // console.log(data._id);

        const res = await axiosInstance.post(`api/master/${data._id}`, body);
        if(res.status === 200)
        {
            alert('Fields added sucessfully');
        }
        else{
            alert('Something went wrong');
        }

        handleClose();
        setFlag(!flag);
        setList([]);
    }

    useEffect(() => {
        const getData = async () => {
            const res = await axiosInstance.get('api/master');
            // console.log(res);
            setData(res.data.data);
        }
        getData();
    }, [])

  return (
    <div>
        <Button variant="contained"
                sx={{
                    height:"40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    // marginTop:"10px",
                    fontSize:"0.875rem !important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }} onClick={handleOpen}>
                    <AddIcon sx={{
                    marginRight: '0.5rem'
                    }} />
                    Add Column
                </Button> 
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Columns
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{width:"90%"}}>
                {
                    datalist.map((curr) => {
                        return(
                            <div style={{width:"100%", display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                            <Input style={{width:"70%"}} value={curr} />
                            <Button variant="contained"
                            sx={{
                                height:"30px",
                                backgroundColor: '#000',
                                color: '#fff',
                                fontSize:"0.8rem !important",
                                '&:hover': {
                                    backgroundColor: '#000',
                                    opacity: '0.8'
                                }
                            }}

                            onClick = {() => { removeField(curr) }}
                            >
                                <RemoveIcon />
                            </Button>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{width:"90%", display:"flex", justifyContent:"space-between", marginTop:"10px", marginBottom:"20px"}}>
                <Input style={{width:"70%"}} placeholder="Add field" value={val} setvalue={setVal} />
                <Button variant="contained"
                sx={{
                    height:"40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize:"0.8rem !important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }} 
                onClick={handleClick} 
                >
                    Add
                </Button>
            </div>
            <Button variant="contained"
                sx={{
                    height:"40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    marginTop:"10px",
                    fontSize:"0.8rem !important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }}  
                onClick = {addMasterData}
                >
                    Done
                </Button>
            </Typography>
            </Box>
        </Modal>
        </div>
  );
}

export default BasicModal;

