import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Input from "../../components/input/Input"
import Button from '@mui/material/Button'
import RemoveIcon from '@mui/icons-material/Remove';
import Select from "../../components/select/Select";
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../axios/AxiosInstance'


const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Main = styled.div`
    width:50%;
    ${'' /* height:75%; */}
    max-height:100vh;
    overflow:scroll;
    background-color:white;
    padding:5%;
    display:flex;
    flex-direction:column;
    align-items:center;
`
const Heading = styled.div`
    color:black;
    font-size:36px;
    font-weight:700;
`
const Head = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-bottom:30px;
`
const Addbutton = styled(Button)`
    background-color:black;
    color:white;
    border-radius:20px;
    font-size:15px;

    &:hover{
        background-color:black;
    }
`
const MyButton = styled.div`
    width:100px;
    height:40px;
    background-color:black;
    color:white;
    border-radius:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:5px;
    margin-top:40px;
    cursor:pointer;
`

const items = [
  {
    value: 'transport',
    text: "Transport"
  },
  {
    value: 'sms',
    text: "SMS"
  },
  {
    value: 'working_hours',
    text: "Working Hours"
  },
  {
    value: 'weekly_off',
    text: "Weekly Off"
  },
  {
    value: 'task_limit',
    text: 'Task Limit'
  },
  {
    value: 'slot_duration',
    text: 'Slot Duration'
  },
  {
    value: 'holiday',
    text: 'Holiday'
  }
]

const typeItems = [
  {
    value: 'text',
    text: "Text"
  },
  {
    value: 'number',
    text: "number"
  },
  {
    value: 'dropdown',
    text: "Dropdown"
  },
  {
    value: 'time',
    text: "Time"
  },
  {
    value: 'date',
    text: "Date"
  },
  {
    value: 'array',
    text: "Array"
  }
]

const AddMaster = () => {

  const navigate = useNavigate();

  const [val, setVal] = useState("");
  const [type, setType] = useState('');
  const [datalist, setList] = useState([]);
  const [counter, setCounter] = useState(1);
  const [key, setKey] = useState('');
  const [isdropodown, setIsdropdown] = useState(false);
  const [dropItems, setDropItems] = useState([]);
  const [dropitem, setDropitem] = useState('');
  const [time, setTime] = useState(null);
  const [isTime, setIsTime] = useState(false);
  const [isArray , setIsArray] = useState(false);
  const [arrayItems, setArrayItems] = useState([]);
  const [arrayitem, setArrayitem] = useState('');

  // console.log(datalist);

  const handleClick = () => {

    if (val === '') {
      alert("Enter field name before adding");
      return;
    }

    if (type === '') {
      alert("Select type of the field");
      return;
    }

    if (type === 'dropdown') {
      if (dropItems.length === 0) {
        alert("Add dropdown items");
        return;
      }

      const newarr = [...datalist];
      newarr.push({
        val: val,
        type: type,
        items: dropItems
      });
      setList(newarr);
      alert("dropdown items added successfully")
      setDropItems([]);
      setIsdropdown(false);
    }
    else if (type === 'array') {
      if (arrayItems.length === 0) {
        alert("Add Array items");
        return;
      }

      const newarr = [...datalist];
      newarr.push({
        val: val,
        type: type,
        items: arrayItems
      });
      setList(newarr);
      alert("Array items added successfully")
      setArrayItems([]);
      setIsArray(false);
    }
    else {
      const newarr = [...datalist];
      newarr.push({
        val: val,
        type: type
      });
      setList(newarr);
    }
    setVal("");
    setType('');
  }

  const addDropItem = () => {
    if (dropitem === '') {
      alert("Enter item name before adding");
      return;
    }

    const newarr = [...dropItems];
    newarr.push({
      value: dropitem,
      text: dropitem
    });
    setDropItems(newarr);
    setDropitem("");
  }
  const addArrayItem = () => {
    if (arrayitem === '') {
      alert("Enter item name before adding");
      return;
    }

    const newarr = [...arrayItems];
    newarr.push({
      value: arrayitem,
      text: arrayitem
    });
    setArrayItems(newarr);
    setArrayitem("");
  }

  const [master, setMaster] = useState('');

  const handleChange = (event) => {
    setMaster(event.target.value);
  };

  const removeField = (val) => {
    const newarr = datalist.filter((curr) => {
      return curr !== val
    })
    setList(newarr);
  }

  const removeDropItem = (val) => {
    const newarr = dropItems.filter((curr) => {
      return curr !== val
    })
    setDropItems(newarr);
  }
  
  const removeArrayItem = (val) => {
    const newarr = arrayItems.filter((curr) => {
      return curr !== val
    })
    setArrayItems(newarr);
  }

  const addMasterData = async () => {

    if (master === '') {
      alert('Master can not be empty');
      return;
    }

    if (datalist.length === 0) {
      alert("atleast 1 field is required");
      return;
    }

    if (key === '') {
      alert('key field is required');
      return;
    }

    let check = false;
    // console.log(datalist , key);
    for (let i = 0; i < datalist.length; i++) {
      if (datalist[i].val === key) {
        check = true;
      }
    }

    if (check === false) {
      alert("key should be one out of all fields");
      return;
    }

    const UserData = JSON.parse(localStorage.getItem('wh_tk')).data;
    const userId = UserData._id;

    const body = {
      userId: userId,
      master: master,
      fields: datalist,
      key: key,
      rows: []
    }

    const res = await axiosInstance.post('api/master', body);
    if (res.status === 200) {
      alert('Master data created sucessfully');
      navigate('/masterdata');
    }
    else {
      alert('Something went wrong');
    }
  }

  const handleTypeChange = (ev) => {
    // console.log(ev);
    setType(ev.target.value);
    if (ev.target.value === 'dropdown') {
      setIsdropdown(true);
    }
    else {
      setIsdropdown(false);
    }

    if (ev.target.value === 'array') {
      setIsArray(true);
    }
    else {
      setIsArray(false);
    }

    if (ev.target.value === 'time') {
      setIsTime(true);
    }
    else {
      setIsTime(false);
    }
  }

  useEffect(() => {

  }, [datalist, counter])

  const [Data, setData] = useState([]);
  useEffect(() => {

    const getData = async () => {
      const res = await axiosInstance.get('api/master');
      // console.log(res);
      setData(res.data.data);
    }
    getData();
  }, [])


  // console.log(Data);

  // Filter items present in master data
  const filterItems = items.filter((curr) => {
    let check = false;
    for (let i = 0; i < Data.length; i++) {
      if (curr.value === Data[i].master) {
        check = true;
      }
    }
    return check === false;
  })


  return (
    <Container>
      <Main>
        <>
          <Head>
            <Heading>Add Master Data</Heading>
          </Head>
          <Select boxstyle={{ width: "70%", marginBottom: "10px" }} items={filterItems} value={master} handleChange={handleChange} label="Select master" />
          <div style={{ width: "100%" }}>
            {
              datalist.map((curr) => {
                return (
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Input style={{ width: "80%" }} value={`${curr.val} : ${curr.type}`} />
                    <Button variant="contained"
                      sx={{
                        height: "30px",
                        backgroundColor: '#000',
                        color: '#fff',
                        fontSize: "0.8rem !important",
                        '&:hover': {
                          backgroundColor: '#000',
                          opacity: '0.8'
                        }
                      }}

                      onClick={() => { removeField(curr) }}
                    >
                      <RemoveIcon />
                    </Button>
                  </div>
                )
              })
            }
          </div>
          {isdropodown &&
            <>
              <div style={{ width: "90%" }}>
                {
                  dropItems.map((curr) => {
                    return (
                      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <Input style={{ width: "80%" }} value={curr.value} />
                        <Button variant="contained"
                          sx={{
                            height: "30px",
                            backgroundColor: '#000',
                            color: '#fff',
                            fontSize: "0.8rem !important",
                            '&:hover': {
                              backgroundColor: '#000',
                              opacity: '0.8'
                            }
                          }}

                          onClick={() => { removeDropItem(curr) }}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                    )
                  })
                }
              </div>
              <div style={{ width: "90%", display: "flex", justifyContent: "space-between", marginTop: "10px", marginBottom: "20px" }}>
                <Input style={{ width: "80%" }} placeholder="Add Dropdown item" value={dropitem} setvalue={setDropitem} />
                <Button variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize: "0.8rem !important",
                    '&:hover': {
                      backgroundColor: '#000',
                      opacity: '0.8'
                    }
                  }}
                  onClick={addDropItem}
                >
                  Add
                </Button>
              </div>
            </>
          }
          {isArray &&
            <>
              <div style={{ width: "90%" }}>
                {
                  arrayItems.map((curr) => {
                    return (
                      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <Input style={{ width: "80%" }} value={curr.value} />
                        <Button variant="contained"
                          sx={{
                            height: "30px",
                            backgroundColor: '#000',
                            color: '#fff',
                            fontSize: "0.8rem !important",
                            '&:hover': {
                              backgroundColor: '#000',
                              opacity: '0.8'
                            }
                          }}

                          onClick={() => { removeArrayItem(curr) }}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                    )
                  })
                }
              </div>
              <div style={{ width: "90%", display: "flex", justifyContent: "space-between", marginTop: "10px", marginBottom: "20px" }}>
                <Input style={{ width: "80%" }} placeholder="Add Array item" value={arrayitem} setvalue={setArrayitem} />
                <Button variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize: "0.8rem !important",
                    '&:hover': {
                      backgroundColor: '#000',
                      opacity: '0.8'
                    }
                  }}
                  onClick={addArrayItem}
                >
                  Add
                </Button>
              </div>
            </>
          }

          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: "10px", marginBottom: "20px" }}>
            <Input style={{ width: "50%" }} placeholder="Add field" value={val} setvalue={setVal} />
            <Select boxstyle={{ height: "40px" }} style={{ height: "40px" }} items={typeItems} value={type} handleChange={handleTypeChange} lablestyle={{ top: "-8px" }} label="Type" />
            <Button variant="contained"
              sx={{
                height: "40px",
                backgroundColor: '#000',
                color: '#fff',
                fontSize: "0.8rem !important",
                '&:hover': {
                  backgroundColor: '#000',
                  opacity: '0.8'
                }
              }}
              onClick={handleClick}
            >
              Add
            </Button>
          </div>
          <Input style={{ marginTop: "10px" }} value={key} setvalue={setKey} placeholder="Enter field which is key.." />
          <Button variant="contained"
            sx={{
              height: "40px",
              backgroundColor: '#000',
              color: '#fff',
              marginTop: "40px",
              fontSize: "0.8rem !important",
              '&:hover': {
                backgroundColor: '#000',
                opacity: '0.8'
              }
            }}
            onClick={addMasterData}
          >
            Done
          </Button>
        </>
      </Main>
    </Container>
  )

}

export default AddMaster;