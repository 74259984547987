import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import './Tat.css';
import axiosInstance from '../../axios/AxiosInstance';
import moment from 'moment/moment';

export default function TatPage() {
    const [startDate, setStartDate] = useState(new Date());
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate1, endDate1] = dateRange;
    const [OderdataNew, SetOderdataNew] = useState([]);
    const [PickupdataNew, SetPickupdataNew] = useState([]);
    const [SrdataNew, SetSrdataNew] = useState([]);
    const [AllData, SetAllData] = useState([]);

    let getData = async () => {
        let start = startDate1
        let end = endDate1
        end = end.setHours(23, 59, 59, 999);
        const { data } = await axiosInstance.get(`api/ticket/tat?start=${new Date(start)}&end=${new Date(end)}`);
        SetAllData(processData(data.data))
        console.log(processData(data.data));
        const { Oderdata, Pickupdata, Srdata } = mergeData(transformAndSortData(data.data))
        SetOderdataNew(Oderdata)
        SetPickupdataNew(Pickupdata)
        SetSrdataNew(Srdata)
    }

    useEffect(() => {
        if (startDate1 && endDate1) {
            getData()
        }
    }, [startDate1, endDate1])

    let handleDate = (event) => {
        setDateRange(event);
        if (startDate1 && endDate1) {
            console.log(startDate1, endDate1);
        }
    };

    function transformAndSortData(data) {
        return data.map(location => {
            const OderdataIds = [...new Set(location.Oderdata.map(order => order.id))].sort((a, b) => a - b);
            const PickupdataIds = [...new Set(location.Pickupdata.map(pickup => pickup.id))].sort((a, b) => a - b);
            const SrdataIds = [...new Set(location.Srdata.map(sr => sr.id))].sort((a, b) => a - b);

            return {
                _id: location._id,
                Oderdata: OderdataIds,
                Pickupdata: PickupdataIds,
                Srdata: SrdataIds
            };
        });
    }

    function mergeData(data) {
        const mergedOderdata = [];
        const mergedPickupdata = [];
        const mergedSrdata = [];

        data.forEach(location => {
            location.Oderdata.forEach(id => {
                if (!mergedOderdata.includes(id)) {
                    mergedOderdata.push(id);
                }
            });

            location.Pickupdata.forEach(id => {
                if (!mergedPickupdata.includes(id)) {
                    mergedPickupdata.push(id);
                }
            });

            location.Srdata.forEach(id => {
                if (id !== undefined && !mergedSrdata.includes(id)) {
                    mergedSrdata.push(id);
                }
            });
        });

        return {
            Oderdata: mergedOderdata.sort((a, b) => a - b),
            Pickupdata: mergedPickupdata.sort((a, b) => a - b),
            Srdata: mergedSrdata.sort((a, b) => a - b)
        };
    }

    function getAllIds(data) {
        const allIds = {
            Oderdata: new Set(),
            Pickupdata: new Set(),
            Srdata: new Set()
        };

        data.forEach(location => {
            location.Oderdata.forEach(item => allIds.Oderdata.add(item.id));
            location.Pickupdata.forEach(item => allIds.Pickupdata.add(item.id));
            location.Srdata.forEach(item => {
                if (item.id !== undefined) allIds.Srdata.add(item.id);
            });
        });

        return {
            Oderdata: Array.from(allIds.Oderdata).sort((a, b) => a - b),
            Pickupdata: Array.from(allIds.Pickupdata).sort((a, b) => a - b),
            Srdata: Array.from(allIds.Srdata).sort((a, b) => a - b)
        };
    }

    function processData(apiResponse) {
        const allIds = getAllIds(apiResponse);

        return apiResponse.map(location => {
            const processedLocation = {
                _id: location._id,
                Oderdata: allIds.Oderdata.map(id => {
                    const existingItem = location.Oderdata.find(item => item.id === id);
                    return { id, count: existingItem ? existingItem.count : 0 };
                }),
                Pickupdata: allIds.Pickupdata.map(id => {
                    const existingItem = location.Pickupdata.find(item => item.id === id);
                    return { id, count: existingItem ? existingItem.count : 0 };
                }),
                Srdata: allIds.Srdata.map(id => {
                    const existingItem = location.Srdata.find(item => item.id === id);
                    return { id, count: existingItem ? existingItem.count : 0 };
                })
            };

            return processedLocation;
        });
    }

    function calculateGrandTotals(data, type) {
        const grandTotals = {
            underTAT: 0,
            grandTotal: 0
        };

        const relevantData = type === 'Oderdata' ? OderdataNew :
            type === 'Pickupdata' ? PickupdataNew : SrdataNew;

        relevantData.forEach(id => {
            grandTotals[id] = 0;
        });

        data.forEach(item => {
            item[type].forEach(order => {
                grandTotals[order.id] += order.count;
                grandTotals.grandTotal += order.count;
            });
        });

        return grandTotals;
    }

    return (
        <div>
            <div className="table-container">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={startDate1}
                    endDate={endDate1}
                    onChange={handleDate}
                    id="DashboardInput"
                />
                {
                    OderdataNew.length > 0 ? <>
                        {OderdataNew.length > 0 && (
                            <>
                                <h2 style={{ marginTop: "20px" }}> Order Delivery TAT Report</h2>
                                <table className="delivery-table">
                                    <thead>
                                        <tr>
                                            <th className='th'>Scheduled Date</th>
                                            <th className='th'>City</th>
                                            <th className='th'>Under TAT</th>
                                            {OderdataNew.map((item) => (
                                                item === 1 ? null : <th className='th' key={item}>{item}</th>
                                            ))}
                                            <th className='th'>Grand Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AllData.map((item, index) => (
                                            <tr key={index}>

                                                {index === 2 ? <td className='td'>{moment(startDate1).format("DD-MM-YYYY") + " - " + moment(endDate1).format("DD-MM-YYYY")}</td> : <td className='td'></td>}
                                                <td className='td'>{item._id}</td>
                                                {OderdataNew.map((some) =>
                                                    item.Oderdata.map((newSome, index) =>
                                                        newSome.id === some ? <td className='td' key={index}>{newSome.count}</td> : null
                                                    )
                                                )}
                                                <td className='td'>{item.Oderdata.reduce((sum, item) => sum + item.count, 0)}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td className='td' colSpan="2">Grand Total</td>
                                            {OderdataNew.map((id) => (
                                                <td className='td' key={id}>{calculateGrandTotals(AllData, 'Oderdata')[id]}</td>
                                            ))}
                                            <td className='td'>{calculateGrandTotals(AllData, 'Oderdata').grandTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        )}

                        {PickupdataNew.length > 0 && (
                            <>
                                <h2 style={{ marginTop: "20px" }}> Pickup TAT Report</h2>
                                <table className="delivery-table">
                                    <thead>
                                        <tr>
                                            <th className='th'>Scheduled Date</th>
                                            <th className='th'>City</th>
                                            <th className='th'>Under TAT</th>
                                            {PickupdataNew.map((item) => (
                                                item === 1 ? null : <th className='th' key={item}>{item}</th>
                                            ))}
                                            <th className='th'>Grand Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AllData.map((item, index) => (
                                            <tr key={index}>
                                                {index === 2 ? <td className='td'>{moment(startDate1).format("DD-MM-YYYY") + " - " + moment(endDate1).format("DD-MM-YYYY")}</td> : <td className='td'></td>}
                                                <td className='td'>{item._id}</td>
                                                {PickupdataNew.map((some) =>
                                                    item.Pickupdata.map((newSome, index) =>
                                                        newSome.id === some ? <td className='td' key={index}>{newSome.count}</td> : null
                                                    )
                                                )}
                                                <td className='td'>{item.Pickupdata.reduce((sum, item) => sum + item.count, 0)}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td className='td' colSpan="2">Grand Total</td>
                                            {PickupdataNew.map((id) => (
                                                <td className='td' key={id}>{calculateGrandTotals(AllData, 'Pickupdata')[id]}</td>
                                            ))}
                                            <td className='td'>{calculateGrandTotals(AllData, 'Pickupdata').grandTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        )}

                        {SrdataNew.length > 0 && (
                            <>
                                <h2 style={{ marginTop: "20px" }}> SR TAT Report</h2>
                                <table className="delivery-table">
                                    <thead>
                                        <tr>
                                            <th className='th'>Scheduled Date</th>
                                            <th className='th'>City</th>
                                            <th className='th'>Under TAT</th>
                                            {SrdataNew.map((item) => (
                                                item === 1 ? null : <th className='th' key={item}>{item}</th>
                                            ))}
                                            <th className='th'>Grand Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AllData.map((item, index) => (
                                            <tr key={index}>
                                                {index === 2 ? <td className='td'>{moment(startDate1).format("DD-MM-YYYY") + " - " + moment(endDate1).format("DD-MM-YYYY")}</td> : <td className='td'></td>}
                                                <td className='td'>{item._id}</td>
                                                {SrdataNew.map((some) =>
                                                    item.Srdata.map((newSome, index) =>
                                                        newSome.id === some ? <td className='td' key={index}>{newSome.count}</td> : null
                                                    )
                                                )}
                                                <td className='td'>{item.Srdata.reduce((sum, item) => sum + item.count, 0)}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td className='td' colSpan="2">Grand Total</td>
                                            {SrdataNew.map((id) => (
                                                <td className='td' key={id}>{calculateGrandTotals(AllData, 'Srdata')[id]}</td>
                                            ))}
                                            <td className='td'>{calculateGrandTotals(AllData, 'Srdata').grandTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        )}</> : <h3 style={{marginTop:"10px"}}>
                        Please select date
                        </h3>
                }

            </div>
        </div>
    )
}