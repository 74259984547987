import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function MaterialUIPickers(props) {
  // const handleChange = (newValue) => {
  //   props.setValue(newValue);
  //   props.other1s('');
  //   props.other1e('');
  //   props.other2s('');
  //   props.other2e('');
  // };

  const handleDate = () => {
    if (props.status === "Scheduled") {
      let startMinDate = new Date();
      let deliveryDate = new Date(props.schedule);
      startMinDate.setDate(startMinDate.getDate() - 2);
      // console.log("dates ", startMinDate, props.schedule, deliveryDate);
     
      if (deliveryDate < startMinDate) return startMinDate;
      else return deliveryDate;
      // console.log(scheduleDate.getDate() , scheduleDate.getFullYear(), scheduleDate.getMonth());
      // let tomorrow =  new Date();
      // tomorrow.setDate(scheduleDate.getDate() + 1);
      // return tomorrow;
    }
    if (props.schedule) {
      let startMinDate = new Date();
      let deliveryDate = new Date(props.schedule);
      startMinDate.setDate(startMinDate.getDate() - 2);
      // console.log("dates ", startMinDate, props.schedule, deliveryDate);
      if (deliveryDate < startMinDate) return startMinDate;
      else return deliveryDate;
    }

    return props.minDate;
  };

  React.useEffect(() => {}, [props.modalFlag]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3} sx={{ ...props.style }}>
        <DesktopDatePicker
          label={props.label}
          inputFormat="DD/MM/YYYY"
          value={props.value}
          onChange={props.handleChange}
          minDate={props.status ? handleDate() : ""}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}
